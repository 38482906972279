import axios from 'axios';
import {
  getAuthorizedGraphqlClient,
  getAuthorizedHeaders,
  restBaseUrl,
} from './queries';
import {
  AddTeamMemberInput,
  CreateContactInput,
  CreateFirmInput,
  CreateDealInput,
  ContactAssignmentInput,
  CreateTeamMemberRoleInput,
  UpdateTeamMemberInput,
  RemoveTeamMemberInput,
  UpdateContactInput,
  CreatePortfolioInput,
  UpdateDealChecklistItemInput,
  CreateChecklistInput,
  DealChecklistInput,
  UpdateChecklistInput,
  CreateChecklistItemInput,
  UpdateChecklistItemInput,
  UpdateStatusUpdateInput,
  CreateStatusUpdateInput,
  UpdateDealInput,
  CreateChecklistCategoryInput,
  UpdateDealEventInput,
  CreateKeyRiskInput,
  UpdateKeyRiskInput,
  UpdateDealMeritInput,
  CreateDealMeritInput,
  ConfigurableEntityEnum,
  DealMemberAssignmentInput,
  RemoveDealMemberInput,
  UpdateDealMemberRoleInput,
  UpdatePortfolioInput,
  UpdatePortfolioMemberRoleInput,
  RemovePortfolioMemberInput,
  PortfolioMemberAssignmentInput,
  UpdatePortfolioViewColumnInput,
  AddPortfolioViewColumnInput,
  CreateEventInput,
  UpdateEventInput,
  CreateDealEventInput,
  UpdateAnalysisMetricInput,
  CreateAnalysisInput,
  UpdateAnalysisInput,
  CreateAnalysisMetricInput,
  UpdatePortfolioDealsInput,
  CreateContractInput,
  UpdateContractInput,
  CreatePublishedCapitalForecastInput,
  CreateRevisionInput,
  UpdateRevisionInput,
  CreatePortfolioViewInput,
  UpdatePortfolioViewInput,
  CommentSubjectEnum,
  CreateCommentInput,
  UpdateCommentInput,
  CreateTransactionInput,
  CreateTransactionDetailInput,
  CreateGroupInput,
  UpdateGroupInput,
  CreateMetricInput,
  UpdateMetricInput,
  CreatePipelineInput,
  UpdatePipelineInput,
  UpdateTeamMemberRoleInput,
  UpdateOrganizationMemberRoleInput,
  RemoveOrganizationMemberInput,
  OrganizationMemberAssignmentInput,
  UpdateTransactionInput,
  UpdateRevisionAccountInput,
  CreateRevisionAccountInput,
  CreatePublishedScheduleInstanceInput,
  UpdatePublishedScheduleInstanceInput,
  CreatePublishedDealEventInput,
  UpdatePublishedDealEventInput,
  CreateBuildingInput,
  UpdateBuildingInput,
  CreateBondInput,
  UpdateBondInput,
  CreateGcEstimateInput,
  UpdateGcEstimateInput,
  CreateGlAccountInput,
  UpdateGlAccountInput,
  CreateGlAccountTreeInput,
  UpdateGlAccountTreeInput,
  CreateChangeEventInput,
  UpdateChangeEventInput,
  CreatePotentialChangeOrderInput,
  UpdatePotentialChangeOrderInput,
  CreateChangeOrderPackageInput,
  UpdateChangeOrderPackageInput,
  UpdateApprovalPackageSubSectionTextInput,
  UpdateApprovalPackageSubSectionInput,
  ApprovalPackageSubSectionTypeEnum,
  UpdateApprovalPackageSectionInput,
  CreateApprovalPackageSectionInput,
  CreateApprovalPackageInput,
  UpdateApprovalPackageInput,
  CreateApprovalPackageReviewerInput,
  UpdateApprovalPackageReviewerInput,
  CreateApprovalPackageContributorInput,
  IcSubSectionTableCellEntryInput,
  UpdateApprovalPackageTemplateInput,
  CreateApprovalPackageTemplateInput,
  ApprovalPackageStatusEnum,
  CreatePipelineGeneralOffsetInput,
  UpdatePipelineGeneralOffsetInput,
  CreatePipelineAccountOffsetInput,
  UpdatePipelineAccountOffsetInput,
  CreateForecastRuleInput,
  UpdateForecastRuleInput,
  DealArchiveStatusEnum,
  CreatePortfolioFilterGroupInput,
  CreateStatusUpdateTemplateInput,
  UpdateStatusUpdateTemplateInput,
  CreatePartnerRoleInput,
  UpdatePartnerRoleInput,
  UpdateDealPartnerInput,
  RemoveDealPartnerInput,
  AddDealPartnerInput,
  UpdateApprovalPackageSubSectionChecklistInput,
  CreateLabelInput,
  UpdateLabelInput,
  CreatePipelineContingencyCategoryInput,
  UpdatePipelineContingencyCategoryInput,
  CreateApprovalPackageTypeInput,
  UpdateApprovalPackageTypeInput,
  UpdateFirmInput,
  CreateInteractionInput,
  UpdateInteractionInput,
  PipelineMemberAssignmentInput,
  RemovePipelineMemberInput,
  UpdatePipelineMemberRoleInput,
  UpdateFirmRegionInput,
  CreateFirmRegionInput,
  CreateAnalysisEventInput,
  UpdateAnalysisEventInput,
  CreateTextMetricInput,
  UpdateTextMetricInput,
  CreateAnalysisTypeInput,
  UpdateAnalysisTypeInput,
  CreateAnalysisTextMetricInput,
  UpdateAnalysisTextMetricInput,
  CreateInterestRateInput,
  UpdateInterestRateInput
} from '../graphql/generated';

export async function createDeal(deal: CreateDealInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateDeal({ deal });
  return data;
}

export async function updateDeal(deal: UpdateDealInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateDeal({ deal });
  return data;
}

export async function createPortfolio(portfolio: CreatePortfolioInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreatePortfolio({ portfolio });
  return data;
}

export async function updatePortfolio(portfolio: UpdatePortfolioInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePortfolio({ portfolio });
  return data;
}

export async function updateDynamicPortfolioFilters(id: number, filterGroups: CreatePortfolioFilterGroupInput[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateDynamicPortfolioFilters({ id, filterGroups });
  return data;
}

export async function createSchedule(schedule: any) {
  const { data } = await axios.get(
    'https://www.mocky.io/v2/5e9f7e772d00004a00cb7be0?mocky-delay=500ms'
  );
  return data;
}

export async function createChecklist(checklist: CreateChecklistInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateChecklist({ checklist });
  return data;
}

export async function updateChecklist(checklist: UpdateChecklistInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateChecklist({ checklist });
  return data;
}

export async function addChecklistToDeal(dealChecklist: DealChecklistInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddChecklistToDeal({ dealChecklist });
  return data;
}

export async function removeChecklistFromDeal(
  dealChecklist: DealChecklistInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveChecklistFromDeal({ dealChecklist });
  return data;
}

export async function createChecklistItem(
  checklistItem: CreateChecklistItemInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateChecklistItem({ checklistItem });
  return data;
}

export async function updateChecklistItem(
  checklistItem: UpdateChecklistItemInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateChecklistItem({ checklistItem });
  return data;
}

export async function deleteChecklist(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteChecklist({ id });
  return data;
}

export async function deleteChecklistItem(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteChecklistItem({ id });
  return data;
}

export async function updateDealChecklistItem(
  checklistItem: UpdateDealChecklistItemInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateDealChecklistitem({ checklistItem });
  return data;
}

export async function createChecklistCategory(
  checklistCategory: CreateChecklistCategoryInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateChecklistCategory({ checklistCategory });
  return data;
}

export async function createContact(contact: CreateContactInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateContact({ contact });
  return data;
}

export async function updateContact(contact: UpdateContactInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateContact({ contact });
  return data;
}

export async function deleteContact(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteContact({ id });
  return data;
}

export async function assignContacts(assignment: ContactAssignmentInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AssignContacts({ assignment });
  return data;
}

export async function unassignContacts(assignment: ContactAssignmentInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UnassignContacts({ assignment });
  return data;
}

export async function createFirm(firm: CreateFirmInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateFirm({ firm });
  return data;
}

export async function updateFirm(firm: UpdateFirmInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateFirm({ firm });
  return data;
}

export async function deleteFirm(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteFirm({ id });
  return data;
}

export async function createFirmRegion(firmRegion: CreateFirmRegionInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateFirmRegion({ firmRegion });
  return data;
}

export async function updateFirmRegion(firmRegion: UpdateFirmRegionInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateFirmRegion({ firmRegion });
  return data;
}

export async function deleteFirmRegion(regionId: number, firmId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteFirmRegion({ fkRegion: regionId, fkFirm: firmId });
  return data;
}

export async function createInteraction(interaction: CreateInteractionInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateInteraction({ interaction });
  return data;
}

export async function updateInteraction(interaction: UpdateInteractionInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateInteraction({ interaction });
  return data;
}

export async function deleteInteraction(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteInteraction({ id });
  return data;
}

export async function createTeamMember(teamMember: AddTeamMemberInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddTeamMember({ teamMember });
  return data;
}

export async function updateTeamMembers(dealId: number, roleId: number, userIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateTeamMembers({ dealId, roleId, userIds });
  return data;
}

export async function updateTeamMember(teamMember: UpdateTeamMemberInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateTeamMember({ teamMember });
  return data;
}

export async function removeTeamMember(teamMember: RemoveTeamMemberInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveTeamMember({ teamMember });
  return data;
}

export async function createDealPartner(partner: AddDealPartnerInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddDealPartner({ partner });
  return data;
}

export async function updateDealPartner(partner: UpdateDealPartnerInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateDealPartner({ partner });
  return data;
}

export async function updateDealPartners(dealId: number, roleId: number, firmIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateDealPartners({ dealId, roleId, firmIds });
  return data;
}

export async function removeDealPartner(partner: RemoveDealPartnerInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveDealPartner({ partner });
  return data;
}

export async function createTeamRole(
  teamMemberRole: CreateTeamMemberRoleInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateTeamMemberRole({ teamMemberRole });
  return data;
}

export async function updateTeamRole(
  teamMemberRole: UpdateTeamMemberRoleInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateTeamMemberRole({ teamMemberRole });
  return data;
}

export async function deleteTeamRole(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteTeamMemberRole({ id });
  return data;
}

export async function createPartnerRole(
  partnerRole: CreatePartnerRoleInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreatePartnerRole({ partnerRole });
  return data;
}

export async function updatePartnerRole(
  partnerRole: UpdatePartnerRoleInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePartnerRole({ partnerRole });
  return data;
}

export async function deletePartnerRole(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeletePartnerRole({ id });
  return data;
}

export async function createGCEstimate(estimate: any) {
  const { data } = await axios.get(
    'http://www.mocky.io/v2/5e9f8b1f2d00004a00cb7c15?mocky-delay=500ms'
  );
  return data;
}

export async function editGCEstimate(estimate: any) {
  const { data } = await axios.get(
    'http://www.mocky.io/v2/5e9f8b1f2d00004a00cb7c15?mocky-delay=500ms'
  );
  return data;
}

export async function createTask(task: any) {
  const { data } = await axios.get(
    'http://www.mocky.io/v2/5e9f8b1f2d00004a00cb7c15?mocky-delay=500ms'
  );
  return data;
}

export async function editTask(task: any) {
  const { data } = await axios.get(
    'http://www.mocky.io/v2/5e9f8b1f2d00004a00cb7c15?mocky-delay=500ms'
  );
  return data;
}

export async function createKeyRisk(keyRisk: CreateKeyRiskInput, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateKeyRisk({ keyRisk, dealId });
  return data;
}

export async function updateKeyRisk(keyRisk: UpdateKeyRiskInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateKeyRisk({ keyRisk });
  return data;
}

export async function deleteKeyRisk(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteKeyRisk({ id });
  return data;
}

export async function createDealMerit(dealMerit: CreateDealMeritInput, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateDealMerit({ dealMerit, dealId });
  return data;
}

export async function updateDealMerit(dealMerit: UpdateDealMeritInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateDealMerit({ dealMerit });
  return data;
}

export async function deleteDealMerit(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteDealMerit({ id });
  return data;
}


export async function addEventsToDeal(dealId: number, eventIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddEventsToDeal({ dealId, eventIds });
  return data;
}

export async function removeEventsFromDeal(dealId: number, eventIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveEventsFromDeal({ dealId, eventIds });
  return data;
}

export async function createComment(
  comment: CreateCommentInput,
  subjectType: CommentSubjectEnum
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateComment({ comment, subjectType });
  return data;
}

export async function updateComment(comment: UpdateCommentInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateComment({ comment });
  return data;
}

export async function deleteComment(
  id: number,
  subjectType: CommentSubjectEnum
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteComment({ id, subjectType });
  return data;
}

export async function createStatusUpdate(
  statusUpdate: CreateStatusUpdateInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateStatusUpdate({ statusUpdate });
  return data;
}

export async function updateStatusUpdate(
  statusUpdate: UpdateStatusUpdateInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateStatusUpdate({ statusUpdate });
  return data;
}

export async function deleteStatusUpdate(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteStatusUpdate({ id });
  return data;
}

export async function createStatusUpdateTemplate(
  statusUpdateTemplate: CreateStatusUpdateTemplateInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateStatusUpdateTemplate({ statusUpdateTemplate });
  return data;
}

export async function updateStatusUpdateTemplate(
  statusUpdateTemplate: UpdateStatusUpdateTemplateInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateStatusUpdateTemplate({ statusUpdateTemplate });
  return data;
}

export async function deleteStatusUpdateTemplate(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteStatusUpdateTemplate({ id });
  return data;
}

export async function reorderDealPinnedMetric(
  fkDeal: number,
  id: number,
  beforeId?: number,
  afterId?: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.ReorderDealPinnedMetric({
    fkDeal,
    id,
    beforeId,
    afterId,
  });
  return data;
}

export async function reorderPipelinePinnedMetric(
  pipelineId: number,
  id: number,
  beforeId?: number,
  afterId?: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.ReorderPipelinePinnedMetric({
    pipelineId,
    id,
    beforeId,
    afterId,
  });
  return data;
}

export async function reorderPipelinePinnedEvent(
  pipelineId: number,
  id: number,
  beforeId?: number,
  afterId?: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.ReorderPipelinePinnedEvent({
    pipelineId,
    id,
    beforeId,
    afterId,
  });
  return data;
}

export async function createPipelineDealStage(
  name: string,
  pipelineId: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreatePipelineDealStage({ name, pipelineId });
  return data;
}

export async function updatePipelineDealStage(
  id: number,
  name: string,
  isDisabled: boolean,
  pipelineId: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePipelineDealStage({
    id,
    name,
    isDisabled,
    pipelineId,
  });
  return data;
}

export async function reorderPipelineDealStage(
  id: number,
  pipelineId: number,
  beforeId?: number,
  afterId?: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.ReorderPipelineDealStage({
    id,
    beforeId,
    afterId,
    pipelineId,
  });
  return data;
}

export async function deletePipelineDealStage(
  id: number,
  pipelineId: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeletePipelineDealStage({ id, pipelineId });
  return data;
}

export async function createConfigurableEntity(
  name: string,
  type: ConfigurableEntityEnum
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateConfigurableEntity({ name, type });
  return data;
}

export async function updateConfigurableEntity(
  id: number,
  name: string,
  isDisabled: boolean,
  type: ConfigurableEntityEnum,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateConfigurableEntity({
    id,
    name,
    isDisabled,
    type,
  });
  return data;
}

export async function deleteConfigurableEntity(
  id: number,
  type: ConfigurableEntityEnum
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteConfigurableEntity({ id, type });
  return data;
}

export async function assignMembersToOrganization(
  assignment: OrganizationMemberAssignmentInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AssignMembersToOrganization({ assignment });
  return data;
}

export async function removeMembersFromOrganization(
  assignment: RemoveOrganizationMemberInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveMembersFromOrganization({ assignment });
  return data;
}

export async function updateOrganizationMemberRole(
  organizationMemberRole: UpdateOrganizationMemberRoleInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateOrganizationMemberRole({
    organizationMemberRole,
  });
  return data;
}

export async function assignMembersToDeal(
  assignment: DealMemberAssignmentInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AssignMembersToDeal({ assignment });
  return data;
}

export async function removeMembersFromDeal(assignment: RemoveDealMemberInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveMembersFromDeal({ assignment });
  return data;
}

export async function updateDealMemberRole(
  dealMemberRole: UpdateDealMemberRoleInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateDealMemberRole({ dealMemberRole });
  return data;
}

export async function assignMembersToPipeline(
  assignment: PipelineMemberAssignmentInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AssignMembersToPipeline({ assignment });
  return data;
}

export async function removeMembersFromPipeline(assignment: RemovePipelineMemberInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveMembersFromPipeline({ assignment });
  return data;
}

export async function updatePipelineMemberRole(
  pipelineMemberRole: UpdatePipelineMemberRoleInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePipelineMemberRole({ pipelineMemberRole });
  return data;
}

export async function assignMembersToPortfolio(
  assignment: PortfolioMemberAssignmentInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AssignMembersToPortfolio({ assignment });
  return data;
}

export async function removeMembersFromPortfolio(
  assignment: RemovePortfolioMemberInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveMembersFromPortfolio({ assignment });
  return data;
}

export async function updatePortfolioMemberRole(
  portfolioMemberRole: UpdatePortfolioMemberRoleInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePortfolioMemberRole({ portfolioMemberRole });
  return data;
}

export async function updatePortfolioViewColumn(
  column: UpdatePortfolioViewColumnInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePortfolioViewColumn({ column });
  return data;
}

export async function addPortfolioViewColumn(
  column: AddPortfolioViewColumnInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddPortfolioViewColumn({ column });
  return data;
}

export async function removePortfolioViewColumns(
  portfolioViewId: number,
  columns: AddPortfolioViewColumnInput[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemovePortfolioViewColumns({
    id: portfolioViewId,
    columns,
  });
  return data;
}

export async function addPortfolioDeals(
  portfolioDeals: UpdatePortfolioDealsInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddPortfolioDeals({ portfolioDeals });
  return data;
}

export async function removePortfolioDeals(
  portfolioDeals: UpdatePortfolioDealsInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemovePortfolioDeals({ portfolioDeals });
  return data;
}

export async function createEvent(event: CreateEventInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateEvent({ event });
  return data;
}

export async function updateEvent(event: UpdateEventInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateEvent({ event });
  return data;
}

export async function deleteEvent(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteEvent({ id });
  return data;
}

export async function updateDealEvent(event: UpdateDealEventInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateDealEvent({ event });
  return data;
}

export async function createDealEvent(event: CreateDealEventInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateDealEvent({ event });
  return data;
}

export async function createAnalysis(analysis: CreateAnalysisInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateAnalysis({ analysis });
  return data;
}

export async function updateAnalysis(analysis: UpdateAnalysisInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateAnalysis({ analysis });
  return data;
}

export async function deleteAnalysis(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteAnalysis({ id });
  return data;
}

export async function createAnalysisType(analysisType: CreateAnalysisTypeInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateAnalysisType({ analysisType });
  return data;
}

export async function updateAnalysisType(analysisType: UpdateAnalysisTypeInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateAnalysisType({ analysisType });
  return data;
}

export async function deleteAnalysisType(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteAnalysisType({ id });
  return data;
}

export async function createAnalysisMetric(metric: CreateAnalysisMetricInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateAnalysisMetric({ metric });
  return data;
}

export async function updateAnalysisMetric(metric: UpdateAnalysisMetricInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateAnalysisMetric({ metric });
  return data;
}

export async function deleteAnalysisMetric(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteAnalysisMetric({ id });
  return data;
}

export async function createAnalysisTextMetric(textMetric: CreateAnalysisTextMetricInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateAnalysisTextMetric({ textMetric });
  return data;
}

export async function updateAnalysisTextMetric(textMetric: UpdateAnalysisTextMetricInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateAnalysisTextMetric({ textMetric });
  return data;
}

export async function deleteAnalysisTextMetric(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteAnalysisTextMetric({ id });
  return data;
}

export async function createAnalysisEvent(event: CreateAnalysisEventInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateAnalysisEvent({ event });
  return data;
}

export async function updateAnalysisEvent(event: UpdateAnalysisEventInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateAnalysisEvent({ event });
  return data;
}

export async function deleteAnalysisEvent(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteAnalysisEvent({ id });
  return data;
}

export async function duplicateAnalysis(analysisId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DuplicateAnalysis({ id: analysisId });
  return data;
}

export async function archiveAnalysis(analysisId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.ArchiveAnalysis({ id: analysisId });
  return data;
}

export async function createContract(contract: CreateContractInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateContract({ contract });
  return data;
}

export async function updateContract(contract: UpdateContractInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateContract({ contract });
  return data;
}

export async function deleteContract(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteContract({ id });
  return data;
}

export async function publishCapitalForecast(
  capitalForecast: CreatePublishedCapitalForecastInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.PublishCapitalForecast({ capitalForecast });
  return data;
}

export async function deletePublishedCapitalForecast(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeletePublishedCapitalForecast({ id });
  return data;
}

export async function createBudgetRevision(revision: CreateRevisionInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateRevision({ revision });
  return data;
}

export async function updateBudgetRevision(revision: UpdateRevisionInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateRevision({ revision });
  return data;
}

export async function deleteBudgetRevision(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteRevision({ id });
  return data;
}

export async function createBudgetRevisionCostAccount(
  revisionCostAccount: CreateRevisionAccountInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateRevisionCostAccount({ revisionCostAccount });
  return data;
}

export async function updateBudgetRevisionCostAccount(
  revisionCostAccount: UpdateRevisionAccountInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateRevisionCostAccount({ revisionCostAccount });
  return data;
}

export async function deleteBudgetRevisionCostAccounts(
  revisionId: number,
  ids: number[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteRevisionCostAccounts({ revisionId, ids });
  return data;
}

export async function createPortfolioView(
  portfolioView: CreatePortfolioViewInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreatePortfolioView({ portfolioView });
  return data;
}

export async function updatePortfolioView(
  portfolioView: UpdatePortfolioViewInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePortfolioView({ portfolioView });
  return data;
}

export async function deletePortfolioView(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeletePortfolioView({ id });
  return data;
}

export async function generateTransactionDetailPreview(
  transaction: CreateTransactionInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GenerateTransactionDetailPreview({ transaction });
  return data;
}

export async function createTransaction(
  transaction: CreateTransactionInput,
  details: CreateTransactionDetailInput[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateTransaction({ transaction, details });
  return data;
}

export async function updateTransaction(
  transaction: UpdateTransactionInput,
  details: CreateTransactionDetailInput[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateTransaction({ transaction, details });
  return data;
}

export async function deleteTransaction(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteTransaction({ id });
  return data;
}

export async function deletePortfolio(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeletePortfolio({ id });
  return data;
}

export async function deleteDeal(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteDeal({ id });
  return data;
}

export async function archiveDeal(id: number, status: DealArchiveStatusEnum, reason?: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.ArchiveDeal({ id, status, reason });
  return data;
}

export async function unarchiveDeal(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UnarchiveDeal({ id });
  return data;
}

export async function createGroup(group: CreateGroupInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateGroup({ group });
  return data;
}

export async function updateGroup(group: UpdateGroupInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateGroup({ group });
  return data;
}

export async function deleteGroup(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteGroup({ id });
  return data;
}

export async function addUsersToGroup(groupId: number, userIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddUsersToGroup({ groupId, userIds });
  return data;
}

export async function removeUsersFromGroup(groupId: number, userIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveUsersfromGroup({ groupId, userIds });
  return data;
}

export async function createMetric(metric: CreateMetricInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateMetric({ metric });
  return data;
}

export async function updateMetric(metric: UpdateMetricInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateMetric({ metric });
  return data;
}

export async function deleteMetric(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteMetric({ id });
  return data;
}

export async function createTextMetric(textMetric: CreateTextMetricInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateTextMetric({ textMetric });
  return data;
}

export async function updateTextMetric(textMetric: UpdateTextMetricInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateTextMetric({ textMetric });
  return data;
}

export async function deleteTextMetric(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteTextMetric({ id });
  return data;
}

export async function createPipeline(input: CreatePipelineInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreatePipeline({ input });
  return data;
}

export async function updatePipeline(input: UpdatePipelineInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePipeline({ input });
  return data;
}

export async function deletePipeline(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeletePipeline({ id });
  return data;
}

export async function addChecklistsToPipeline(
  id: number,
  checklistIds: number[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddChecklistsToPipeline({ id, checklistIds });
  return data;
}

export async function removeChecklistsFromPipeline(
  id: number,
  checklistIds: number[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveChecklistsFromPipeline({ id, checklistIds });
  return data;
}

export async function addPinnedMetricsToPipeline(
  id: number,
  metricIds: number[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddPinnedMetricsToPipeline({ id, metricIds });
  return data;
}

export async function removePinnedMetricsFromPipeline(
  id: number,
  metricIds: number[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemovePinnedMetricsFromPipeline({ id, metricIds });
  return data;
}

export async function addPinnedEventsToPipeline(
  id: number,
  eventIds: number[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddPinnedEventsToPipeline({ id, eventIds });
  return data;
}

export async function removePinnedEventsFromPipeline(
  id: number,
  eventIds: number[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemovePinnedEventsFromPipeline({ id, eventIds });
  return data;
}

export async function addPinnedPartnerRolesToPipeline(
  id: number,
  roleIds: number[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddPinnedPartnerRolesToPipeline({ id, roleIds });
  return data;
}

export async function removePinnedPartnerRolesFromPipeline(
  id: number,
  roleIds: number[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemovePinnedPartnerRolesFromPipeline({ id, roleIds });
  return data;
}

export async function reorderPipelinePinnedPartnerRole(
  pipelineId: number,
  id: number,
  beforeId?: number,
  afterId?: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.ReorderPipelinePinnedPartnerRole({
    pipelineId,
    id,
    beforeId,
    afterId,
  });
  return data;
}

export async function addPinnedTeamMemberRolesToPipeline(
  id: number,
  roleIds: number[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddPinnedTeamMemberRolesToPipeline({ id, roleIds });
  return data;
}

export async function removePinnedTeamMemberRolesFromPipeline(
  id: number,
  roleIds: number[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemovePinnedTeamMemberRolesFromPipeline({ id, roleIds });
  return data;
}

export async function reorderPipelinePinnedTeamMemberRole(
  pipelineId: number,
  id: number,
  beforeId?: number,
  afterId?: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.ReorderPipelinePinnedTeamMemberRole({
    pipelineId,
    id,
    beforeId,
    afterId,
  });
  return data;
}

export async function addEventsToPipeline(id: number, eventIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddEventsToPipeline({ id, eventIds });
  return data;
}

export async function removeEventsFromPipeline(id: number, eventIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveEventsFromPipeline({ id, eventIds });
  return data;
}

export async function addModulesToPipeline(id: number, moduleIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddModulesToPipeline({ id, moduleIds });
  return data;
}

export async function removeModulesFromPipeline(id: number, moduleIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveModulesFromPipeline({ id, moduleIds });
  return data;
}

export async function addForecastRulesToPipeline(id: number, forecastRuleIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddForecastRulesToPipeline({ id, forecastRuleIds });
  return data;
}

export async function removeForecastRulesFromPipeline(id: number, forecastRuleIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveForecastRulesFromPipeline({ id, forecastRuleIds });
  return data;
}

export async function enableForecastRules(fkDeal: number, forecastRules: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.EnableForecastRules({ fkDeal, forecastRules });
  return data;
}

export async function disableForecastRules(fkDeal: number, forecastRules: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DisableForecastRules({ fkDeal, forecastRules });
  return data;
}

export async function publishScheduleInstance(
  instance: CreatePublishedScheduleInstanceInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.PublishScheduleInstance({ instance });
  return data;
}

export async function createScheduleInstance(
  instance: CreatePublishedScheduleInstanceInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateScheduleInstance({ instance });
  return data;
}

export async function updateScheduleInstance(
  instance: UpdatePublishedScheduleInstanceInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateScheduleInstance({ instance });
  return data;
}

export async function deleteScheduleInstance(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteScheduleInstance({ id });
  return data;
}

export async function createPublishedDealEvent(
  event: CreatePublishedDealEventInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreatePublishedDealEvent({ event });
  return data;
}

export async function updatePublishedDealEvent(
  event: UpdatePublishedDealEventInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePublishedDealEvent({ event });
  return data;
}

export async function createBuilding(building: CreateBuildingInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateBuilding({ building });
  return data;
}

export async function updateBuilding(building: UpdateBuildingInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateBuilding({ building });
  return data;
}

export async function deleteBuilding(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteBuilding({ id });
  return data;
}

export async function createBond(bond: CreateBondInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateBond({ bond });
  return data;
}

export async function updateBond(bond: UpdateBondInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateBond({ bond });
  return data;
}

export async function deleteBond(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteBond({ id });
  return data;
}

export async function createGcEstimate(gcEstimate: CreateGcEstimateInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateGcEstimate({ gcEstimate });
  return data;
}

export async function updateGcEstimate(gcEstimate: UpdateGcEstimateInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateGcEstimate({ gcEstimate });
  return data;
}

export async function deleteGcEstimate(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteGcEstimate({ id });
  return data;
}

export async function addDealPinnedMetric(fkDeal: number, fkMetric: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddDealPinnedMetric({ fkDeal, fkMetric });
  return data;
}

export async function removeDealPinnedMetric(fkDeal: number, fkMetric: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveDealPinnedMetric({ fkDeal, fkMetric });
  return data;
}

export async function createGlAccount(account: CreateGlAccountInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateGlAccount({ account });
  return data;
}

export async function updateGlAccount(account: UpdateGlAccountInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateGlAccount({ account });
  return data;
}

export async function deleteGlAccount(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteGlAccount({ id });
  return data;
}

export async function createGlAccountTree(
  accountTree: CreateGlAccountTreeInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateGlAccountTree({ accountTree });
  return data;
}

export async function updateGlAccountTree(
  accountTree: UpdateGlAccountTreeInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateGlAccountTree({ accountTree });
  return data;
}

export async function deleteGlAccountTree(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteGlAccountTree({ id });
  return data;
}

export async function createPipelineContingencyCategory(
  accountCategorization: CreatePipelineContingencyCategoryInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreatePipelineContingencyCategory({ accountCategorization });
  return data;
}

export async function updatePipelineContingencyCategory(
  accountCategorization: UpdatePipelineContingencyCategoryInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePipelineContingencyCategory({ accountCategorization });
  return data;
}

export async function deletePipelineContingencyCategory(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeletePipelineContingencyCategory({ id });
  return data;
}

export async function createPipelineGeneralOffset(
  generalOffsetRule: CreatePipelineGeneralOffsetInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreatePipelineGeneralOffset({ generalOffsetRule });
  return data;
}

export async function updatePipelineGeneralOffset(
  generalOffsetRule: UpdatePipelineGeneralOffsetInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePipelineGeneralOffset({ generalOffsetRule });
  return data;
}

export async function deletePipelineGeneralOffset(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeletePipelineGeneralOffset({ id });
  return data;
}

export async function createPipelineAccountOffset(
  accountOffsetRule: CreatePipelineAccountOffsetInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreatePipelineAccountOffset({ accountOffsetRule });
  return data;
}

export async function updatePipelineAccountOffset(
  accountOffsetRule: UpdatePipelineAccountOffsetInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePipelineAccountOffset({ accountOffsetRule });
  return data;
}

export async function deletePipelineAccountOffset(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeletePipelineAccountOffset({ id });
  return data;
}

export async function createForecastRule(
  forecastRule: CreateForecastRuleInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateForecastRule({ forecastRule });
  return data;
}

export async function updateForecastRule(
  forecastRule: UpdateForecastRuleInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateForecastRule({ forecastRule });
  return data;
}

export async function deleteForecastRule(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteForecastRule({ id });
  return data;
}

export async function addAccountsToAccountTree(
  id: number,
  accounts: number[],
  parentId?: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddAccountsToAccountTree({
    id,
    accounts,
    parentId,
  });
  return data;
}

export async function removeAccountsFromAccountTree(
  id: number,
  accounts: number[]
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveAccountsFromAccountTree({ id, accounts });
  return data;
}

export async function changeAccountTreeParent(
  id: number,
  accounts: number[],
  parentId?: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.ChangeAccountTreeParent({ id, accounts, parentId });
  return data;
}

export async function createChangeEvent(changeEvent: CreateChangeEventInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateChangeEvent({ changeEvent });
  return data;
}

export async function updateChangeEvent(changeEvent: UpdateChangeEventInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateChangeEvent({ changeEvent });
  return data;
}

export async function deleteChangeEvent(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteChangeEvent({ id });
  return data;
}

export async function createPotentialChangeOrder(
  potentialChangeOrder: CreatePotentialChangeOrderInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreatePotentialChangeOrder({
    potentialChangeOrder,
  });
  return data;
}

export async function updatePotentialChangeOrder(
  potentialChangeOrder: UpdatePotentialChangeOrderInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdatePotentialChangeOrder({
    potentialChangeOrder,
  });
  return data;
}

export async function deletePotentialChangeOrder(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeletePotentialChangeOrder({ id });
  return data;
}

export async function createChangeOrderPackage(
  changeOrderPackage: CreateChangeOrderPackageInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateChangeOrderPackage({ changeOrderPackage });
  return data;
}

export async function updateChangeOrderPackage(
  changeOrderPackage: UpdateChangeOrderPackageInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateChangeOrderPackage({ changeOrderPackage });
  return data;
}

export async function deleteChangeOrderPackage(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteChangeOrderPackage({ id });
  return data;
}

export async function updateApprovalPackageSubSectionText(
  subSectionText: UpdateApprovalPackageSubSectionTextInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackageSubSectionText({
    subSectionText,
  });
  return data;
}

export async function updateApprovalPackageSubSectionChecklist(
  subSectionChecklist: UpdateApprovalPackageSubSectionChecklistInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackageSubSectionChecklist({
    subSectionChecklist,
  });
  return data;
}

export async function createApprovalPackage(
  approvalPackage: CreateApprovalPackageInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateApprovalPackage({ approvalPackage });
  return data;
}

export async function updateApprovalPackage(
  approvalPackage: UpdateApprovalPackageInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackage({ approvalPackage });
  return data;
}

export async function archiveApprovalPackage(
  id: number,
  status: ApprovalPackageStatusEnum
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.ArchiveApprovalPackage({ id, status });
  return data;
}

export async function publishApprovalPackage(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.PublishApprovalPackage({ id });
  return data;
}

export async function deleteApprovalPackage(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteApprovalPackage({ id });
  return data;
}

export async function createApprovalPackageSection(
  section: CreateApprovalPackageSectionInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateApprovalPackageSection({ section });
  return data;
}

export async function updateApprovalPackageSection(
  section: UpdateApprovalPackageSectionInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackageSection({ section });
  return data;
}

export async function deleteApprovalPackageSection(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteApprovalPackageSection({ id });
  return data;
}

export async function createApprovalPackageSubSection(
  sectionId: number,
  type: ApprovalPackageSubSectionTypeEnum,
  checklistId?: number,
  gcEstimateTypeId?: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateApprovalPackageSubSection({
    sectionId,
    type,
    checklistId,
    gcEstimateTypeId,
  });
  return data;
}

export async function updateApprovalPackageSubSection(
  subSection: UpdateApprovalPackageSubSectionInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackageSubSection({ subSection });
  return data;
}

export async function deleteApprovalPackageSubSection(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteApprovalPackageSubSection({ id });
  return data;
}

export async function uploadApprovalPackageImage(
  approvalPackageSubSectionId: number,
  displayName: string,
  file: any
) {
  const headers = await getAuthorizedHeaders();
  const url = `${restBaseUrl}documents/approvalPackageSubSection/${approvalPackageSubSectionId}`;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('displayName', displayName);
  const response = await axios({
    method: 'POST',
    url: url,
    data: formData,
    headers: { ...headers },
  });
  return response;
}

export async function uploadApprovalPackageExhibit(
  approvalPackageId: number,
  displayName: string,
  file: any,
) {
  const headers = await getAuthorizedHeaders();
  const url = `${restBaseUrl}documents/approvalPackage/${approvalPackageId}`;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('displayName', displayName);
  const response = await axios({
    method: 'POST',
    url: url,
    data: formData,
    headers: { ...headers },
  });
  return response;
}

export async function updateApprovalPackageExhibit(id: number, displayName?: string, fileName?: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackageExhibit({ id, displayName, fileName });
  return data;
}

export async function deleteApprovalPackageExhibit(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteApprovalPackageExhibit({ id });
  return data;
}

export async function createApprovalPackageReviewer(
  reviewer: CreateApprovalPackageReviewerInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateApprovalPackageReviewer({ reviewer });
  return data;
}

export async function updateApprovalPackageReviewer(
  reviewer: UpdateApprovalPackageReviewerInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackageReviewer({ reviewer });
  return data;
}

export async function deleteApprovalPackageReviewer(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteApprovalPackageReviewer({ id });
  return data;
}

export async function createApprovalPackageContributor(
  contributor: CreateApprovalPackageContributorInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateApprovalPackageContributor({ contributor });
  return data;
}

export async function deleteApprovalPackageContributor(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteApprovalPackageContributor({ id });
  return data;
}

export async function createApprovalPackageSubSectionTableCell(subSectionId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateApprovalPackageSubSectionTableCell({ id: subSectionId });
  return data;
}

export async function deleteApprovalPackageSubSectionTableCell(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteApprovalPackageSubSectionTableCell({ id });
  return data;
}

export async function setEntriesForApprovalPackageSubSectionTableCell(id: number, entries: IcSubSectionTableCellEntryInput[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.SetEntriesForApprovalPackageSubSectionTableCell({ id, entries });
  return data;
}

export async function setApprovalPackageSubSectionTableColumns(id: number, columns: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.SetApprovalPackageSubSectionTableColumns({ id, columns });
  return data;
}

export async function refreshApprovalPackageKeyRisks(id: number, approvalPackageId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RefreshApprovalPackageKeyRisks({ id, approvalPackageId });
  return data;
}

export async function refreshApprovalPackageDealMerits(id: number, approvalPackageId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RefreshApprovalPackageDealMerits({ id, approvalPackageId });
  return data;
}

// Approval Package Templates

export async function updateApprovalPackageTemplateSubSectionText(
  subSectionText: UpdateApprovalPackageSubSectionTextInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackageTemplateSubSectionText({
    subSectionText,
  });
  return data;
}

export async function updateApprovalPackageTemplateSubSectionChecklist(
  subSectionChecklist: UpdateApprovalPackageSubSectionChecklistInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackageTemplateSubSectionChecklist({
    subSectionChecklist,
  });
  return data;
}

export async function createApprovalPackageTemplate(
  template: CreateApprovalPackageTemplateInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateApprovalPackageTemplate({ template });
  return data;
}

export async function updateApprovalPackageTemplate(
  template: UpdateApprovalPackageTemplateInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackageTemplate({ template });
  return data;
}

export async function deleteApprovalPackageTemplate(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteApprovalPackageTemplate({ id });
  return data;
}

export async function createApprovalPackageTemplateFromPackage(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateApprovalPackageTemplateFromPackage({ id });
  return data;
}

export async function createApprovalPackageTemplateSection(
  section: CreateApprovalPackageSectionInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateApprovalPackageTemplateSection({ section });
  return data;
}

export async function updateApprovalPackageTemplateSection(
  section: UpdateApprovalPackageSectionInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackageTemplateSection({ section });
  return data;
}

export async function deleteApprovalPackageTemplateSection(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteApprovalPackageTemplateSection({ id });
  return data;
}

export async function createApprovalPackageTemplateSubSection(
  sectionId: number,
  type: ApprovalPackageSubSectionTypeEnum,
  checklistId?: number,
  gcEstimateTypeId?: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateApprovalPackageTemplateSubSection({
    sectionId,
    type,
    checklistId,
    gcEstimateTypeId,
  });
  return data;
}

export async function updateApprovalPackageTemplateSubSection(
  subSection: UpdateApprovalPackageSubSectionInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackageTemplateSubSection({ subSection });
  return data;
}

export async function deleteApprovalPackageTemplateSubSection(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteApprovalPackageTemplateSubSection({ id });
  return data;
}

export async function createApprovalPackageTemplateSubSectionTableCell(subSectionId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateApprovalPackageTemplateSubSectionTableCell({ id: subSectionId });
  return data;
}

export async function deleteApprovalPackageTemplateSubSectionTableCell(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteApprovalPackageTemplateSubSectionTableCell({ id });
  return data;
}

export async function setEntriesForApprovalPackageTemplateSubSectionTableCell(id: number, entries: IcSubSectionTableCellEntryInput[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.SetEntriesForApprovalPackageTemplateSubSectionTableCell({ id, entries });
  return data;
}

export async function setApprovalPackageTemplateSubSectionTableColumns(id: number, columns: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.SetApprovalPackageTemplateSubSectionTableColumns({ id, columns });
  return data;
}

export async function createLabel(label: CreateLabelInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateLabel({ label });
  return data;
}

export async function updateLabel(label: UpdateLabelInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateLabel({ label });
  return data;
}

export async function deleteLabel(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteLabel({ id });
  return data;
}

export async function assignChangeEventsToParent(dealId: number, changeEventIds: number[], parentId?: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AssignChangeEventsToParent({ dealId, changeEventIds, parentId });
  return data;
}

export async function assignPotentialChangeOrdersToParent(dealId: number, potentialChangeOrderIds: number[], parentId?: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AssignPotentialChangeOrdersToParent({ dealId, potentialChangeOrderIds, parentId });
  return data;
}

export async function assignChangeOrdersToParent(dealId: number, changeOrderIds: number[], parentId?: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AssignChangeOrdersToParent({ dealId, changeOrderIds, parentId });
  return data;
}

export async function runDefaultForecastRules(fkDeal: number, accountIds?: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RunDefaultForecastRules({ fkDeal, accounts: accountIds });
  return data;
}

export async function forecastCapitalizationAccounts(fkDeal: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.ForecastCapitalizationAccounts({ fkDeal });
  return data;
}

export async function forecastInterestAccount(fkDeal: number) {
    const client = await getAuthorizedGraphqlClient();
    const data = await client.ForecastInterestAccount({ fkDeal });
    return data;
  }

export async function createApprovalPackageType(approvalPackageType: CreateApprovalPackageTypeInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateApprovalPackageType({ approvalPackageType });
  return data;
}

export async function updateApprovalPackageType(approvalPackageType: UpdateApprovalPackageTypeInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateApprovalPackageType({ approvalPackageType });
  return data;
}

export async function deleteApprovalPackageType(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteApprovalPackageType({ id });
  return data;
}

export async function uploadDealArtifact(
  dealId: number,
  artifactId: number,
  file: any,
) {
  const headers = await getAuthorizedHeaders();
  const url = `${restBaseUrl}documents/deal/${dealId}/artifacts`;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fkArtifact', artifactId.toString());
  try {

    const response = await axios({
      method: 'POST',
      url: url,
      data: formData,
      headers: { ...headers },
    });
    return response;
  } catch (error) {
    console.log('error caught', error);
    throw new Error('error');
  }
}

export async function deleteDealArtifact(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteDealArtifact({ id });
  return data;
}

export async function bulkEditVisibleDealEvents(pipelineId: number, dealIds: number[], eventIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.BulkEditVisibleDealEvents({ pipelineId, dealIds, eventIds });
  return data;
}

export async function bulkUnhideDealEvents(pipelineId: number, dealIds: number[], eventIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.BulkUnhideDealEvents({ pipelineId, dealIds, eventIds });
  return data;
}

export async function uploadContactAttachment(
  contactId: number,
  displayName: string,
  file: any
) {
  const headers = await getAuthorizedHeaders();
  const url = `${restBaseUrl}documents/contact-details/${contactId}/attachments`;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('displayName', displayName);
  const response = await axios({
    method: 'POST',
    url: url,
    data: formData,
    headers: { ...headers },
  });
  return response;
}

export async function deleteContactAttachment(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteContactAttachment({ id });
  return data;
}

export async function uploadFirmAttachment(
  firmId: number,
  displayName: string,
  file: any
) {
  const headers = await getAuthorizedHeaders();
  const url = `${restBaseUrl}documents/firm-details/${firmId}/attachments`;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('displayName', displayName);
  const response = await axios({
    method: 'POST',
    url: url,
    data: formData,
    headers: { ...headers },
  });
  return response;
}

export async function deleteFirmAttachment(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteFirmAttachment({ id });
  return data;
}

export async function createInterestRate(interestRate: CreateInterestRateInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateInterestRate({ interestRate });
  return data;
}

export async function updateInterestRate(interestRate: UpdateInterestRateInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateInterestRate({ interestRate });
  return data;
}

export async function deleteInterestRate(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteInterestRate({ id });
  return data;
}

export async function uploadInterestRateFile(
  file: any
) {
  const headers = await getAuthorizedHeaders();
  const url = `${restBaseUrl}documents/interest-rates`;
  const formData = new FormData();
  formData.append('file', file);
  const response = await axios({
    method: 'POST',
    url: url,
    data: formData,
    headers: { ...headers },
  });
  return response;
}