import React, { HTMLAttributes, FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { mixin, font } from 'shared/utils/styles';
import FieldWrapper, { FieldWrapperProps } from 'shared/components/form/index';
import { useField } from 'formik';

const ToggleSwitchWrapper = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const ToggleSwitchInput = styled.input`
  display: none;
  & + span:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 5px;
    bottom: 4px;
    background-color: #fff;
    transition: 0.4s;
    border-radius: 50%;
  }

  &:checked + span {
    background-color: ${({ theme }) => theme.primary};
    &:before {
      transform: translateX(17px);
    }
  }
`;

const SwitchContainer = styled.span`
  ${mixin.clickable};
  background-color: ${({ theme }) => theme.secondary};
  width: 40px;
  height: 20px;
  border-radius: 10px;
`;

const StatusLabel = styled.span`
  ${font.medium};
  padding-left: 10px;
  ${mixin.clickable};
`;

interface ToggleSwitchProps extends HTMLAttributes<HTMLInputElement> {
  on?: boolean;
  onLabel?: string;
  offLabel?: string;
  showStatus?: boolean;
  onToggle?: () => void;
}

const ToggleSwitch: FunctionComponent<ToggleSwitchProps> = ({
  on = false,
  showStatus = false,
  onLabel = 'On',
  offLabel = 'Off',
  onToggle = () => false,
  ...props
}) => {
  return (
    <ToggleSwitchWrapper onClick={onToggle} style={props.style}>
      <ToggleSwitchInput
        type={'checkbox'}
        checked={on}
        role={'switch'}
        {...props}
      />
      <SwitchContainer />
      {showStatus && <StatusLabel>{on ? onLabel : offLabel}</StatusLabel>}
    </ToggleSwitchWrapper>
  );
};

export interface ToggleSwitchFieldProps
  extends ToggleSwitchProps,
    FieldWrapperProps {
  name: string;
}

export const ToggleSwitchField: FunctionComponent<ToggleSwitchFieldProps> = ({
  label,
  error,
  ...props
}) => {
  return (
    <FieldWrapper label={label} error={error} {...props}>
      <ToggleSwitch {...props} />
    </FieldWrapper>
  );
};

export const ToggleSwitchFormikField: FunctionComponent<ToggleSwitchFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const setValue = helpers.setValue;
  return (
    <ToggleSwitchField
      error={meta.error}
      {...props}
      {...field}
      on={field.value}
      onToggle={() => setValue(!field.value)}
    />
  );
};

export default ToggleSwitch;
