import { ClientError, GraphQLClient } from 'graphql-request';
import {
  CreateTransactionInput,
  getSdk,
  SdkFunctionWrapper,
} from '../graphql/generated';
import authModuleInstance from 'shared/auth/msal';
import axios from 'axios';

const graphEndpoint = process.env.GRAPHQL_ENDPOINT!;
export const restBaseUrl = process.env.REST_ENDPOINT!;

const clientErrorWrapper: SdkFunctionWrapper = async <T>(
  action: () => Promise<T>
): Promise<T> => {
  try {
    const result = await action();
    return result;
  } catch (e) {
    if (e instanceof ClientError) {
      return (e.response as unknown) as T;
    }
    throw e;
  }
};

export const getOrgPathComponent = () => {
  const pathComponents = window.location.pathname.split('/');
  if (pathComponents.length > 1 && pathComponents[1] != '') {
    return pathComponents[1];
  } else {
    return '';
  }
};

const getAuthorizedGraphQlClientWithScopes = async (scopes: string[]) => {
  const accessToken = await authModuleInstance.acquireToken(scopes);
  const graphqlClient = new GraphQLClient(graphEndpoint);
  graphqlClient.setHeader('Authorization', `Bearer ${accessToken}`);
  // console.log(accessToken);
  const orgPath = getOrgPathComponent();
  if (orgPath != '') {
    graphqlClient.setHeader('X-TENANT', orgPath);
  }
  return getSdk(graphqlClient, clientErrorWrapper);
};

export const getAuthorizedGraphqlClient = async () => {
  return await getAuthorizedGraphQlClientWithScopes([
    'api://91a83884-c92d-44d8-800b-1d0ee760382e/Default',
  ]);
};

const getAuthorizedlClientWithScopes = async (scopes: string[]) => {
  const accessToken = await authModuleInstance.acquireToken(scopes);
  const orgPath = getOrgPathComponent();
  let headers: Record<string, string> = {
    Authorization: `Bearer ${accessToken}`,
  };
  if (orgPath != '') {
    headers = { ...headers, 'X-TENANT': orgPath };
  }
  const client = axios.create({
    baseURL: restBaseUrl,
    headers: headers,
  });
  return client;
};

export const getAuthorizedHeaders = async () => {
  const accessToken = await authModuleInstance.acquireToken([
    'api://91a83884-c92d-44d8-800b-1d0ee760382e/Default',
  ]);
  const orgPath = getOrgPathComponent();
  let headers: Record<string, string> = {
    Authorization: `Bearer ${accessToken}`,
  };
  if (orgPath != '') {
    headers = { ...headers, 'X-TENANT': orgPath };
  }
  return headers;
};

export const getAuthorizedlApprovalPackageExhibitUrl = async (
  approvalPackageId: number,
  fileId: number
) => {
  const headers = await getAuthorizedHeaders();
  return {
    url: `${restBaseUrl}documents/approvalPackage/${approvalPackageId}/${fileId}`,
    httpHeaders: headers,
  };
};

export const getAuthorizedClient = async () => {
  return await getAuthorizedlClientWithScopes([
    'api://91a83884-c92d-44d8-800b-1d0ee760382e/Default',
  ]);
};

const getAuthorizedPdfClientWithScopes = async (scopes: string[]) => {
  const accessToken = await authModuleInstance.acquireToken(scopes);
  // console.log('pdf access token', accessToken)
  const orgPath = getOrgPathComponent();
  let headers: Record<string, string> = {
    Authorization: `Bearer ${accessToken}`,
  };
  if (orgPath != '') {
    headers = { ...headers, 'X-TENANT': orgPath };
  }
  const client = axios.create({
    baseURL: process.env.PDF_RENDERER_ENDPOINT,
    headers: headers,
  });
  return client;
};

const getAuthorizedEmailerClientWithScopes = async (scopes: string[]) => {
  const accessToken = await authModuleInstance.acquireToken(scopes);
  const orgPath = getOrgPathComponent();
  let headers: Record<string, string> = {
    Authorization: `Bearer ${accessToken}`,
  };
  if (orgPath != '') {
    headers = { ...headers, 'X-TENANT': orgPath };
  }
  const client = axios.create({
    baseURL: process.env.EMAILER_ENDPOINT,
    headers: headers,
  });
  return client;
};

export const getAuthorizedPdfClient = async () => {
  return await getAuthorizedPdfClientWithScopes([
    'api://6d8458d3-d7ca-4368-aec3-4c8db7462a25/Default',
  ]);
};

export const getAuthorizedEmailerClient = async () => {
  return await getAuthorizedEmailerClientWithScopes([
    'api://dbb2c549-6aa8-4019-b8f0-f339bf6e311c/Default',
  ]);
};

export async function downloadApprovalPackagePdf(approvalPackageId: number) {
  const client = await getAuthorizedPdfClient();
  const data = await client.post('', { approvalPackageId }, { responseType: 'blob' });
  return data;
}

export async function sendEmails(
  senderId: number, 
  toEmails: string[], 
  subjectLine: string, 
  message: string,
  senderName: string,
  reviewPath: string,
  packageString: string,
  ) {
  const client = await getAuthorizedEmailerClient();
  const data = await client.post('', {
    'senderId': senderId,
    'toEmails': toEmails,
    'subjectLine': subjectLine,
    'message': message,
    'senderName': senderName,
    'reviewPath': reviewPath,
    'packageString': packageString,
  }, { responseType: 'text' });
  return data;
}

export async function getCurrentUser(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetCurrentUser();
  return data;
}

export async function getPortfolios(key: string, orgName: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPortfolios();
  return data;
}

export async function getPortfolio(key: string, portfolioId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPortfolio({ id: portfolioId });
  return data;
}

export async function getPortfolioPinnedReports(
  key: string,
  portfolioId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPinnedPortfolioReports({ id: portfolioId });
  return data;
}

export async function getPortfolioUsers(key: string, portfolioId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPortfolioUsers({ id: portfolioId });
  return data;
}

export async function getPortfolioDeals(key: string, portfolioId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPortfolioDeals({ id: portfolioId });
  return data;
}

export async function getPortfolioUserAccess(key: string, portfolioId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPortfolioUserAccess({ id: portfolioId });
  return data;
}

export async function getDeals(key: string, showArchivedDeals: boolean) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDeals({ showArchivedDeals });
  return data;
}

export async function getDealsPaginated(
  key: string, 
  showArchivedDeals: boolean,
  limit: number,
  offset: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealsPaginated({ showArchivedDeals, limit, offset });
  return data;
}

export async function getDeal(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDeal({ id: dealId });
  return data;
}

export async function getDealInteractions(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealInteractions({ id: dealId });
  return data;
}

export async function getDealUserAccess(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealUserAccess({ id: dealId });
  return data;
}

export async function getPipelineUserAccess(key: string, pipelineId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPipelineUserAccess({ id: pipelineId });
  return data;
}

export async function getDealStatusUpdates(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealStatusUpdates({ id: dealId });
  return data;
}

export async function getDealStatusUpdate(
  key: string,
  dealId: number,
  statusUpdateId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealStatusUpdate({ id: dealId, statusUpdateId });
  return data;
}

export async function getDealStatusUpdateTemplates(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealStatusUpdateTemplates();
  return data;
}

export async function getDealStatusUpdateTemplate(
  key: string,
  statusUpdateTemplateId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealStatusUpdateTemplate({ id: statusUpdateTemplateId });
  return data;
}

export async function getDealStatuses(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealStatuses();
  return data;
}

export async function getDealSchedule(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealSchedule({ id: dealId });
  return data;
}

export async function getDealKeyMilestones(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealKeyMilestones({ id: dealId });
  return data;
}

export async function getDealEvent(
  key: string,
  dealId: number,
  dealEventId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealEvent({ id: dealId, dealEventId });
  return data;
}

export async function getDealDocuments(
  key: string,
  dealId: number,
  folderId?: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealDocuments({
    id: dealId,
    fkFolder: folderId,
  });
  return data;
}

export async function getFolders(key: string, folderIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetFolders({ ids: folderIds });
  return data;
}

export async function downloadDealDocument(fkDeal: number, fkFile: number) {
  const client = await getAuthorizedClient();
  const data = await client.get(`documents/deal/${fkDeal}/${fkFile}`);
  return data;
}

export async function getChecklists(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetChecklists();
  return data;
}

export async function getChecklist(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetChecklist({ id });
  return data;
}

export async function getDealChecklists(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealChecklists({ id: dealId });
  return data;
}

export async function getChecklistItemStatuses(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetChecklistItemStatuses();
  return data;
}

export async function getChecklistCategories(key: string, checklistId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetChecklistCategories({ id: checklistId });
  return data;
}

export async function getTasks(key: string, opportunityId: string) {
  const { data } = await axios.get(
    'https://www.mocky.io/v2/5ed96b9b31000037dfc4ed72?mocky-delay=500ms'
  );
  return data;
}

export async function getTask(key: string, taskId: string) {
  const { data } = await axios.get(
    'https://run.mocky.io/v3/69d26ff0-1373-4e20-9116-28d826202ef6?mocky-delay=500ms'
  );
  return data;
}

export async function getComments(key: string, entityId: string) {
  const { data } = await axios.get(
    'https://www.mocky.io/v2/5edac8713200002b005d2613?mocky-delay=500ms'
  );
  return data;
}

export async function getReportingMetadata(key: string) {
  const { data } = await axios.get(
    'https://run.mocky.io/v3/eff82413-668b-4203-ae99-da4f79cafd6e?mocky-delay=500ms'
  );
  return data;
}

export async function getDocuments(
  key: string,
  opportunityId: string,
  pathComponents: string[] | undefined
) {
  const { data } = await axios.get(
    'https://www.mocky.io/v2/5ed97f643300000e0079e514?mocky-delay=500ms'
  );
  return data;
}

export async function getUpdateReports(key: string, opportunityId: string) {
  const { data } = await axios.get(
    'https://www.mocky.io/v2/5ea0706d320000510094af2b?mocky-delay=500ms'
  );
  return data;
}

export async function getPortfolioReports(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetReports();
  return data;
}

export async function getReports(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetReports();
  return data;
}

export async function getPortfolioReport(key: string, reportId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetReport({ id: reportId });
  return data;
}

export async function getReport(key: string, reportId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetReport({ id: reportId });
  return data;
}

export async function getReportQueryResults(key: string, query: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetReportResults({ query });
  return data;
}

export async function getTeam(key: string, opportunityId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealTeam({ id: opportunityId });
  return data;
}

export async function getDealPartners(key: string, opportunityId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealPartners({ id: opportunityId });
  return data;
}

export async function getTeamMemberRoles(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetTeamMemberRoles();
  return data;
}

export async function getPartnerRoles(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPartnerRoles();
  return data;
}

export async function getFirm(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetFirm({ id });
  return data;
}

export async function getPipelineFirms(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPipelineFirms({ id });
  return data;
}

export async function getDealPipelineFirms(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealPipelineFirms({ id });
  return data;
}

export async function getPipelineContacts(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPipelineContacts({ id });
  return data;
}

export async function getPipelineModules(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPipelineModules({ id });
  return data;
}

export async function getModules(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetModules();
  return data;
}

export async function getInteraction(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetInteraction({ id });
  return data;
}

export async function getDealPipelineContacts(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealPipelineContacts({ id });
  return data;
}

export async function getContact(key: string, contactId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetContact({ id: contactId });
  return data;
}

export async function getBuildings(key: string, opportunityId: string) {
  const { data } = await axios.get(
    'https://run.mocky.io/v3/5e4358d5-d5c0-4c0f-9d1a-f6e189a869f6?mocky-delay=500ms'
  );
  return data;
}

export async function getBuilding(key: string, buildingId: string) {
  const { data } = await axios.get(
    'https://run.mocky.io/v3/4c6dbce0-a254-4a46-b088-e7d1da98aa8f?mocky-delay=500ms'
  );
  return data;
}

export async function getBonds(key: string, opportunityId: string) {
  const { data } = await axios.get(
    'https://run.mocky.io/v3/226b9d5c-ea46-44f5-964a-3be3b8e74847?mocky-delay=500ms'
  );
  return data;
}

export async function getBond(key: string, opportunityId: string) {
  const { data } = await axios.get(
    'https://run.mocky.io/v3/54367139-2f37-4a72-b166-6d84aa24b991?mocky-delay=500ms'
  );
  return data;
}

export async function getAllApprovals(key: string, opportunityId: string) {
  const { data } = await axios.get(
    'https://www.mocky.io/v2/5ed933da310000f5dec4ec24?mocky-delay=500ms'
  );
  return data;
}

export async function getIcDashboard(key: string) {
  const { data } = await axios.get(
    'https://www.mocky.io/v2/5ea0774c3200006f0094af4d?mocky-delay=500ms'
  );
  return data;
}
export async function getGCEstimates(key: string, opportunityId: string) {
  const { data } = await axios.get(
    'https://www.mocky.io/v2/5ea06d47320000204394af12?mocky-delay=500ms'
  );
  return data;
}

export async function getGCEstimate(key: string, gcEstimateId: string) {
  const { data } = await axios.get(
    'https://run.mocky.io/v3/d7a0c864-1567-486c-8938-11de2eb25376?mocky-delay=500ms'
  );
  return data;
}

export async function getStates() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetStates();
  return data;
}

export async function getRegions() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetRegions();
  return data;
}

export async function getMarkets() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetMarkets();
  return data;
}

export async function getMarketTypes() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetMarketTypes();
  return data;
}

export async function getMarketSegments() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetMarketSegments();
  return data;
}

export async function getSubmarkets() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetSubmarkets();
  return data;
}

export async function getMsas() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetMsas();
  return data;
}

export async function getProductTypes() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetProductTypes();
  return data;
}

export async function getEstimateTypes() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetEstimateTypes();
  return data;
}

export async function getBuildingTypes() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetBuildingTypes();
  return data;
}

export async function getConstructionTypes() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetConstructionTypes();
  return data;
}

export async function getStructures() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetStructures();
  return data;
}

export async function getDealTypes() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealTypes();
  return data;
}

export async function getAssetClasses() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetAssetClasses();
  return data;
}

export async function getHoaTypes() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetHoaTypes();
  return data;
}

export async function getFunds() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetFunds();
  return data;
}

export async function getFundEntities() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetFundEntities();
  return data;
}

export async function getPriorityLevels() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPriorityLevels();
  return data;
}

export async function getPipelines() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPipelines();
  return data;
}

export async function getPipeline(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPipeline({ id });
  return data;
}

export async function getBudgetOffsetRuleFilters() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetBudgetOffsetRuleFilters();
  return data;
}

export async function getDealAttributes() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealAttributes();
  return data;
}

export async function getForecastRules() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetForecastRules();
  return data;
}

export async function getForecastRule(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetForecastRule({ id });
  return data;
}

export async function getPipelineDealStages(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPipelineDealStages({ id });
  return data;
}

export async function getAnalysisScenarios() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetAnalysisScenarios();
  return data;
}

export async function getAnalysisTypes() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetAnalysisTypes();
  return data;
}

export async function getMetrics() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetMetrics();
  return data;
}

export async function getTextMetrics() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetTextMetrics();
  return data;
}

export async function getCurrentOrganizationInfo(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetCurrentOrganizationInfo();
  return data;
}

export async function getOrganizationUserAccess(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetOrganizationUserAccess();
  return data;
}

export async function getOrganizationUsers(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetOrganizationUsers();
  return data;
}

export async function getOrganizationGroups(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetOrganizationGroups();
  return data;
}

export async function getOrganizationGroup(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetOrganizationGroup({ id });
  return data;
}

export async function getPermissionRoles(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPermissionRoles();
  return data;
}

export async function getPortfolioView(
  key: string,
  portfolioId: number,
  viewId: number,
  showArchivedDeals: boolean,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPortfolioView({
    id: portfolioId,
    viewId: viewId,
    showArchivedDeals: showArchivedDeals,
  });
  return data;
}

export async function getPortfolioViewPaginated(
  key: string,
  portfolioId: number,
  viewId: number,
  showArchivedDeals: boolean,
  limit: number,
  offset: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPortfolioViewPaginated({
    id: portfolioId,
    viewId: viewId,
    showArchivedDeals: showArchivedDeals,
    limit:limit,
    offset: offset
  });
  return data;
}

export async function getPortfolioViewColumns(
  key: string,
  portfolioId: number,
  viewId: number,
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPortfolioViewColumns({
    id: portfolioId,
    viewId: viewId,
  });
  return data;
}

export async function getPortfolioViews(key: string, portfolioId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPortfolioViews({ id: portfolioId });
  return data;
}

export async function getViewColumnOptions(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AllViewColumnOptions();
  return data;
}

export async function getEvents(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetEvents();
  return data;
}

export async function getEvent(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetEvent({ id });
  return data;
}

export async function getDealAnalyses(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealAnalyses({ id: dealId });
  return data;
}

export async function getDealAnalysis(
  key: string,
  dealId: number,
  analysisId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealAnalysis({
    id: dealId,
    fkAnalysis: analysisId,
  });
  return data;
}

export async function getDealCurrentAnalysis(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealCurrentAnalysis({ id: dealId });
  return data;
}

export async function getDealMasterBudgetOverview(
  key: string,
  dealId: number,
  fkGlAccountTree: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealMasterBudgetOverview({
    id: dealId,
    fkGlAccountTree,
  });
  return data;
}

export async function getDealTransactions(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealTransactions({ id: dealId });
  return data;
}

export async function getDealTransaction(
  key: string,
  dealId: number,
  transactionId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealTransaction({ id: dealId, transactionId });
  return data;
}

export async function getDealRevisions(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealRevisions({ id: dealId });
  return data;
}

export async function getDealContracts(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealContracts({ id: dealId });
  return data;
}

export async function getDealContract(
  key: string,
  dealId: number,
  contractId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealContract({ id: dealId, contractId });
  return data;
}

export async function getDealPublishedCapitalForecasts(
  key: string,
  dealId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealPublishedCapitalForecasts({ id: dealId });
  return data;
}

export async function getDealPublishedMasterBudgetOverview(
  key: string,
  dealId: number,
  publishedCapitalForecastId: number,
  fkGlAccountTree: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealPublishedMasterBudgetOverview({
    id: dealId,
    publishedCapitalForecastId,
    fkGlAccountTree,
  });
  return data;
}

export async function getDealMasterBudgetData(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealMasterBudgetData({ id: dealId });
  return data;
}

export async function getDealTransactionFormOptions(
  key: string,
  dealId: number,
  accountTreeId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealTransactionFormOptions({
    id: dealId,
    accountTreeId,
  });
  return data;
}

export async function getBudgetRevision(
  key: string,
  dealId: number,
  revisionId: number,
  accountTreeId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetBudgetRevision({
    id: dealId,
    revisionId,
    accountTreeId,
  });
  return data;
}

export async function getDealAccountForecasts(
  key: string,
  dealId: number,
  accountIds: number[],
  start?: string,
  end?: string,
  excludeTransactionId?: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealAccountForecasts({
    id: dealId,
    accountIds,
    start,
    end,
    excludeTransactionId,
  });
  return data;
}

export async function getPublishedScheduleInstances(
  key: string,
  dealId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPublishedScheduleInstances({ id: dealId });
  return data;
}

export async function getPublishedScheduleInstance(
  key: string,
  dealId: number,
  publishedScheduleInstanceId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPublishedScheduleInstance({
    id: dealId,
    fkPublishedScheduleInstance: publishedScheduleInstanceId,
  });
  return data;
}

export async function getDealBuildings(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealBuildings({ id: dealId });
  return data;
}

export async function getDealBuilding(
  key: string,
  dealId: number,
  buildingId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealBuilding({
    id: dealId,
    fkBuilding: buildingId,
  });
  return data;
}

export async function getDealBonds(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealBonds({ id: dealId });
  return data;
}

export async function getDealBond(key: string, dealId: number, bondId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealBond({ id: dealId, fkBond: bondId });
  return data;
}

export async function getDealGcEstimates(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealGcEstimates({ id: dealId });
  return data;
}

export async function getGcEstimateTypes(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetGcEstimateTypes();
  return data;
}

export async function getDealGcEstimate(
  key: string,
  dealId: number,
  gcEstimateId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealGcEstimate({
    id: dealId,
    fkGcEstimate: gcEstimateId,
  });
  return data;
}

export async function getDealPinnedMetrics(key: string, dealId: number, key2: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealPinnedMetrics({ id: dealId });
  return data;
}

export async function getAccountTypes(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetAccountTypes();
  return data;
}

export async function getContingencyCategories(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetContingencyCategories();
  return data;
}

export async function getAccountCategories(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetAccountCategories();
  return data;
}

export async function getAccounts(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetAccounts();
  return data;
}

export async function getAccount(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetAccount({ id });
  return data;
}

export async function getAccountTrees(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetAccountTrees();
  return data;
}

export async function getAccountTree(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetAccountTree({ id });
  return data;
}

export async function getDealChangeItems(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealChangeItems({ id: dealId });
  return data;
}

export async function getDealChangeEvent(
  key: string,
  dealId: number,
  changeEventId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealChangeEvent({
    id: dealId,
    fkChangeEvent: changeEventId,
  });
  return data;
}

export async function getDealChangeOrderPackages(key: string, dealId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealChangeOrderPackages({ id: dealId });
  return data;
}

export async function getDealChangeOrderPackage(
  key: string,
  dealId: number,
  changeOrderPackageId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealChangeOrderPackage({
    id: dealId,
    fkChangeOrderPackage: changeOrderPackageId,
  });
  return data;
}

export async function getDealPotentialChangeOrders(
  key: string,
  dealId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealPotentialChangeOrders({ id: dealId });
  return data;
}

export async function getDealPotentialChangeOrder(
  key: string,
  dealId: number,
  potentialChangeOrderId: number
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealPotentialChangeOrder({
    id: dealId,
    fkPotentialChangeOrder: potentialChangeOrderId,
  });
  return data;
}

export async function getChangeOrderStatuses(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetChangeOrderStatuses();
  return data;
}

export async function getPotentialChangeOrderTypes(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPotentialChangeOrderTypes();
  return data;
}

export async function getChangeEventTypes(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetChangeEventTypes();
  return data;
}

export async function getChangeEventReasons(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetChangeEventReasons();
  return data;
}

export async function getApprovalPackage(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetApprovalPackage({ id });
  return data;
}

export async function getApprovalPackages(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetApprovalPackages();
  return data;
}

export async function getDealApprovalPackages(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealApprovalPackages({ id });
  return data;
}

export async function getPublishedApprovalPackage(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPublishedApprovalPackage({ id });
  return data;
}

export async function getApprovalPackageStatuses(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetApprovalPackageStatuses();
  return data;
}

export async function getApprovalPackageReviewerStatuses(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetApprovalPackageReviewerStatuses();
  return data;
}

export async function getApprovalPackageTypes(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetApprovalPackageTypes();
  return data;
}

export async function getApprovalPackageType(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetApprovalPackageType({ id });
  return data;
}

export async function getApprovalPackageTableCellEntryOptions(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetApprovalPackageTableCellEntryOptions();
  return data;
}

export async function getApprovalPackageTemplates(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetApprovalPackageTemplates();
  return data;
}

export async function getApprovalPackageTemplate(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetApprovalPackageTemplate({ id });
  return data;
}

export async function getLabels(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetLabels();
  return data;
}

export async function getReportTypes(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetReportTypes();
  return data;
}

export async function getInvestmentTypes(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetInvestmentTypes();
  return data;
}

export async function getCrmStatuses(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetCrmStatuses();
  return data;
}

export async function getFirmTypes(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetFirmTypes();
  return data;
}

export async function getInteractionTypes(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetInteractionTypes();
  return data;
}

export async function getTransactionPeriodSummaries(key: string, id: number, fkGlAccount: number, period: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetTransactionPeriodSummaries({ id, fkGlAccount, period });
  return data;
}

export async function getDealArtifacts(key: string, id: number, key2: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealArtifacts({ id });
  return data;
}

export async function getArtifactTypes(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetArtifactTypes();
  return data;
}

export async function getContactAttachments(key: string, id: number, key2: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetContactAttachments({ id });
  return data;
}

export async function getFirmAttachments(key: string, id: number, key2: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetFirmAttachments({ id });
  return data;
}

export async function getInterestRates() {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetInterestRates();
  return data;
}

export async function getForecastInterestAccountStatus(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetForecastInterestAccountStatus({ id });
  return data;
}

export async function getLatestPipelineAnalysisMetricDistribution(key: string, analysisMetricId: number) {
    const client = await getAuthorizedGraphqlClient();
    const data = await client.GetLatestPipelineAnalysisMetricDistribution({ analysisMetricId });
    return data;
}

export async function getCategoricalDealCharts(key: string, id: number, birthDate: string, dealTypeIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetCategoricalDealCharts({ id, birthDate, dealTypeIds });
  return data;
}

export async function getNumericalDealCharts(key: string, id: number, birthDate: string, dealTypeIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetNumericalDealCharts({ id, birthDate, dealTypeIds });
  return data;
}

export async function getAnalyticsSummary(key: string, id: number, birthDate: string, dealTypeIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetAnalyticsSummary({ id, birthDate, dealTypeIds });
  return data;
}

export async function getDealQuarterEventsChart(key: string, pipelineId: number, fkEvent: number, yearsBack: number, dealTypeIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDealQuarterEventsChart({ pipelineId, fkEvent, yearsBack, dealTypeIds });
  return data;
}

export async function getFundChart(key: string, id: number, birthDate: string, dealTypeIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetFundChart({ id, birthDate, dealTypeIds });
  return data;
}