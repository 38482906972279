/* eslint-disable react/display-name */
import React, { FunctionComponent, forwardRef, FC } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Input, InputProps } from '../input/Input';
import '../../../css/react-datepicker.css';
import styled, { createGlobalStyle } from 'styled-components';
import { font } from 'shared/utils/styles';
import format from 'date-fns/format';
import { Button } from 'shared/components';
import FieldWrapper, { FieldWrapperProps } from '..';
import { useField } from 'formik';
import ReactDOM from 'react-dom';

type OverridenDatePickerProps = Omit<ReactDatePickerProps, 'onChange'> & {
  onChange?: (
    date: Date | [Date, Date] | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ) => void;
};

interface DatePickerProps extends OverridenDatePickerProps {
  placeholder?: string;
  inputProps?: InputProps;
}

const HeaderWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeaderDateText = styled.div`
  color: ${({ theme }) => theme.textDarkest};
  text-transform: uppercase;
  ${font.size(12)};
  ${font.medium};
`;

const el = document.body;

type DatePickerPopperContainerProps = {
  children: React.ReactNode;
};

const getPopperContainer = () => {
  const el = document.querySelector('body');
  return el != null ? el : document.body;
};

const DatePickerPopperContainer: FC<DatePickerPopperContainerProps> = ({
  children,
}) => ReactDOM.createPortal(children, getPopperContainer());

const GlobalDatePickerStyles = createGlobalStyle`
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    width: 100%;
    color: ${({ theme }) => theme.textDark};
    text-align: center;
  }

  .react-datepicker {
    background-color: ${({ theme }) => theme.isDark ? theme.backgroundMedium : theme.backgroundPrimary};
    color: ${({ theme }) => theme.danger};
    border: none; // Remove if you want the border
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.isDark ? theme.backgroundMedium : theme.backgroundPrimary};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.isDark ? theme.backgroundMedium : theme.backgroundPrimary};
    color: white;
  }

  // Style the day names (Sun, Mon, Tue, etc.)
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.textDark};
  }

  // Style the month and year in the header
  .react-datepicker__current-month,
  .react-datepicker__header__dropdown button,
  .react-datepicker__navigation {
    color: ${({ theme }) => theme.textDark};
  }

  // Style the individual days
  .react-datepicker__day, 
  .react-datepicker__time-name {
    color: ${({ theme }) => theme.textDark};
  }

  // Style the selected day
  .react-datepicker__day--selected {
    color: #fff;
    background-color: ${({ theme }) => theme.primary};
  }

  // Style the day when hovered
  .react-datepicker__day:hover {
    color: ${({ theme }) => theme.textDark};
    background-color: ${({ theme }) => theme.isDark ? '#404040' : ''};
  }
`;

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  (
    {
      selected,
      onChange,
      placeholder = 'Select a date...',
      inputProps = {},
      ...props
    },
    ref
  ) => {
    return (
      <>
        <GlobalDatePickerStyles/>
        <ReactDatePicker
          selected={selected}
          wrapperClassName='react-datepicker'
          onChange={(date, event) => {
            if (onChange) onChange(date, event);
          }}
          customInput={<Input ref={ref} {...inputProps} />}
          showPopperArrow={false}
          isClearable={true}
          placeholderText={placeholder}
          popperContainer={DatePickerPopperContainer}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <HeaderWrapper>
              <Button
                slim={true}
                variant={'simple'}
                icon={'chevron-left'}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              />
              <HeaderDateText>{format(date, 'MMMM yyyy')}</HeaderDateText>
              <Button
                slim={true}
                variant={'simple'}
                icon={'chevron-right'}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              />
            </HeaderWrapper>
          )}
          {...props}
        />
      </>
    );
  }
);

export default DatePicker;

interface DatePickerFieldProps extends DatePickerProps, FieldWrapperProps {
  name: string;
}

export const DatePickerField = forwardRef<
  HTMLInputElement,
  DatePickerFieldProps
>(({ label, error, ...props }, ref) => {
  return (
    <FieldWrapper label={label} error={error} {...props}>
      <DatePicker {...props} ref={ref} />
    </FieldWrapper>
  );
});

export const DatePickerFormikField: FunctionComponent<DatePickerFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <DatePickerField
      error={meta.error}
      {...props}
      {...field}
      onChange={(date) => setValue(date)}
      selected={field.value}
    />
  );
};
