import React, { FunctionComponent } from 'react';
import {
  StatusCard,
  PageDetails,
  Row,
  Column,
  HorizontalSeparator,
} from 'shared/components';
import { useRouteMatch, useParams, Route, useHistory } from 'react-router-dom';
import {
  useGetDealStatusUpdates,
} from 'shared/hooks/api';
import { StatusVariant } from 'shared/components/card/StatusCard';
import StatusUpdateComments from './StatusUpdateComments';
import { RoutedStatusUpdateDetails } from '../../modals/StatusUpdateDetails';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import CreateStatusUpdateModal from '../modals/CreateStatusUpdateModal';

interface StatusUpdatesProps {
  onCommentUpdated: () => void;
  onClose: () => void;
  setIsStatusUpdatesClicked: (value: boolean) => void;
}

interface RouteParams {
  orgName: string;
  oppId: string;
}

const StatusUpdates: FunctionComponent<StatusUpdatesProps> = ({
  onCommentUpdated,
  onClose,
  setIsStatusUpdatesClicked
}) => {
  const { oppId, orgName } = useParams<RouteParams>();
  const { data, error, isFetching, refetch } = useGetDealStatusUpdates(
    Number(oppId)
  );
  const match = useRouteMatch();
  const history = useHistory();

  const newStatusUpdateModalHelpers = createQueryParamModalHelpers(
    'new-status-update'
  );

  return (
    <PageDetails>
      <Row gap={10}>
        {data &&
          data.deal?.statusUpdates &&
          data.deal.statusUpdates.map((update) => {
            return (
              <React.Fragment key={update.id}>
                <Column>
                  <StatusCard
                    variant={update.status.color as StatusVariant}
                    statusUpdateId={update.id}
                    status={update.status}
                    title={update.title}
                    message={update.message}
                    authorName={update.createdBy?.displayName || 'Unknown'}
                    modifiedDate={update.updatedAt}
                    onViewComments={() =>
                      history.push(`${match.url}/${update.id}/comments`)
                    }
                    onViewDetails={() => {
                      return (history.push(`/${orgName}/deals/${oppId}/summary/statusUpdate/${update.id}`))
                    }
                    }
                    oppId={Number(oppId)}
                    commentCount={update.commentCount}
                    labels={update.labels || []}
                  />
                </Column>
                <Column>
                  <HorizontalSeparator />
                </Column>
              </React.Fragment>
            );
          })}
      </Row>
      {newStatusUpdateModalHelpers.isOpen() && (
        <CreateStatusUpdateModal
          oppId={Number(oppId)}
          onClose={newStatusUpdateModalHelpers.close}
        />
      )}
      <Route
        path={`${match.path}/statusUpdate/:statusUpdateId`}
        render={() => (
          <RoutedStatusUpdateDetails
            onClose={() => history.push(match.url)}
          />
        )}
      />
      <Route
        path={`${match.path}/:statusUpdateId/comments`}
        render={() => (
          <StatusUpdateComments
            onClose={() => history.push(match.url)}
            onCommentUpdated={() => {
              refetch();
              onCommentUpdated();
            }}
          />
        )}
      />
    </PageDetails>
  );
};

export default StatusUpdates;