import { useField } from 'formik';
import React, {
  forwardRef,
  FunctionComponent,
  ReactNode,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createEditor, Editor, Node, Transforms, Text } from 'slate';
import { withHistory } from 'slate-history';
import { ReactEditor, Slate, withReact } from 'slate-react';
import FieldWrapper, { FieldWrapperProps } from '..';
import { Debug } from '../..';
import { Separator } from '../../pageContent/Styles';
import {
  defaultEditorValue,
  EditorButton,
  LinkButton,
  renderElement,
  renderLeaf,
  serializeSlateDataToHtml,
  withLinks,
  withValidSelection,
} from './editor';
import {
  StyledEditable,
  StyledEditableWrapper,
  TextEditorTools,
  TextEditorToolsLeft,
  TextEditorToolsRight,
} from './Styles';
import * as Yup from 'yup';
import styled from 'styled-components';

type KeysToOmit = 'value' | 'onChange';

export interface TextEditorContextValue {
  setShowTools?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TextEditorContext = React.createContext<
  TextEditorContextValue
>({
  setShowTools: undefined,
});

interface TextEditorProps
  extends Omit<TextareaHTMLAttributes<HTMLDivElement>, KeysToOmit> {
  value?: Node[];
  onChange?: (value: Node[]) => void;
  placeholder?: string;
  editorTools?: ReactNode;
  tools?: ReactNode;
  readOnly?: boolean;
  debug?: boolean;
  editorRef?: React.MutableRefObject<ReactEditor | undefined>;
  borderless?: boolean;
}

const TextEditor: FunctionComponent<TextEditorProps> = ({
  value,
  onChange,
  editorTools,
  tools,
  editorRef,
  style,
  placeholder = 'Enter a value...',
  readOnly = false,
  debug = false,
  borderless = false,
  className,
  ...props
}) => {
  const [editor] = useState(() => withLinks(withReact(withHistory(createEditor()))));
  const [isFocused, setIsFocused] = useState(false);
  const renderContentLeaf = useCallback((props) => renderLeaf(props), []);
  const renderContentElement = useCallback((props) => renderElement({ ...props, editor }), [editor]);
  const [uncontrolledValue, setUncontrolledValue] = useState<Node[]>(
    value && value.length > 0 ? value : defaultEditorValue
  );
  
  const editorValue = useMemo(() => {
    if (onChange) {
      return value && value.length > 0 ? value : defaultEditorValue;
    } else {
      return uncontrolledValue;
    }
  }, [onChange, value, uncontrolledValue]);

  const textEditor = useMemo(() => {
    return withValidSelection(editorValue, editor);
  }, [editorValue, editor]);

  const editorOnChange = useMemo(() => {
    if (onChange) {
      return onChange;
    } else {
      return (newValue: Node[]) => setUncontrolledValue(newValue);
    }
  }, [onChange, setUncontrolledValue]);

  useEffect(() => {
    if (editorRef) {
      editorRef.current = textEditor;
    }
  }, [textEditor]);

  useEffect(() => {
    if (props.autoFocus) {
      ReactEditor.focus(textEditor);
      if (!textEditor.selection) {
        Transforms.select(textEditor, Editor.end(textEditor, []));
      }
    }
  }, [props.autoFocus, textEditor]);

  const [shouldShowTools, setShouldShowTools] = useState(false);

  return (
    <TextEditorContext.Provider value={{ setShowTools: setShouldShowTools }}>
      <React.Fragment>
        <Slate editor={textEditor} value={editorValue} onChange={editorOnChange}>
          <StyledEditableWrapper
            isFocused={isFocused}
            readOnly={readOnly}
            borderless={borderless}
            className={className}
          >
            <StyledEditable
              style={style}
              placeholder={placeholder}
              {...props}
              onFocus={(e) => {
                if (props.onFocus) {
                  props.onFocus(e);
                }
                setIsFocused(true);
              }}
              onBlur={(e) => {
                if (props.onBlur) {
                  props.onBlur(e);
                }
                setIsFocused(false);
              }}
              renderLeaf={renderContentLeaf}
              renderElement={renderContentElement}
              readOnly={readOnly}
            />
            {(shouldShowTools || isFocused) && (
              <TextEditorTools onMouseDown={(e) => e.preventDefault()}>
                <TextEditorToolsLeft>
                  <EditorButton format={'bold'} icon={'bold'} />
                  <EditorButton format={'italic'} icon={'italic'} />
                  <EditorButton format={'underline'} icon={'underline'} />
                  <EditorButton format={'strikethrough'} icon={'strikethrough'} />
                  <EditorButton format={'highlight'} icon={'highlighter'} />
                  <EditorButton format={'bulleted-list'} icon={'list-ul'} />
                  <LinkButton/>
                  <Separator />
                  {editorTools && editorTools}
                </TextEditorToolsLeft>
                <TextEditorToolsRight>{tools && tools}</TextEditorToolsRight>
              </TextEditorTools>
            )}
          </StyledEditableWrapper>
        </Slate>
        {debug && <Debug state={editor} />}
      </React.Fragment>
    </TextEditorContext.Provider>
  );
};

export default TextEditor;

interface TextEditorFieldProps extends TextEditorProps, FieldWrapperProps {
  name: string;
  borderless?: boolean;
}

export const TextEditorField: FunctionComponent<TextEditorFieldProps> = ({
  label,
  error,
  placeholder = 'Enter a value...',
  ...props
}) => {
  return (
    <FieldWrapper label={label} error={error} {...props}>
      <TextEditor placeholder={placeholder} {...props} />
    </FieldWrapper>
  );
};

export const TextEditorFormikField: FunctionComponent<TextEditorFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;
  return (
    <TextEditorField
      error={meta.error}
      {...props}
      {...field}
      onChange={(newValue) => setValue(newValue)}
      onBlur={(e) => {
        if (props.onBlur) {
          props.onBlur(e);
        }
        setTouched(true);
      }}
    />
  );
};

export const RequiredTextEditorSchema = (message?: string) =>
  Yup.mixed()
    .required(message)
    .test('isNonEmptyNode', '${path} is a required field.', (value) => {
      if (
        value?.length == 1 &&
        value[0]?.children.length &&
        (!value[0]?.children[0]?.text || value[0]?.children[0]?.text == '')
      ) {
        return false;
      } else {
        return true;
      }
    });

export const OptionalTextEditorSchema = Yup.mixed().nullable().notRequired();

export const parseTextEditedValue = (value?: string | null) => {
  try {
    if (value) {
      return JSON.parse(value);
    } else {
      return undefined;
    }
  } catch {
    return undefined;
  }
};

export type SlateEditedTextProps = {
  value?: any;
  maxLength?: number;
  style?: React.CSSProperties;
  className?: string;
};

// eslint-disable-next-line react/display-name
export const TextEditedContent = forwardRef<
  HTMLDivElement,
  SlateEditedTextProps
>(({ value, maxLength, style, className }, ref) => {
  const serialized = useMemo(() => {
    try {
      if (value) {
        let content = JSON.parse(value) as Node[];
        const currentLength = getNodesStringLength(content);
        if (maxLength != undefined) {
          content = splitNodeWithTextLimit(content, 0, maxLength);
        }
        return serializeSlateDataToHtml(content);
      } else {
        return '';
      }
    } catch {
      return '';
    }
  }, [value]);
  return (
    <React.Fragment>
      <StyledTextEditedContent
        ref={ref}
        className={className}
        style={style}
        dangerouslySetInnerHTML={{ __html: serialized }}
      />
    </React.Fragment>
  );
});

const StyledTextEditedContent = styled.div`
  white-space: pre-wrap;
`

const getSingleNodeString = (node: Node) => Node.string(node);
export const getNodesStringLength = (nodes: Node[]) => nodes.map(getSingleNodeString).join('\n').length

const splitNodeWithTextLimit = (children: Node[], currentLength: number, limit: number) => {
  let totalLength = currentLength
  return children.reduce((mergedNodes: Node[], node) => {
    const nodeLength = getSingleNodeString(node).length
    if (totalLength + nodeLength <= limit) {
      mergedNodes.push(node)
      totalLength = totalLength + nodeLength + 1
      return mergedNodes
    }

    if (totalLength < limit) {
      if (Text.isText(node)) {
        node.text = node.text.slice(0, limit - totalLength)
        totalLength = totalLength + node.text.length
      } else {
        node.children = splitNodeWithTextLimit(
          node.children,
          0,
          limit - totalLength,
        )
        totalLength =
          totalLength + node.children.map(getSingleNodeString).join('').length
      }

      mergedNodes.push(node)
    }

    return mergedNodes
  }, []);
}
