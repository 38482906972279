import { update } from 'lodash';
import { useMutation, queryCache } from 'react-query';
import { formatDate, formatDatetime } from 'shared/utils/formatting/formatters';
import { GraphQLResult } from '..';
import * as mutations from '../client/mutations';
import {
  AddTeamMemberInput,
  CreateContactInput,
  CreateFirmInput,
  ContactAssignmentInput,
  CreateTeamMemberRoleInput,
  UpdateTeamMemberInput,
  RemoveTeamMemberInput,
  UpdateContactInput,
  CreateDealInput,
  CreatePortfolioInput,
  UpdateDealChecklistItemInput,
  CreateChecklistInput,
  DealChecklistInput,
  UpdateChecklistInput,
  CreateChecklistItemInput,
  UpdateChecklistItemInput,
  CreateStatusUpdateInput,
  UpdateStatusUpdateInput,
  GetDealStatusUpdates,
  UpdateDealInput,
  CreateChecklistCategoryInput,
  UpdateDealEventInput,
  CreateKeyRiskInput,
  UpdateKeyRiskInput,
  CreateDealMeritInput,
  UpdateDealMeritInput,
  ConfigurableEntityEnum,
  DealMemberAssignmentInput,
  UpdateDealMemberRoleInput,
  RemoveDealMemberInput,
  GetDeal,
  UpdatePortfolioInput,
  UpdatePortfolioMemberRoleInput,
  RemovePortfolioMemberInput,
  PortfolioMemberAssignmentInput,
  GetPortfolio,
  UpdatePortfolioViewColumnInput,
  AddPortfolioViewColumnInput,
  GetPortfolioView,
  CreateDealEventInput,
  CreateEventInput,
  UpdateEventInput,
  UpdateAnalysisMetricInput,
  CreateAnalysisMetricInput,
  CreateAnalysisInput,
  UpdateAnalysisInput,
  UpdatePortfolioDealsInput,
  CreateContractInput,
  UpdateContractInput,
  CreateRevisionInput,
  UpdateRevisionInput,
  CreatePortfolioViewInput,
  UpdatePortfolioViewInput,
  CreateCommentVariables,
  UpdateCommentVariables,
  DeleteCommentVariables,
  CreateTransactionInput,
  CreateTransactionDetailInput,
  CreateGroupInput,
  UpdateGroupInput,
  CreateRevisionAccountInput,
  UpdateRevisionAccountInput,
  CreateMetricInput,
  UpdateMetricInput,
  CreatePipelineInput,
  UpdatePipelineInput,
  UpdateTeamMemberRoleInput,
  OrganizationMemberAssignmentInput,
  RemoveOrganizationMemberInput,
  UpdateOrganizationMemberRoleInput,
  UpdateTransactionInput,
  CreatePublishedCapitalForecastInput,
  CreatePublishedScheduleInstanceInput,
  UpdatePublishedScheduleInstanceInput,
  UpdatePublishedDealEventInput,
  CreatePublishedDealEventInput,
  CreateBuildingInput,
  UpdateBuildingInput,
  CreateBondInput,
  UpdateBondInput,
  CreateGcEstimateInput,
  UpdateGcEstimateInput,
  CreateGlAccountInput,
  UpdateGlAccountInput,
  UpdateGlAccountTreeInput,
  CreateGlAccountTreeInput,
  CreateChangeEventInput,
  UpdateChangeEventInput,
  UpdateChangeOrderPackageInput,
  CreateChangeOrderPackageInput,
  UpdatePotentialChangeOrderInput,
  CreatePotentialChangeOrderInput,
  UpdateApprovalPackageSubSectionTextInput,
  UpdateApprovalPackageSubSectionInput,
  ApprovalPackageSubSectionTypeEnum,
  UpdateApprovalPackageSectionInput,
  CreateApprovalPackageSectionInput,
  CreateApprovalPackageInput,
  UpdateApprovalPackageInput,
  CreateApprovalPackageReviewerInput,
  UpdateApprovalPackageReviewerInput,
  CreateApprovalPackageContributorInput,
  GetApprovalPackage,
  IcSubSectionTableCellEntryInput,
  GetApprovalPackageTemplate,
  UpdateApprovalPackageTemplateInput,
  CreateApprovalPackageTemplateInput,
  ApprovalPackageStatusEnum,
  CreatePipelineGeneralOffsetInput,
  UpdatePipelineGeneralOffsetInput,
  CreatePipelineAccountOffsetInput,
  UpdatePipelineAccountOffsetInput,
  UpdateForecastRuleInput,
  CreateForecastRuleInput,
  DealArchiveStatusEnum,
  GetDealSchedule,
  CreatePortfolioFilterGroupInput,
  CreateStatusUpdateTemplateInput,
  UpdateStatusUpdateTemplateInput,
  CreatePartnerRoleInput,
  UpdatePartnerRoleInput,
  AddDealPartnerInput,
  UpdateDealPartnerInput,
  RemoveDealPartnerInput,
  UpdateApprovalPackageSubSectionChecklistInput,
  CreateLabelInput,
  UpdateLabelInput,
  CreatePipelineContingencyCategoryInput,
  UpdatePipelineContingencyCategoryInput,
  CreateApprovalPackageTypeInput,
  UpdateApprovalPackageTypeInput,
  UpdateFirmInput,
  CreateInteractionInput,
  UpdateInteractionInput,
  PipelineMemberAssignmentInput,
  UpdatePipelineMemberRoleInput,
  RemovePipelineMemberInput,
  UpdateFirmRegionInput,
  CreateFirmRegionInput,
  DealEventTypeEnum,
  CreateAnalysisEventInput,
  UpdateAnalysisEventInput,
  GetPublishedScheduleInstance,
  UpdateTextMetricInput,
  CreateTextMetricInput,
  CreateAnalysisTextMetricInput,
  UpdateAnalysisTextMetricInput,
  UpdateAnalysisTypeInput,
  CreateAnalysisTypeInput,
  CreateInterestRateInput,
  UpdateInterestRateInput,
} from '../graphql/generated';

// Mutations

export const useCreateDeal = (config?: any) => {
  return useMutation(
    (deal: CreateDealInput) => {
      return mutations.createDeal(deal);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['deals']);
      },
      ...config,
    }
  );
};

export const useUpdateDeal = (config?: any) => {
  return useMutation(
    (deal: UpdateDealInput) => {
      return mutations.updateDeal(deal);
    },
    {
      onMutate: (variables) => {
        queryCache.setQueryData(
          ['deal', variables.id],
          (old?: GraphQLResult<GetDeal>) => {
            if (old && old.data?.deal) {
              const updated = { ...old };
              if (updated.data) {
                if (
                  variables.isPrivate != null ||
                  variables.isPrivate != undefined
                ) {
                  updated.data.deal = {
                    ...old.data.deal,
                    isPrivate: variables.isPrivate,
                  };
                }
                if (
                  variables.publicCanEdit != null ||
                  variables.publicCanEdit != undefined
                ) {
                  updated.data.deal = {
                    ...old.data.deal,
                    ...updated.data.deal,
                    publicCanEdit: variables.publicCanEdit,
                  };
                }
              }
              return updated;
            } else {
              return old;
            }
          }
        );
      },
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['deal', variables.id]);
      },
      ...config,
    }
  );
};

export const useCreatePortfolio = (config?: any) => {
  return useMutation((portfolio: CreatePortfolioInput) => {
    return mutations.createPortfolio(portfolio);
  }, config);
};

export const useUpdatePortfolio = (config?: any) => {
  return useMutation(
    (portfolio: UpdatePortfolioInput) => {
      return mutations.updatePortfolio(portfolio);
    },
    {
      onMutate: (variables) => {
        queryCache.setQueryData(
          ['portfolio', variables.id],
          (old?: GraphQLResult<GetPortfolio>) => {
            if (old && old.data?.portfolio) {
              const updated = { ...old };
              if (updated.data) {
                if (
                  variables.isPrivate != null ||
                  variables.isPrivate != undefined
                ) {
                  updated.data.portfolio = {
                    ...old.data.portfolio,
                    isPrivate: variables.isPrivate,
                  };
                }
                if (
                  variables.publicCanEdit != null ||
                  variables.publicCanEdit != undefined
                ) {
                  updated.data.portfolio = {
                    ...old.data.portfolio,
                    ...updated.data.portfolio,
                    publicCanEdit: variables.publicCanEdit,
                  };
                }
              }
              return updated;
            } else {
              return old;
            }
          }
        );
      },
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['portfolio', variables.id]);
      },
      ...config,
    }
  );
};

export const useUpdateDynamicPortfolioFilters = (config?: any) => {
  return useMutation(
    ({ id, filterGroups }: { id: number; filterGroups: CreatePortfolioFilterGroupInput[] }) => {
      return mutations.updateDynamicPortfolioFilters(id, filterGroups);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['portfolio', variables.id]);
      },
      ...config,
    }
  );
};

export const useCreateSchedule = (config?: any) => {
  return useMutation((schedule) => {
    return mutations.createSchedule(schedule);
  }, config);
};

export const useCreateChecklist = (config?: any) => {
  return useMutation(
    (checklist: CreateChecklistInput) => {
      return mutations.createChecklist(checklist);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['checklists']);
      },
      ...config,
    }
  );
};

export const useUpdateChecklist = (config?: any) => {
  return useMutation((checklist: UpdateChecklistInput) => {
    return mutations.updateChecklist(checklist);
  }, config);
};

export const useAddChecklistToDeal = (config?: any) => {
  return useMutation((dealChecklist: DealChecklistInput) => {
    return mutations.addChecklistToDeal(dealChecklist);
  }, config);
};

export const useRemoveChecklistFromDeal = (config?: any) => {
  return useMutation((dealChecklist: DealChecklistInput) => {
    return mutations.removeChecklistFromDeal(dealChecklist);
  }, config);
};

export const useCreateChecklistItem = (config?: any) => {
  return useMutation((checklistItem: CreateChecklistItemInput) => {
    return mutations.createChecklistItem(checklistItem);
  }, config);
};

export const useUpdateChecklistItem = (config?: any) => {
  return useMutation((checklistItem: UpdateChecklistItemInput) => {
    return mutations.updateChecklistItem(checklistItem);
  }, config);
};

export const useDeleteChecklist = (config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.deleteChecklist(id);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['checklists']);
      },
      ...config,
    }
  );
};

export const useDeleteChecklistItem = (config?: any) => {
  return useMutation((id: number) => {
    return mutations.deleteChecklistItem(id);
  }, config);
};

export const useUpdateDealChecklistItem = (config?: any) => {
  return useMutation((checklistItem: UpdateDealChecklistItemInput) => {
    return mutations.updateDealChecklistItem(checklistItem);
  }, config);
};

export const useCreateContact = (config?: any) => {
  return useMutation((contact: CreateContactInput) => {
    return mutations.createContact(contact);
  }, config);
};

export const useUpdateContact = (config?: any) => {
  return useMutation((contact: UpdateContactInput) => {
    return mutations.updateContact(contact);
  }, config);
};

export const useDeleteContact = (config?: any) => {
  return useMutation((id: number) => mutations.deleteContact(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['organizationContacts']);
    },
    ...config,
  });
};

export const useAssignContacts = (config?: any) => {
  return useMutation((assignment: ContactAssignmentInput) => {
    return mutations.assignContacts(assignment);
  }, config);
};

export const useUnassignContacts = (config?: any) => {
  return useMutation((assignment: ContactAssignmentInput) => {
    return mutations.unassignContacts(assignment);
  }, config);
};

export const useCreateTeamMember = (config?: any) => {
  return useMutation((teamMember: AddTeamMemberInput) => {
    return mutations.createTeamMember(teamMember);
  }, config);
};

export const useUpdateTeamMembers = (config?: any) => {
  return useMutation(({ dealId, roleId, userIds }: { dealId: number; roleId: number; userIds: number[] }) => {
    return mutations.updateTeamMembers(dealId, roleId, userIds);
  }, config);
};

export const useUpdateTeamMember = (config?: any) => {
  return useMutation((teamMember: UpdateTeamMemberInput) => {
    return mutations.updateTeamMember(teamMember);
  }, config);
};

export const useRemoveTeamMember = (config?: any) => {
  return useMutation((teamMember: RemoveTeamMemberInput) => {
    return mutations.removeTeamMember(teamMember);
  }, config);
};

export const useCreateDealPartner = (config?: any) => {
  return useMutation((partner: AddDealPartnerInput) => {
    return mutations.createDealPartner(partner);
  }, config);
};

export const useUpdateDealPartner = (config?: any) => {
  return useMutation((partner: UpdateDealPartnerInput) => {
    return mutations.updateDealPartner(partner);
  }, config);
};

export const useUpdateDealPartners = (config?: any) => {
  return useMutation(({ dealId, roleId, firmIds }: { dealId: number; roleId: number; firmIds: number[] }) => {
    return mutations.updateDealPartners(dealId, roleId, firmIds);
  }, config);
};

export const useRemoveDealPartner = (config?: any) => {
  return useMutation((partner: RemoveDealPartnerInput) => {
    return mutations.removeDealPartner(partner);
  }, config);
};

export const useCreateTeamRole = (config?: any) => {
  return useMutation(
    (role: CreateTeamMemberRoleInput) => {
      return mutations.createTeamRole(role);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['teamMemberRoles']);
      },
      ...config,
    }
  );
};

export const useUpdateTeamRole = (config?: any) => {
  return useMutation(
    (role: UpdateTeamMemberRoleInput) => {
      return mutations.updateTeamRole(role);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['teamMemberRoles']);
      },
      ...config,
    }
  );
};

export const useDeleteTeamRole = (config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.deleteTeamRole(id);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['teamMemberRoles']);
      },
      ...config,
    }
  );
};

export const useCreatePartnerRole = (config?: any) => {
  return useMutation(
    (role: CreatePartnerRoleInput) => {
      return mutations.createPartnerRole(role);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['partnerRoles']);
      },
      ...config,
    }
  );
};

export const useUpdatePartnerRole = (config?: any) => {
  return useMutation(
    (role: UpdatePartnerRoleInput) => {
      return mutations.updatePartnerRole(role);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['partnerRoles']);
      },
      ...config,
    }
  );
};

export const useDeletePartnerRole = (config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.deletePartnerRole(id);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['partnerRoles']);
      },
      ...config,
    }
  );
};

export const useCreateGCEstimate = (config?: any) => {
  return useMutation(
    (estimate) => mutations.createGCEstimate(estimate),
    config
  );
};

export const useCreateTask = (config?: any) => {
  return useMutation((task) => mutations.createTask(task), config);
};

export const useEditTask = (config?: any) => {
  return useMutation((task) => mutations.editTask(task), config);
};

export const useCreateKeyRisk = (dealId: number, config?: any) => {
  return useMutation(
    (keyRisk: CreateKeyRiskInput) => mutations.createKeyRisk(keyRisk, dealId),
    config
  );
};

export const useUpdateKeyRisk = (config?: any) => {
  return useMutation(
    (keyRisk: UpdateKeyRiskInput) => mutations.updateKeyRisk(keyRisk),
    config
  );
};

export const useUpdateDealKeyRisk = (
  dealId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateKeyRiskInput) =>
      mutations.updateKeyRisk(input),
    {
      onMutate: (variables) => {
        const snapshot = queryCache.getQueryData(['deal', dealId]);
        queryCache.setQueryData(
          ['deal', dealId],
          (old?: GraphQLResult<GetDeal>) => {
            if (old && old.data?.deal) {
              const updated = { ...old };
              const oldKeyRisks = old.data.deal.keyRisks
                ? [...old.data.deal.keyRisks]
                : [];
              const index = oldKeyRisks.findIndex((x) => x.id == variables.id);
              if (index != -1) {
                oldKeyRisks[index].orderId = variables.orderId;
              }
              if (updated && updated.data?.deal) {
                updated.data.deal.keyRisks = oldKeyRisks;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return snapshot;
      },
      onError: (err, data, rollback: any) => rollback(),
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['deal', dealId]);
      },
      ...config,
    }
  );
};

export const useDeleteKeyRisk = (config?: any) => {
  return useMutation((id: number) => {
    return mutations.deleteKeyRisk(id);
  }, config);
};

export const useCreateDealMerit = (dealId: number, config?: any) => {
  return useMutation(
    (dealMerit: CreateDealMeritInput) => mutations.createDealMerit(dealMerit, dealId),
    config
  );
};

export const useUpdateDealMerit = (config?: any) => {
  return useMutation(
    (dealMerit: UpdateDealMeritInput) => mutations.updateDealMerit(dealMerit),
    config
  );
};

export const useUpdateDealDealMerit = (
  dealId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateDealMeritInput) =>
      mutations.updateDealMerit(input),
    {
      onMutate: (variables) => {
        const snapshot = queryCache.getQueryData(['deal', dealId]);
        queryCache.setQueryData(
          ['deal', dealId],
          (old?: GraphQLResult<GetDeal>) => {
            if (old && old.data?.deal) {
              const updated = { ...old };
              const oldDealMerits = old.data.deal.dealMerits
                ? [...old.data.deal.dealMerits]
                : [];
              const index = oldDealMerits.findIndex((x) => x.id == variables.id);
              if (index != -1) {
                oldDealMerits[index].orderId = variables.orderId;
              }
              if (updated && updated.data?.deal) {
                updated.data.deal.dealMerits = oldDealMerits;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return snapshot;
      },
      onError: (err, data, rollback: any) => rollback(),
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['deal', dealId]);
      },
      ...config,
    }
  );
};

export const useDeleteDealMerit = (config?: any) => {
  return useMutation((id: number) => {
    return mutations.deleteDealMerit(id);
  }, config);
};


export const useAddEventsToDeal = (config?: any) => {
  return useMutation(
    ({ dealId, eventIds }: { dealId: number; eventIds: number[] }) =>
      mutations.addEventsToDeal(dealId, eventIds),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealEvents', variables.dealId]);
      },
      ...config,
    }
  );
};

export const useRemoveEventsFromDeal = (config?: any) => {
  return useMutation(
    ({ dealId, eventIds }: { dealId: number; eventIds: number[] }) =>
      mutations.removeEventsFromDeal(dealId, eventIds),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealEvents', variables.dealId]);
      },
      ...config,
    }
  );
};



export const useCreateComment = (config?: any) => {
  return useMutation(
    (inputs: CreateCommentVariables) =>
      mutations.createComment(inputs.comment, inputs.subjectType),
    config
  );
};

export const useUpdateComment = (config?: any) => {
  return useMutation(
    (inputs: UpdateCommentVariables) => mutations.updateComment(inputs.comment),
    config
  );
};

export const useDeleteComment = (config?: any) => {
  return useMutation(
    (inputs: DeleteCommentVariables) =>
      mutations.deleteComment(inputs.id, inputs.subjectType),
    config
  );
};

export const useEditGCEstimate = (config?: any) => {
  return useMutation((building) => mutations.editGCEstimate(building), config);
};

export const useCreateFirm = (config?: any) => {
  return useMutation(
    (firm: CreateFirmInput) => mutations.createFirm(firm),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['firms']);
        queryCache.refetchQueries(['firmsList']);
      },
      ...config,
    }
  );
};

export const useUpdateFirm = (config?: any) => {
  return useMutation(
    (firm: UpdateFirmInput) => mutations.updateFirm(firm),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['firms', variables.id]);
        queryCache.refetchQueries(['firmsList']);
      },
      ...config,
    }
  );
};

export const useDeleteFirm = (config?: any) => {
  return useMutation((id: number) => mutations.deleteFirm(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['firms']);
      queryCache.refetchQueries(['firmsList']);
    },
    ...config,
  });
};

export const useCreateFirmRegion = (config?: any) => {
  return useMutation(
    (firm: CreateFirmRegionInput) => mutations.createFirmRegion(firm),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['firms', variables.fkFirm]);
        queryCache.refetchQueries(['firmsList']);
      },
      ...config,
    }
  );
};

export const useUpdateFirmRegion = (config?: any) => {
  return useMutation(
    (firm: UpdateFirmRegionInput) => mutations.updateFirmRegion(firm),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['firms', variables.fkFirm]);
        queryCache.refetchQueries(['firmsList']);
      },
      ...config,
    }
  );
};

export const useDeleteFirmRegion = (config?: any) => {
  return useMutation(({ fkRegion, fkFirm }: { fkRegion: number; fkFirm: number }) => mutations.deleteFirmRegion(fkRegion, fkFirm), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['firms', variables.fkFirm]);
      queryCache.refetchQueries(['firmsList']);
    },
    ...config,
  });
};

export const useCreateInteraction = (firmId?: number, contactId?: number, config?: any) => {

  return useMutation(
    (interaction: CreateInteractionInput) => mutations.createInteraction(interaction),
    {
      onSuccess: (data, variables) => {
        if (firmId) {
          queryCache.refetchQueries(['firms', firmId]);
        }
        if (contactId) {
          queryCache.refetchQueries(['contact', contactId]);
        }
      },
      ...config,
    }
  );
};

export const useUpdateInteraction = (firmId?: number, contactId?: number, config?: any) => {
  return useMutation(
    (interaction: UpdateInteractionInput) => mutations.updateInteraction(interaction),
    {
      onSuccess: (data, variables) => {
        if (firmId) {
          queryCache.refetchQueries(['firms', firmId]);
        }
        if (contactId) {
          queryCache.refetchQueries(['contact', contactId]);
        }
      },
      ...config,
    }
  );
};

export const useDeleteInteraction = (firmId?: number, contactId?: number, oppId?: number, config?: any) => {
  return useMutation(
    (id: number) => mutations.deleteInteraction(id),
    {
      onSuccess: (data, variables) => {
        if (firmId) {
          queryCache.refetchQueries(['firms', firmId]);
        }
        if (contactId) {
          queryCache.refetchQueries(['contact', contactId]);
        }
        if (oppId) {
          queryCache.refetchQueries(['deal', oppId]);
        }
      },
      ...config,
    }
  );
};

export const useCreateStatusUpdate = (config?: any) => {
  return useMutation(
    (statusUpdate: CreateStatusUpdateInput) =>
      mutations.createStatusUpdate(statusUpdate),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealStatusUpdates', variables.fkDeal]);
        queryCache.refetchQueries(['deal', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useUpdateStatusUpdate = (config?: any) => {
  return useMutation(
    (statusUpdate: UpdateStatusUpdateInput) =>
      mutations.updateStatusUpdate(statusUpdate),
    {
      onSuccess: (data, variables) => {
        if (data.data?.updateStatusUpdate) {
          // queryCache.setQueryData(
          //   ['dealStatusUpdates', data.data?.updateStatusUpdate.fkDeal],
          //   (old?: GraphQLResult<GetDealStatusUpdates>) => {
          //     if (old && old.data?.deal) {
          //       const updated = { ...old };
          //       const oldStatusUpdates = old.data.deal.statusUpdates
          //         ? [...old.data.deal.statusUpdates]
          //         : [];
          //       const index = oldStatusUpdates.findIndex(
          //         (x) => x.id == variables.id
          //       );
          //       if (index != -1) {
          //         oldStatusUpdates[index] = {
          //           ...oldStatusUpdates[index],
          //           ...data.data?.updateStatusUpdate,
          //         };
          //       }
          //       if (updated && updated.data?.deal)
          //         updated.data.deal.statusUpdates = oldStatusUpdates;
          //       return updated;
          //     } else {
          //       return old;
          //     }
          //   }
          // );
          queryCache.refetchQueries([
            'dealStatusUpdates',
            data.data.updateStatusUpdate.fkDeal,
          ]);
          queryCache.refetchQueries([
            'dealStatusUpdates',
            data.data.updateStatusUpdate.fkDeal,
            variables.id,
          ]);
          queryCache.refetchQueries([
            'deal',
            data.data.updateStatusUpdate.fkDeal,
          ]);
        }
      },
      ...config,
    }
  );
};

export const useDeleteStatusUpdate = (dealId: number, config?: any) => {
  return useMutation((id: number) => mutations.deleteStatusUpdate(id), {
    onMutate: (variables) => {
      queryCache.setQueryData(
        ['dealStatusUpdates', dealId],
        (old?: GraphQLResult<GetDealStatusUpdates>) => {
          if (old && old.data?.deal) {
            const updated = { ...old };
            const oldStatusUpdates = old.data.deal.statusUpdates
              ? [...old.data.deal.statusUpdates]
              : [];
            const index = oldStatusUpdates.findIndex((x) => x.id == variables);
            if (index != -1) {
              oldStatusUpdates.splice(index, 1);
            }
            if (updated && updated.data?.deal)
              updated.data.deal.statusUpdates = oldStatusUpdates;
            return updated;
          } else {
            return old;
          }
        }
      );
    },
    onError: (err, data, rollback: any) => rollback(),
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['dealStatusUpdates', dealId]);
      queryCache.refetchQueries(['dealStatusUpdates', dealId, variables]);
      queryCache.refetchQueries(['deal', dealId]);
    },
    ...config,
  });
};

export const useCreateStatusUpdateTemplate = (config?: any) => {
  return useMutation(
    (statusUpdateTemplate: CreateStatusUpdateTemplateInput) =>
      mutations.createStatusUpdateTemplate(statusUpdateTemplate),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealStatusUpdateTemplates']);
      },
      ...config,
    }
  );
};

export const useUpdateStatusUpdateTemplate = (config?: any) => {
  return useMutation(
    (statusUpdateTemplate: UpdateStatusUpdateTemplateInput) =>
      mutations.updateStatusUpdateTemplate(statusUpdateTemplate),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'dealStatusUpdateTemplates'
        ]);
      },
      ...config,
    }
  );
};

export const useDeleteStatusUpdateTemplate = (config?: any) => {
  return useMutation((id: number) => mutations.deleteStatusUpdateTemplate(id), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['dealStatusUpdateTemplates']);
    },
    ...config,
  });
};

export const useCreateChecklistCategory = (config?: any) => {
  return useMutation((checklistCategory: CreateChecklistCategoryInput) => {
    return mutations.createChecklistCategory(checklistCategory);
  }, config);
};

export const useCreatePipelineDealStage = (config?: any) => {
  return useMutation(
    ({ name, pipelineId }: { name: string; pipelineId: number }) =>
      mutations.createPipelineDealStage(name, pipelineId),
    config
  );
};

export const useUpdatePipelineDealStage = (config?: any) => {
  return useMutation(
    ({
      id,
      name,
      isDisabled,
      pipelineId,
    }: {
      id: number;
      name: string;
      isDisabled: boolean;
      pipelineId: number;
    }) => mutations.updatePipelineDealStage(id, name, isDisabled, pipelineId),
    config
  );
};

export const useReorderPipelineDealStage = (config?: any) => {
  return useMutation(
    ({
      id,
      beforeId,
      afterId,
      pipelineId,
    }: {
      id: number;
      beforeId?: number;
      afterId?: number;
      pipelineId: number;
    }) => mutations.reorderPipelineDealStage(id, pipelineId, beforeId, afterId),
    config
  );
};

export const useDeletePipelineDealStage = (config?: any) => {
  return useMutation(
    ({ id, pipelineId }: { id: number; pipelineId: number }) =>
      mutations.deletePipelineDealStage(id, pipelineId),
    config
  );
};

export const useCreateConfigurableEntity = (config?: any) => {
  return useMutation(
    ({ name, type }: { name: string; type: ConfigurableEntityEnum }) =>
      mutations.createConfigurableEntity(name, type),
    config
  );
};

export const useUpdateConfigurableEntity = (config?: any) => {
  return useMutation(
    ({
      id,
      name,
      isDisabled,
      type,
    }: {
      id: number;
      name: string;
      isDisabled: boolean;
      type: ConfigurableEntityEnum;
    }) => mutations.updateConfigurableEntity(id, name, isDisabled, type),
    config
  );
};

export const useDeleteConfigurableEntity = (config?: any) => {
  return useMutation(
    ({ id, type }: { id: number; type: ConfigurableEntityEnum }) =>
      mutations.deleteConfigurableEntity(id, type),
    config
  );
};

export const useAssignOrganizationMembers = (config?: any) => {
  return useMutation(
    (assignment: OrganizationMemberAssignmentInput) => {
      return mutations.assignMembersToOrganization(assignment);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['organizationUserAccess']);
      },
      ...config,
    }
  );
};

export const useRemoveOrganizationMembers = (config?: any) => {
  return useMutation(
    (assignment: RemoveOrganizationMemberInput) => {
      return mutations.removeMembersFromOrganization(assignment);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['organizationUserAccess']);
      },
      ...config,
    }
  );
};

export const useUpdateOrganizationMemberRole = (config?: any) => {
  return useMutation(
    (dealMemberRole: UpdateOrganizationMemberRoleInput) => {
      return mutations.updateOrganizationMemberRole(dealMemberRole);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['organizationUserAccess']);
      },
      ...config,
    }
  );
};

export const useAssignDealMembers = (config?: any) => {
  return useMutation(
    (assignment: DealMemberAssignmentInput) => {
      return mutations.assignMembersToDeal(assignment);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['deal', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useRemoveDealMembers = (config?: any) => {
  return useMutation(
    (assignment: RemoveDealMemberInput) => {
      return mutations.removeMembersFromDeal(assignment);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['deal', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useUpdateDealMemberRole = (config?: any) => {
  return useMutation(
    (dealMemberRole: UpdateDealMemberRoleInput) => {
      return mutations.updateDealMemberRole(dealMemberRole);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['deal', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useAssignPipelineMembers = (config?: any) => {
  return useMutation(
    (assignment: PipelineMemberAssignmentInput) => {
      return mutations.assignMembersToPipeline(assignment);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['pipeline', variables.fkPipeline]);
      },
      ...config,
    }
  );
};

export const useRemovePipelineMembers = (config?: any) => {
  return useMutation(
    (assignment: RemovePipelineMemberInput) => {
      return mutations.removeMembersFromPipeline(assignment);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['pipeline', variables.fkPipeline]);
      },
      ...config,
    }
  );
};

export const useUpdatePipelineMemberRole = (config?: any) => {
  return useMutation(
    (pipelineMemberRole: UpdatePipelineMemberRoleInput) => {
      return mutations.updatePipelineMemberRole(pipelineMemberRole);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['pipeline', variables.fkPipeline]);
      },
      ...config,
    }
  );
};

export const useAssignPortfolioMembers = (config?: any) => {
  return useMutation(
    (assignment: PortfolioMemberAssignmentInput) => {
      return mutations.assignMembersToPortfolio(assignment);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['portfolio', variables.fkPortfolio]);
      },
      ...config,
    }
  );
};

export const useRemovePortfolioMembers = (config?: any) => {
  return useMutation(
    (assignment: RemovePortfolioMemberInput) => {
      return mutations.removeMembersFromPortfolio(assignment);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['portfolio', variables.fkPortfolio]);
      },
      ...config,
    }
  );
};

export const useUpdatePortfolioMemberRole = (config?: any) => {
  return useMutation(
    (portfolioMemberRole: UpdatePortfolioMemberRoleInput) => {
      return mutations.updatePortfolioMemberRole(portfolioMemberRole);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['portfolio', variables.fkPortfolio]);
      },
      ...config,
    }
  );
};

export const useUpdatePortfolioViewColumn = (
  portfolioId: number,
  viewId: number,
  config?: any
) => {
  return useMutation(
    (column: UpdatePortfolioViewColumnInput) => {
      return mutations.updatePortfolioViewColumn(column);
    },
    {
      onMutate: (variables) => {
        const previous = queryCache.getQueryData([
          'portfolioView',
          portfolioId,
          viewId,
        ]);
        queryCache.setQueryData(
          ['portfolioView', portfolioId, viewId],
          (old?: GraphQLResult<GetPortfolioView>) => {
            if (old && old.data?.portfolio?.pinnedView) {
              const updated = { ...old };
              const oldColumns = old.data.portfolio.pinnedView.columns
                ? [...old.data.portfolio.pinnedView.columns]
                : [];
              const index = oldColumns.findIndex(
                (x) => x.id == variables.id && x.type == variables.type
              );
              if (index != -1) {
                oldColumns[index].orderId = variables.orderId;
              }
              if (updated.data?.portfolio?.pinnedView) {
                updated.data.portfolio.pinnedView.columns = oldColumns;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return () =>
          queryCache.setQueryData(
            ['portfolioView', portfolioId, viewId],
            previous
          );
      },
      onSuccess: () => {
        queryCache.refetchQueries(['portfolioView', portfolioId, viewId]);
      },
      onSettled: (data, error, variables, rollback) => {
        if (data?.errors || error) {
          rollback();
        }
      },
      ...config,
    }
  );
};

export const useAddPortfolioViewColumn = (
  portfolioId: number,
  viewId: number,
  config?: any
) => {
  return useMutation(
    (column: AddPortfolioViewColumnInput) => {
      return mutations.addPortfolioViewColumn(column);
    },
    {
      // onMutate: (variables) => {
      //   queryCache.setQueryData(
      //     ['portfolioView', portfolioId, viewId],
      //     (old?: GraphQLResult<GetPortfolioView>) => {
      //       if (old && old.data?.portfolio?.pinnedView) {
      //         const updated = { ...old };
      //         const oldColumns = old.data?.portfolio.pinnedView.columns
      //           ? [...old.data?.portfolio.pinnedView.columns]
      //           : [];
      //         const lastColumn = oldColumns[oldColumns.length - 1];
      //         const maxOrderId = lastColumn ? lastColumn.orderId : 0;
      //         const newColumn = {
      //           id: variables.id,
      //           name: variables.name,
      //           lookupName: variables.lookupName.replace(/\./g, '_'),
      //           type: variables.type,
      //           orderId: maxOrderId + 10000,
      //         };
      //         if (updated.data?.portfolio?.pinnedView) {
      //           updated.data.portfolio.pinnedView.columns = [
      //             ...oldColumns,
      //             newColumn,
      //           ];
      //         }
      //         return updated;
      //       } else {
      //         return old;
      //       }
      //     }
      //   );
      // },
      onSuccess: () => {
        queryCache.refetchQueries(['portfolioView', portfolioId, viewId]);
      },
      ...config,
    }
  );
};

export const useRemovePortfolioViewColumns = (
  portfolioId: number,
  viewId: number,
  config?: any
) => {
  return useMutation(
    (columns: AddPortfolioViewColumnInput[]) => {
      return mutations.removePortfolioViewColumns(viewId, columns);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['portfolioView', portfolioId, viewId]);
      },
      ...config,
    }
  );
};

export const useAddPortfolioDeals = (viewId?: number, config?: any) => {
  return useMutation(
    (portfolioDeals: UpdatePortfolioDealsInput) => {
      return mutations.addPortfolioDeals(portfolioDeals);
    },
    {
      onSuccess: (data, variables) => {
        if (viewId) {
          queryCache.refetchQueries([
            'portfolioView',
            variables.fkPortfolio,
            viewId,
          ]);
        }
        queryCache.refetchQueries(['portfolioDeals', variables.fkPortfolio]);
      },
      ...config,
    }
  );
};

export const useRemovePortfolioDeals = (viewId?: number, config?: any) => {
  return useMutation(
    (portfolioDeals: UpdatePortfolioDealsInput) => {
      return mutations.removePortfolioDeals(portfolioDeals);
    },
    {
      onSuccess: (data, variables) => {
        if (viewId) {
          queryCache.refetchQueries([
            'portfolioView',
            variables.fkPortfolio,
            viewId,
          ]);
        }
        queryCache.refetchQueries(['portfolioDeals', variables.fkPortfolio]);
      },
      ...config,
    }
  );
};

export const useCreateEvent = (config?: any) => {
  return useMutation(
    (event: CreateEventInput) => {
      return mutations.createEvent(event);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['events']);
      },
      ...config,
    }
  );
};

export const useUpdateEvent = (config?: any) => {
  return useMutation(
    (event: UpdateEventInput) => {
      return mutations.updateEvent(event);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['events']);
      },
      ...config,
    }
  );
};

export const useDeleteEvent = (config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.deleteEvent(id);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['events']);
      },
      ...config,
    }
  );
};

export const useCreateDealEvent = (config?: any) => {
  return useMutation(
    (event: CreateDealEventInput) => {
      return mutations.createDealEvent(event);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealEvents', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useUpdateDealEvent = (config?: any) => {
  return useMutation(
    ({ event, type }: { event: UpdateDealEventInput; type: DealEventTypeEnum }) => {
      return mutations.updateDealEvent(event);
    },
    {
      onMutate: (variables) => {
        const snapshot = queryCache.getQueryData([
          'dealEvents',
          variables.event.fkDeal
        ]);
        queryCache.setQueryData(
          ['dealEvents', variables.event.fkDeal],
          (old?: GraphQLResult<GetDealSchedule>) => {
            if (old && old.data?.deal?.events) {
              const updated = { ...old };
              const oldEvents = old.data.deal.events
                ? [...old.data.deal.events]
                : [];
              const index = oldEvents.findIndex((x) => x.id == variables.event.fkEvent);
              if (index != -1) {
                if (variables.event.isComplete !== undefined && variables.event.isComplete !== null) {
                  oldEvents[index].isComplete = variables.event.isComplete;
                }
                if (variables.event.startDate) {
                  oldEvents[index].startDate = variables.event.startDate;
                  if (variables.type == DealEventTypeEnum.Milestone) {
                    oldEvents[index].endDate = variables.event.startDate;
                  }
                }
                if (variables.event.endDate) {
                  oldEvents[index].endDate = variables.event.endDate;
                  if (variables.type == DealEventTypeEnum.Milestone) {
                    oldEvents[index].startDate = variables.event.startDate;
                  }
                }
              }
              if (updated && updated.data?.deal?.events) {
                updated.data.deal.events = oldEvents;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return snapshot;
      },
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealEvents', variables.event.fkDeal]);
      },
      ...config,
    }
  );
};

export const useCreateAnalysis = (config?: any) => {
  return useMutation(
    (analysis: CreateAnalysisInput) => {
      return mutations.createAnalysis(analysis);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealAnalyses', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useUpdateAnalysis = (dealId: number, config?: any) => {
  return useMutation(
    (analysis: UpdateAnalysisInput) => {
      return mutations.updateAnalysis(analysis);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealAnalysis', dealId, variables.id]);
      },
      ...config,
    }
  );
};

export const useDeleteAnalysis = (dealId: number, config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.deleteAnalysis(id);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['dealAnalysis', dealId]);
      },
      ...config,
    }
  );
};

export const useCreateAnalysisType = (config?: any) => {
  return useMutation(
    (input: CreateAnalysisTypeInput) => mutations.createAnalysisType(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['metrics']);
      },
      ...config,
    }
  );
};

export const useUpdateAnalysisType = (config?: any) => {
  return useMutation(
    (input: UpdateAnalysisTypeInput) => mutations.updateAnalysisType(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['metrics']);
      },
      ...config,
    }
  );
};

export const useDeleteAnalysisType = (config?: any) => {
  return useMutation((id: number) => mutations.deleteAnalysisType(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['metrics']);
    },
    ...config,
  });
};

export const useCreateAnalysisMetric = (dealId: number, config?: any) => {
  return useMutation(
    (metric: CreateAnalysisMetricInput) => {
      return mutations.createAnalysisMetric(metric);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'dealAnalysis',
          dealId,
          variables.fkAnalysis,
        ]);
      },
      ...config,
    }
  );
};

export const useUpdateAnalysisMetric = (dealId: number, config?: any) => {
  return useMutation(
    (metric: UpdateAnalysisMetricInput) => {
      return mutations.updateAnalysisMetric(metric);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'dealAnalysis',
          dealId,
          variables.fkAnalysis,
        ]);
      },
      ...config,
    }
  );
};

export const useDeleteAnalysisMetric = (
  dealId: number,
  analysisId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => {
      return mutations.deleteAnalysisMetric(id);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealAnalysis', dealId, analysisId]);
      },
      ...config,
    }
  );
};

export const useCreateAnalysisTextMetric = (dealId: number, config?: any) => {
  return useMutation(
    (textMetric: CreateAnalysisTextMetricInput) => {
      return mutations.createAnalysisTextMetric(textMetric);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'dealAnalysis',
          dealId,
          variables.fkAnalysis,
        ]);
      },
      ...config,
    }
  );
};

export const useUpdateAnalysisTextMetric = (dealId: number, config?: any) => {
  return useMutation(
    (textMetric: UpdateAnalysisTextMetricInput) => {
      return mutations.updateAnalysisTextMetric(textMetric);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'dealAnalysis',
          dealId,
          variables.fkAnalysis,
        ]);
      },
      ...config,
    }
  );
};

export const useDeleteAnalysisTextMetric = (
  dealId: number,
  analysisId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => {
      return mutations.deleteAnalysisTextMetric(id);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealAnalysis', dealId, analysisId]);
      },
      ...config,
    }
  );
};

export const useCreateAnalysisEvent = (dealId: number, config?: any) => {
  return useMutation(
    (event: CreateAnalysisEventInput) => {
      return mutations.createAnalysisEvent(event);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'dealAnalysis',
          dealId,
          variables.fkAnalysis,
        ]);
      },
      ...config,
    }
  );
};

export const useUpdateAnalysisEvent = (dealId: number, config?: any) => {
  return useMutation(
    (event: UpdateAnalysisEventInput) => {
      return mutations.updateAnalysisEvent(event);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'dealAnalysis',
          dealId,
          variables.fkAnalysis,
        ]);
      },
      ...config,
    }
  );
};

export const useDeleteAnalysisEvent = (
  dealId: number,
  analysisId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => {
      return mutations.deleteAnalysisEvent(id);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealAnalysis', dealId, analysisId]);
      },
      ...config,
    }
  );
};

export const useDuplicateAnalysis = (dealId: number, config?: any) => {
  return useMutation(
    (analysisId: number) => {
      return mutations.duplicateAnalysis(analysisId);
    },
    {
      onSuccess: (data) => {
        if (data.data?.duplicateAnalysis) {
          queryCache.refetchQueries([
            'dealAnalysis',
            dealId,
            data.data.duplicateAnalysis.id,
          ]);
        }
      },
      ...config,
    }
  );
};

export const useArchiveAnalysis = (dealId: number, config?: any) => {
  return useMutation(
    (analysisId: number) => {
      return mutations.archiveAnalysis(analysisId);
    },
    {
      onSuccess: (data, analysisId) => {
        queryCache.refetchQueries(['dealAnalysis', dealId, analysisId]);
      },
      ...config,
    }
  );
};

export const useCreateContract = (config?: any) => {
  return useMutation(
    (contract: CreateContractInput) => {
      return mutations.createContract(contract);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealContracts', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useUpdateContract = (dealId: number, config?: any) => {
  return useMutation(
    (contract: UpdateContractInput) => {
      return mutations.updateContract(contract);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['dealContracts', dealId]);
      },
      ...config,
    }
  );
};

export const useDeleteContract = (dealId: number, config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.deleteContract(id);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['dealContracts', dealId]);
      },
      ...config,
    }
  );
};

export const usePublishCapitalForecast = (config?: any) => {
  return useMutation(
    (capitalForecast: CreatePublishedCapitalForecastInput) => {
      return mutations.publishCapitalForecast(capitalForecast);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'dealPublishedCapitalForecasts',
          variables.fkDeal,
        ]);
      },
      ...config,
    }
  );
};

export const useDeletePublishedCapitalForecast = (
  oppId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => {
      return mutations.deletePublishedCapitalForecast(id);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealPublishedCapitalForecasts', oppId]);
      },
      ...config,
    }
  );
};

export const useCreateBudgetRevision = (config?: any) => {
  return useMutation(
    (revision: CreateRevisionInput) => mutations.createBudgetRevision(revision),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealRevisions', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useUpdateBudgetRevision = (dealId: number, config?: any) => {
  return useMutation(
    (revision: UpdateRevisionInput) => mutations.updateBudgetRevision(revision),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['dealRevisions', dealId]);
      },
      ...config,
    }
  );
};

export const useDeleteBudgetRevision = (dealId: number, config?: any) => {
  return useMutation((id: number) => mutations.deleteBudgetRevision(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['dealRevisions', dealId]);
    },
    ...config,
  });
};

export const useCreateBudgetRevisionCostAccount = (
  dealId: number,
  config?: any
) => {
  return useMutation(
    (revisionCostAccount: CreateRevisionAccountInput) =>
      mutations.createBudgetRevisionCostAccount(revisionCostAccount),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'dealRevisions',
          dealId,
          variables.fkRevision,
        ]);
        queryCache.refetchQueries(['dealRevisions', dealId]);
      },
      ...config,
    }
  );
};

export const useUpdateBudgetRevisionCostAccount = (
  dealId: number,
  revisionId: number,
  config?: any
) => {
  return useMutation(
    (revisionCostAccount: UpdateRevisionAccountInput) =>
      mutations.updateBudgetRevisionCostAccount(revisionCostAccount),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['dealRevisions', dealId, revisionId]);
        queryCache.refetchQueries(['dealRevisions', dealId]);
      },
      ...config,
    }
  );
};

export const useDeleteBudgetRevisionCostAccounts = (
  dealId: number,
  revisionId: number,
  config?: any
) => {
  return useMutation(
    (ids: number[]) =>
      mutations.deleteBudgetRevisionCostAccounts(revisionId, ids),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['dealRevisions', dealId, revisionId]);
        queryCache.refetchQueries(['dealRevisions', dealId]);
      },
      ...config,
    }
  );
};

export const useCreatePortfolioView = (portfolioId: number, config?: any) => {
  return useMutation(
    (portfolioView: CreatePortfolioViewInput) =>
      mutations.createPortfolioView(portfolioView),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['portfolioViews', portfolioId]);
      },
      ...config,
    }
  );
};

export const useUpdatePortfolioView = (portfolioId: number, config?: any) => {
  return useMutation(
    (portfolioView: UpdatePortfolioViewInput) =>
      mutations.updatePortfolioView(portfolioView),
    {
      onMutate: (variables) => {
        const snapshot = queryCache.getQueryData([
          'portfolioView',
          portfolioId,
          variables.id,
        ]);
        queryCache.setQueryData(
          ['portfolioView', portfolioId, variables.id],
          (old?: GraphQLResult<GetPortfolioView>) => {
            if (old && old.data?.portfolio?.pinnedView) {
              const updated = { ...old };
              if (
                updated &&
                updated?.data?.portfolio?.pinnedView &&
                (variables.showArchivedDeals != undefined ||
                  variables.showArchivedDeals != null)
              ) {
                updated.data.portfolio.pinnedView.showArchivedDeals =
                  variables.showArchivedDeals;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return snapshot;
      },
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['portfolioViews', portfolioId]);
        queryCache.refetchQueries(['portfolioView', portfolioId, variables.id]);
      },
      ...config,
    }
  );
};

export const useDeletePortfolioView = (portfolioId: number, config?: any) => {
  return useMutation((id: number) => mutations.deletePortfolioView(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['portfolioViews', portfolioId]);
    },
    ...config,
  });
};

export const useGenerateTransactionDetailPreview = (config?: any) => {
  return useMutation(
    (input: CreateTransactionInput) =>
      mutations.generateTransactionDetailPreview(input),
    config
  );
};

export type CreateTransactionParams = {
  transaction: CreateTransactionInput;
  details: CreateTransactionDetailInput[];
};

export const useCreateTransaction = (fkGlAccountTree: number, config?: any) => {
  return useMutation(
    ({ transaction, details }: CreateTransactionParams) =>
      mutations.createTransaction(transaction, details),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'dealTransactions',
          variables.transaction.fkDeal,
        ]);
        queryCache.refetchQueries([
          'dealMasterBudgetOverview', variables.transaction.fkDeal, fkGlAccountTree
        ]);

        queryCache.refetchQueries([
          'dealTransactions',
          variables.transaction.fkDeal,
          data.data?.createTransaction?.id,
        ]);
      },
      ...config,
    }
  );
};

type UpdateTransactionParams = {
  transaction: UpdateTransactionInput;
  details: CreateTransactionDetailInput[];
};

export const useUpdateTransaction = (oppId: number, fkGlAccountTree: number, config?: any) => {
  return useMutation(
    ({ transaction, details }: UpdateTransactionParams) =>
      mutations.updateTransaction(transaction, details),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealTransactions', oppId]);
        queryCache.refetchQueries([
          'dealTransactions',
          oppId,
          variables.transaction.id,
        ]);
        queryCache.refetchQueries([
          'dealMasterBudgetOverview', oppId, fkGlAccountTree
        ]);
      },
      ...config,
    }
  );
};

export const useDeleteTransaction = (oppId: number, fkGlAccountTree: number, config?: any) => {
  return useMutation((id: number) => mutations.deleteTransaction(id), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['dealTransactions', oppId]);
      queryCache.refetchQueries([
        'dealMasterBudgetOverview', oppId, fkGlAccountTree
      ]);
    },
    ...config,
  });
};

export const useDeletePortfolio = (config?: any) => {
  return useMutation((id: number) => mutations.deletePortfolio(id), config);
};

export const useDeleteDeal = (config?: any) => {
  return useMutation((id: number) => mutations.deleteDeal(id), config);
};

export const useArchiveDeal = (config?: any) => {
  return useMutation(
    ({
      id,
      status,
      reason,
    }: {
      id: number;
      status: DealArchiveStatusEnum;
      reason?: string;
    }) => mutations.archiveDeal(id, status, reason),
    {
      onMutate: (variables) => {
        const snapshot = queryCache.getQueryData([
          'deal',
          variables.id,
        ]);
        queryCache.setQueryData(
          ['deal', variables.id],
          (old?: GraphQLResult<GetDeal>) => {
            if (old && old.data?.deal) {
              const updated = { ...old };
              if (
                updated &&
                updated?.data?.deal
              ) {
                updated.data.deal.archiveDate = new Date().toISOString();
                updated.data.deal.archiveStatus = variables.status;
                updated.data.deal.archiveReason = variables.reason;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return snapshot;
      },
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['deal', variables.id]);
      },
      ...config,
    }
  );
};

export const useUnarchiveDeal = (config?: any) => {
  return useMutation(
    ({
      id,
    }: {
      id: number;
    }) => mutations.unarchiveDeal(id),
    {
      onMutate: (variables) => {
        const snapshot = queryCache.getQueryData([
          'deal',
          variables.id,
        ]);
        queryCache.setQueryData(
          ['deal', variables.id],
          (old?: GraphQLResult<GetDeal>) => {
            if (old && old.data?.deal) {
              const updated = { ...old };
              if (
                updated &&
                updated?.data?.deal
              ) {
                updated.data.deal.archiveDate = null;
                updated.data.deal.archiveStatus = null;
                updated.data.deal.archiveReason = null;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return snapshot;
      },
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['deal', variables.id]);
      },
      ...config,
    }
  );
};

export const useCreateGroup = (config?: any) => {
  return useMutation(
    (input: CreateGroupInput) => mutations.createGroup(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['organizationGroups']);
      },
      ...config,
    }
  );
};

export const useUpdateGroup = (config?: any) => {
  return useMutation(
    (input: UpdateGroupInput) => mutations.updateGroup(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['organizationGroups']);
        queryCache.refetchQueries(['organizationGroups', variables.id]);
      },
      ...config,
    }
  );
};

export const useDeleteGroup = (config?: any) => {
  return useMutation((id: number) => mutations.deleteGroup(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['organizationGroups']);
    },
    ...config,
  });
};

export const useAddUsersToGroup = (config?: any) => {
  return useMutation(
    ({ groupId, userIds }: { groupId: number; userIds: number[] }) =>
      mutations.addUsersToGroup(groupId, userIds),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['organizationGroups', variables.groupId]);
      },
      ...config,
    }
  );
};

export const useRemoveUsersFromGroup = (config?: any) => {
  return useMutation(
    ({ groupId, userIds }: { groupId: number; userIds: number[] }) =>
      mutations.removeUsersFromGroup(groupId, userIds),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['organizationGroups', variables.groupId]);
      },
      ...config,
    }
  );
};

export const useCreateMetric = (config?: any) => {
  return useMutation(
    (input: CreateMetricInput) => mutations.createMetric(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['metrics']);
      },
      ...config,
    }
  );
};

export const useUpdateMetric = (config?: any) => {
  return useMutation(
    (input: UpdateMetricInput) => mutations.updateMetric(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['metrics']);
      },
      ...config,
    }
  );
};

export const useDeleteMetric = (config?: any) => {
  return useMutation((id: number) => mutations.deleteMetric(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['metrics']);
    },
    ...config,
  });
};

export const useCreateTextMetric = (config?: any) => {
  return useMutation(
    (input: CreateTextMetricInput) => mutations.createTextMetric(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['textMetrics']);
      },
      ...config,
    }
  );
};

export const useUpdateTextMetric = (config?: any) => {
  return useMutation(
    (input: UpdateTextMetricInput) => mutations.updateTextMetric(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['textMetrics']);
      },
      ...config,
    }
  );
};

export const useDeleteTextMetric = (config?: any) => {
  return useMutation((id: number) => mutations.deleteTextMetric(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['textMetrics']);
    },
    ...config,
  });
};

export const useCreatePipeline = (config?: any) => {
  return useMutation(
    (input: CreatePipelineInput) => mutations.createPipeline(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['pipelines']);
      },
      ...config,
    }
  );
};

export const useUpdatePipeline = (config?: any) => {
  return useMutation(
    (input: UpdatePipelineInput) => mutations.updatePipeline(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['pipelines']);
        queryCache.refetchQueries(['pipelines', variables.id]);
      },
      ...config,
    }
  );
};

export const useDeletePipeline = (config?: any) => {
  return useMutation((id: number) => mutations.deletePipeline(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['pipelines']);
    },
    ...config,
  });
};

export const useAddChecklistsToPipeline = (config?: any) => {
  return useMutation(
    ({ id, checklistIds }: { id: number; checklistIds: number[] }) =>
      mutations.addChecklistsToPipeline(id, checklistIds),
    config
  );
};

export const useRemoveChecklistsFromPipeline = (config?: any) => {
  return useMutation(
    ({ id, checklistIds }: { id: number; checklistIds: number[] }) =>
      mutations.removeChecklistsFromPipeline(id, checklistIds),
    config
  );
};

export const useAddPinnedMetricsToPipeline = (config?: any) => {
  return useMutation(
    ({ id, metricIds }: { id: number; metricIds: number[] }) =>
      mutations.addPinnedMetricsToPipeline(id, metricIds),
    config
  );
};

export const useRemovePinnedMetricsFromPipeline = (config?: any) => {
  return useMutation(
    ({ id, metricIds }: { id: number; metricIds: number[] }) =>
      mutations.removePinnedMetricsFromPipeline(id, metricIds),
    config
  );
};

export const useAddPinnedEventsToPipeline = (config?: any) => {
  return useMutation(
    ({ id, eventIds }: { id: number; eventIds: number[] }) =>
      mutations.addPinnedEventsToPipeline(id, eventIds),
    config
  );
};

export const useRemovePinnedEventsFromPipeline = (config?: any) => {
  return useMutation(
    ({ id, eventIds }: { id: number; eventIds: number[] }) =>
      mutations.removePinnedEventsFromPipeline(id, eventIds),
    config
  );
};

export const useAddPinnedPartnerRolesToPipeline = (config?: any) => {
  return useMutation(
    ({ id, roleIds }: { id: number; roleIds: number[] }) =>
      mutations.addPinnedPartnerRolesToPipeline(id, roleIds),
    config
  );
};

export const useRemovePinnedPartnerRolesFromPipeline = (config?: any) => {
  return useMutation(
    ({ id, roleIds }: { id: number; roleIds: number[] }) =>
      mutations.removePinnedPartnerRolesFromPipeline(id, roleIds),
    config
  );
};

export const useAddPinnedTeamMemberRolesToPipeline = (config?: any) => {
  return useMutation(
    ({ id, roleIds }: { id: number; roleIds: number[] }) =>
      mutations.addPinnedTeamMemberRolesToPipeline(id, roleIds),
    config
  );
};

export const useRemovePinnedTeamMemberRolesFromPipeline = (config?: any) => {
  return useMutation(
    ({ id, roleIds }: { id: number; roleIds: number[] }) =>
      mutations.removePinnedTeamMemberRolesFromPipeline(id, roleIds),
    config
  );
};

export const useAddEventsToPipeline = (config?: any) => {
  return useMutation(
    ({ id, eventIds }: { id: number; eventIds: number[] }) =>
      mutations.addEventsToPipeline(id, eventIds),
    config
  );
};

export const useRemoveEventsFromPipeline = (config?: any) => {
  return useMutation(
    ({ id, eventIds }: { id: number; eventIds: number[] }) =>
      mutations.removeEventsFromPipeline(id, eventIds),
    config
  );
};

export const useAddModulesToPipeline = (config?: any) => {
  return useMutation(
    ({ id, moduleIds }: { id: number; moduleIds: number[] }) =>
      mutations.addModulesToPipeline(id, moduleIds),
    config
  );
};

export const useRemoveModulesFromPipeline = (config?: any) => {
  return useMutation(
    ({ id, moduleIds }: { id: number; moduleIds: number[] }) =>
      mutations.removeModulesFromPipeline(id, moduleIds),
    config
  );
};

export const useAddForecastRulesToPipeline = (config?: any) => {
  return useMutation(
    ({ id, forecastRuleIds }: { id: number; forecastRuleIds: number[] }) =>
      mutations.addForecastRulesToPipeline(id, forecastRuleIds),
    config
  );
};

export const useRemoveForecastRulesFromPipeline = (config?: any) => {
  return useMutation(
    ({ id, forecastRuleIds }: { id: number; forecastRuleIds: number[] }) =>
      mutations.removeForecastRulesFromPipeline(id, forecastRuleIds),
    config
  );
};

export const useEnableForecastRules = (config?: any) => {
  return useMutation(
    ({ fkDeal, forecastRules }: { fkDeal: number; forecastRules: number[] }) =>
      mutations.enableForecastRules(fkDeal, forecastRules),
    config
  );
};

export const useDisableForecastRules = (config?: any) => {
  return useMutation(
    ({ fkDeal, forecastRules }: { fkDeal: number; forecastRules: number[] }) =>
      mutations.disableForecastRules(fkDeal, forecastRules),
    config
  );
};

export const usePublishScheduleInstance = (config?: any) => {
  return useMutation(
    (input: CreatePublishedScheduleInstanceInput) =>
      mutations.publishScheduleInstance(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'publishedScheduleInstances',
          variables.fkDeal,
        ]);
      },
      ...config,
    }
  );
};

export const useCreateScheduleInstance = (config?: any) => {
  return useMutation(
    (input: CreatePublishedScheduleInstanceInput) =>
      mutations.createScheduleInstance(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'publishedScheduleInstances',
          variables.fkDeal,
        ]);
      },
      ...config,
    }
  );
};

export const useUpdateScheduleInstance = (dealId: number, config?: any) => {
  return useMutation(
    (input: UpdatePublishedScheduleInstanceInput) =>
      mutations.updateScheduleInstance(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['publishedScheduleInstances', dealId]);
      },
      ...config,
    }
  );
};

export const useDeleteScheduleInstance = (dealId: number, config?: any) => {
  return useMutation((id: number) => mutations.deleteScheduleInstance(id), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['publishedScheduleInstances', dealId]);
    },
    ...config,
  });
};

export const useCreatePublishedDealEvent = (config?: any) => {
  return useMutation(
    (event: CreatePublishedDealEventInput) => {
      return mutations.createPublishedDealEvent(event);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'publishedScheduleInstances',
          variables.fkDeal,
          variables.fkPublishedInstanceSchedule,
        ]);
      },
      ...config,
    }
  );
};

export const useUpdatePublishedDealEvent = (
  config?: any) => {
  return useMutation(
    (event: UpdatePublishedDealEventInput) => {
      return mutations.updatePublishedDealEvent(event);
    },
    {
      onMutate: (variables) => {
        const snapshot = queryCache.getQueryData([
          'publishedScheduleInstances', variables.fkDeal, variables.fkPublishedInstanceSchedule
        ]);
        queryCache.setQueryData(
          ['publishedScheduleInstances', variables.fkDeal, variables.fkPublishedInstanceSchedule],
          (old?: GraphQLResult<GetPublishedScheduleInstance>) => {
            if (old && old.data?.deal?.publishedScheduleInstance?.events) {
              const updated = { ...old };
              const oldEvents = old.data.deal.publishedScheduleInstance.events
                ? [...old.data.deal.publishedScheduleInstance.events]
                : [];
              const index = oldEvents.findIndex((x) => x.id == variables.fkEvent);
              if (index != -1) {
                if (variables.isComplete !== undefined && variables.isComplete !== null) {
                  oldEvents[index].isComplete = variables.isComplete;
                }
                if (variables.startDate) {
                  oldEvents[index].startDate = variables.startDate;
                }
                if (variables.endDate) {
                  oldEvents[index].endDate = variables.endDate;
                }
              }
              if (updated && updated.data?.deal?.publishedScheduleInstance?.events) {
                updated.data.deal.publishedScheduleInstance.events = oldEvents;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return snapshot;
      },
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'publishedScheduleInstances',
          variables.fkDeal,
          variables.fkPublishedInstanceSchedule,
        ]);
      },
      ...config,
    }
  );
};

export const useCreateBuilding = (config?: any) => {
  return useMutation(
    (input: CreateBuildingInput) => mutations.createBuilding(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealBuildings', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useUpdateBuilding = (dealId: number, config?: any) => {
  return useMutation(
    (input: UpdateBuildingInput) => mutations.updateBuilding(input),
    {
      onMutate: (variables) => {
        const snapshot = queryCache.getQueryData([
          'dealEvents',
          dealId
        ]);
        queryCache.setQueryData(
          ['dealEvents', dealId],
          (old?: GraphQLResult<GetDealSchedule>) => {
            if (old && old.data?.deal?.buildings) {
              const updated = { ...old };
              const oldBuildings = old.data.deal.buildings
                ? [...old.data.deal.buildings]
                : [];
              const index = oldBuildings.findIndex((x) => x.id == variables.id);
              if (index != -1) {
                if (variables.isTcoReceived !== undefined && variables.isTcoReceived !== null) {
                  oldBuildings[index].isTcoReceived = variables.isTcoReceived;
                }
                if (variables.tcoDate) {
                  oldBuildings[index].tcoDate = variables.tcoDate;
                }
              }
              if (updated && updated.data?.deal?.buildings) {
                updated.data.deal.buildings = oldBuildings;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return snapshot;
      },
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealBuildings', dealId]);
        queryCache.refetchQueries(['dealEvents', dealId]);
      },
      ...config,
    }
  );
};

export const useDeleteBuilding = (dealId: number, config?: any) => {
  return useMutation((id: number) => mutations.deleteBuilding(id), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['dealBuildings', dealId]);
    },
    ...config,
  });
};

export const useCreateBond = (config?: any) => {
  return useMutation((input: CreateBondInput) => mutations.createBond(input), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['dealBonds', variables.fkDeal]);
    },
    ...config,
  });
};

export const useUpdateBond = (dealId: number, config?: any) => {
  return useMutation((input: UpdateBondInput) => mutations.updateBond(input), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['dealBonds', dealId]);
    },
    ...config,
  });
};

export const useDeleteBond = (dealId: number, config?: any) => {
  return useMutation((id: number) => mutations.deleteBond(id), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['dealBonds', dealId]);
    },
    ...config,
  });
};

export const useCreateGcEstimate = (config?: any) => {
  return useMutation(
    (input: CreateGcEstimateInput) => mutations.createGcEstimate(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealGcEstimates', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useUpdateGcEstimate = (dealId: number, config?: any) => {
  return useMutation(
    (input: UpdateGcEstimateInput) => mutations.updateGcEstimate(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealGcEstimates', dealId]);
      },
      ...config,
    }
  );
};

export const useDeleteGcEstimate = (dealId: number, config?: any) => {
  return useMutation((id: number) => mutations.deleteGcEstimate(id), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['dealGcEstimates', dealId]);
    },
    ...config,
  });
};

// export const useAddDealPinnedMetric = (config?: any) => {
//   return useMutation(
//     ({ fkDeal, fkMetric }: { fkDeal: number; fkMetric: number }) =>
//       mutations.addDealPinnedMetric(fkDeal, fkMetric),
//     {
//       onSuccess: (data, variables) => {
//         queryCache.refetchQueries(['deal', variables.fkDeal, 'pinnedMetrics']);
//         queryCache.refetchQueries(['deal', variables.fkDeal]);
//       },
//       ...config,
//     }
//   );
// };

// export const useReorderDealPinnedMetric = (fkDeal: number, config?: any) => {
//   return useMutation(
//     ({
//       id,
//       beforeId,
//       afterId,
//     }: {
//       id: number;
//       beforeId?: number;
//       afterId?: number;
//     }) => mutations.reorderDealPinnedMetric(fkDeal, id, beforeId, afterId),
//     {
//       onSuccess: () => {
//         queryCache.refetchQueries(['deal', fkDeal, 'pinnedMetrics']);
//         queryCache.refetchQueries(['deal', fkDeal]);
//       },
//       ...config,
//     }
//   );
// };

export const useReorderPipelinePinnedMetric = (fkPipeline: number, config?: any) => {
  return useMutation(
    ({
      id,
      beforeId,
      afterId,
    }: {
      id: number;
      beforeId?: number;
      afterId?: number;
    }) => mutations.reorderPipelinePinnedMetric(fkPipeline, id, beforeId, afterId),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['pipelines', fkPipeline]);
      },
      ...config,
    }
  );
};

export const useReorderPipelinePinnedEvent = (fkPipeline: number, config?: any) => {
  return useMutation(
    ({
      id,
      beforeId,
      afterId,
    }: {
      id: number;
      beforeId?: number;
      afterId?: number;
    }) => mutations.reorderPipelinePinnedEvent(fkPipeline, id, beforeId, afterId),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['pipelines', fkPipeline]);
      },
      ...config,
    }
  );
};

export const useReorderPipelinePinnedPartnerRole = (fkPipeline: number, config?: any) => {
  return useMutation(
    ({
      id,
      beforeId,
      afterId,
    }: {
      id: number;
      beforeId?: number;
      afterId?: number;
    }) => mutations.reorderPipelinePinnedPartnerRole(fkPipeline, id, beforeId, afterId),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['pipelines', fkPipeline]);
      },
      ...config,
    }
  );
};

export const useReorderPipelinePinnedTeamMemberRole = (fkPipeline: number, config?: any) => {
  return useMutation(
    ({
      id,
      beforeId,
      afterId,
    }: {
      id: number;
      beforeId?: number;
      afterId?: number;
    }) => mutations.reorderPipelinePinnedTeamMemberRole(fkPipeline, id, beforeId, afterId),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['pipelines', fkPipeline]);
      },
      ...config,
    }
  );
};

// export const useRemoveDealPinnedMetric = (config?: any) => {
//   return useMutation(
//     ({ fkDeal, fkMetric }: { fkDeal: number; fkMetric: number }) =>
//       mutations.removeDealPinnedMetric(fkDeal, fkMetric),
//     {
//       onSuccess: (data, variables) => {
//         queryCache.refetchQueries(['deal', variables.fkDeal, 'pinnedMetrics']);
//         queryCache.refetchQueries(['deal', variables.fkDeal]);
//       },
//       ...config,
//     }
//   );
// };

export const useCreateGlAccount = (config?: any) => {
  return useMutation(
    (input: CreateGlAccountInput) => mutations.createGlAccount(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['accounts']);
      },
      ...config,
    }
  );
};

export const useUpdateGlAccount = (config?: any) => {
  return useMutation(
    (input: UpdateGlAccountInput) => mutations.updateGlAccount(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['accounts']);
        queryCache.refetchQueries(['accounts', variables.id]);
      },
      ...config,
    }
  );
};

export const useDeleteGlAccount = (config?: any) => {
  return useMutation((id: number) => mutations.deleteGlAccount(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['accounts']);
    },
    ...config,
  });
};

export const useCreateGlAccountTree = (config?: any) => {
  return useMutation(
    (input: CreateGlAccountTreeInput) => mutations.createGlAccountTree(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['accountTrees']);
      },
      ...config,
    }
  );
};

export const useUpdateGlAccountTree = (config?: any) => {
  return useMutation(
    (input: UpdateGlAccountTreeInput) => mutations.updateGlAccountTree(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['accountTrees']);
        queryCache.refetchQueries(['accountTrees', variables.id]);
      },
      ...config,
    }
  );
};

export const useDeleteGlAccountTree = (config?: any) => {
  return useMutation((id: number) => mutations.deleteGlAccountTree(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['accountTrees']);
    },
    ...config,
  });
};

export const useCreatePipelineContingencyCategory = (
  pipelineId: number,
  config?: any
) => {
  return useMutation(
    (input: CreatePipelineContingencyCategoryInput) =>
      mutations.createPipelineContingencyCategory(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['pipelines', pipelineId]);
      },
      ...config,
    }
  );
};

export const useUpdatePipelineContingencyCategory = (
  pipelineId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdatePipelineContingencyCategoryInput) =>
      mutations.updatePipelineContingencyCategory(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['pipelines', pipelineId]);
      },
      ...config,
    }
  );
};

export const useDeletePipelineContingencyCategory = (
  pipelineId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deletePipelineContingencyCategory(id),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['pipelines', pipelineId]);
      },
      ...config,
    }
  );
};

export const useCreatePipelineGeneralOffset = (
  pipelineId: number,
  config?: any
) => {
  return useMutation(
    (input: CreatePipelineGeneralOffsetInput) =>
      mutations.createPipelineGeneralOffset(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['pipelines', pipelineId]);
      },
      ...config,
    }
  );
};

export const useUpdatePipelineGeneralOffset = (
  pipelineId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdatePipelineGeneralOffsetInput) =>
      mutations.updatePipelineGeneralOffset(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['pipelines', pipelineId]);
      },
      ...config,
    }
  );
};

export const useDeletePipelineGeneralOffset = (
  pipelineId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deletePipelineGeneralOffset(id),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['pipelines', pipelineId]);
      },
      ...config,
    }
  );
};

export const useCreatePipelineAccountOffset = (
  pipelineId: number,
  config?: any
) => {
  return useMutation(
    (input: CreatePipelineAccountOffsetInput) =>
      mutations.createPipelineAccountOffset(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['pipelines', pipelineId]);
      },
      ...config,
    }
  );
};

export const useUpdatePipelineAccountOffset = (
  pipelineId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdatePipelineAccountOffsetInput) =>
      mutations.updatePipelineAccountOffset(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['pipelines', pipelineId]);
      },
      ...config,
    }
  );
};

export const useDeletePipelineAccountOffset = (
  pipelineId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deletePipelineAccountOffset(id),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['pipelines', pipelineId]);
      },
      ...config,
    }
  );
};

export const useCreateForecastRule = (config?: any) => {
  return useMutation(
    (input: CreateForecastRuleInput) => mutations.createForecastRule(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['forecastRules']);
      },
      ...config,
    }
  );
};

export const useUpdateForecastRule = (config?: any) => {
  return useMutation(
    (input: UpdateForecastRuleInput) => mutations.updateForecastRule(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['forecastRules']);
      },
      ...config,
    }
  );
};

export const useDeleteForecastRule = (config?: any) => {
  return useMutation((id: number) => mutations.deleteForecastRule(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['forecastRules']);
    },
    ...config,
  });
};

export const useAddAccountsToAccountTree = (config?: any) => {
  return useMutation(
    ({
      id,
      parentId,
      accounts,
    }: {
      id: number;
      parentId?: number;
      accounts: number[];
    }) => mutations.addAccountsToAccountTree(id, accounts, parentId),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['accountTrees', variables.id]);
      },
      ...config,
    }
  );
};

export const useRemoveAccountsFromAccountTree = (config?: any) => {
  return useMutation(
    ({ id, accounts }: { id: number; accounts: number[] }) =>
      mutations.removeAccountsFromAccountTree(id, accounts),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['accountTrees', variables.id]);
      },
      ...config,
    }
  );
};

export const useChangeAccountTreeParent = (config?: any) => {
  return useMutation(
    ({
      id,
      parentId,
      accounts,
    }: {
      id: number;
      parentId?: number;
      accounts: number[];
    }) => mutations.changeAccountTreeParent(id, accounts, parentId),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['accountTrees', variables.id]);
      },
      ...config,
    }
  );
};

export const useCreateChangeEvent = (config?: any) => {
  return useMutation(
    (input: CreateChangeEventInput) => mutations.createChangeEvent(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealChangeItems', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useUpdateChangeEvent = (dealId: number, config?: any) => {
  return useMutation(
    (input: UpdateChangeEventInput) => mutations.updateChangeEvent(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealChangeItems', dealId]);
      },
      ...config,
    }
  );
};

export const useDeleteChangeEvent = (dealId: number, config?: any) => {
  return useMutation((id: number) => mutations.deleteChangeEvent(id), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['dealChangeItems', dealId]);
    },
    ...config,
  });
};

export const useCreateChangeOrderPackage = (config?: any) => {
  return useMutation(
    (input: CreateChangeOrderPackageInput) =>
      mutations.createChangeOrderPackage(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealChangeItems', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useUpdateChangeOrderPackage = (dealId: number, config?: any) => {
  return useMutation(
    (input: UpdateChangeOrderPackageInput) =>
      mutations.updateChangeOrderPackage(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealChangeItems', dealId]);
      },
      ...config,
    }
  );
};

export const useDeleteChangeOrderPackage = (dealId: number, config?: any) => {
  return useMutation((id: number) => mutations.deleteChangeOrderPackage(id), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['dealChangeItems', dealId]);
    },
    ...config,
  });
};

export const useCreatePotentialChangeOrder = (config?: any) => {
  return useMutation(
    (input: CreatePotentialChangeOrderInput) =>
      mutations.createPotentialChangeOrder(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealChangeItems', variables.fkDeal]);
      },
      ...config,
    }
  );
};

export const useUpdatePotentialChangeOrder = (dealId: number, config?: any) => {
  return useMutation(
    (input: UpdatePotentialChangeOrderInput) =>
      mutations.updatePotentialChangeOrder(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['dealChangeItems', dealId]);
      },
      ...config,
    }
  );
};

export const useDeletePotentialChangeOrder = (dealId: number, config?: any) => {
  return useMutation((id: number) => mutations.deletePotentialChangeOrder(id), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['dealChangeItems', dealId]);
    },
    ...config,
  });
};

export const useCreateApprovalPackage = (config?: any) => {
  return useMutation(
    (input: CreateApprovalPackageInput) =>
      mutations.createApprovalPackage(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages']);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackage = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateApprovalPackageInput) =>
      mutations.updateApprovalPackage(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useArchiveApprovalPackage = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    ({ id, status }: { id: number; status: ApprovalPackageStatusEnum }) =>
      mutations.archiveApprovalPackage(id, status),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const usePublishApprovalPackage = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation((id: number) => mutations.publishApprovalPackage(id), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      queryCache.refetchQueries([
        'publishedApprovalPackages',
        approvalPackageId,
      ]);
    },
    ...config,
  });
};

export const useDeleteApprovalPackage = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation((id: number) => mutations.deleteApprovalPackage(id), {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
    },
    ...config,
  });
};

export const useCreateApprovalPackageSection = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (input: CreateApprovalPackageSectionInput) =>
      mutations.createApprovalPackageSection(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackageSection = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateApprovalPackageSectionInput) =>
      mutations.updateApprovalPackageSection(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useDeleteApprovalPackageSection = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deleteApprovalPackageSection(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackageSubSectionText = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateApprovalPackageSubSectionTextInput) =>
      mutations.updateApprovalPackageSubSectionText(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackageSubSectionChecklist = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateApprovalPackageSubSectionChecklistInput) =>
      mutations.updateApprovalPackageSubSectionChecklist(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useCreateApprovalPackageSubSection = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    ({
      sectionId,
      type,
      checklistId,
      gcEstimateTypeId,
    }: {
      sectionId: number;
      type: ApprovalPackageSubSectionTypeEnum;
      checklistId?: number;
      gcEstimateTypeId?: number;
    }) => mutations.createApprovalPackageSubSection(sectionId, type, checklistId, gcEstimateTypeId),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackageSubSection = (
  approvalPackageId: number,
  sectionId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateApprovalPackageSubSectionInput) =>
      mutations.updateApprovalPackageSubSection(input),
    {
      onMutate: (variables) => {
        const snapshot = queryCache.getQueryData([
          'approvalPackages',
          approvalPackageId,
        ]);
        queryCache.setQueryData(
          ['approvalPackages', approvalPackageId],
          (old?: GraphQLResult<GetApprovalPackage>) => {
            if (old && old.data?.approvalPackage) {
              const updated = { ...old };
              const oldSections = old.data.approvalPackage.sections
                ? [...old.data.approvalPackage.sections]
                : [];
              const index = oldSections.findIndex((x) => x.id == sectionId);
              if (index != -1) {
                const section = oldSections[index];
                const subSectionIndex = section.content?.findIndex(
                  (x) => x.id == variables.id
                );
                if (
                  subSectionIndex &&
                  subSectionIndex != -1 &&
                  section.content &&
                  section.content[subSectionIndex]
                ) {
                  oldSections[index].content![subSectionIndex].orderId =
                    variables.orderId;
                }
              }
              if (updated && updated.data?.approvalPackage) {
                updated.data.approvalPackage.sections = oldSections;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return snapshot;
      },
      onError: (err, data, rollback: any) => rollback(),
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useDeleteApprovalPackageSubSection = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deleteApprovalPackageSubSection(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useUploadApprovalPackageImage = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    ({ id, displayName, file }: { id: number; displayName: string; file: any }) =>
      mutations.uploadApprovalPackageImage(id, displayName, file),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useUploadApprovalPackageExhibit = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    ({ id, displayName, file }: { id: number; displayName: string; file: any }) =>
      mutations.uploadApprovalPackageExhibit(id, displayName, file),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackageExhibit = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    ({ id, displayName, fileName }: { id: number; fileName?: string; displayName?: string }) => mutations.updateApprovalPackageExhibit(id, displayName, fileName),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useDeleteApprovalPackageExhibit = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deleteApprovalPackageExhibit(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useCreateApprovalPackageReviewer = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (input: CreateApprovalPackageReviewerInput) =>
      mutations.createApprovalPackageReviewer(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
        queryCache.refetchQueries([
          'publishedApprovalPackages',
          approvalPackageId,
        ]);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackageReviewer = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateApprovalPackageReviewerInput) =>
      mutations.updateApprovalPackageReviewer(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
        queryCache.refetchQueries([
          'publishedApprovalPackages',
          approvalPackageId,
        ]);
      },
      ...config,
    }
  );
};

export const useDeleteApprovalPackageReviewer = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deleteApprovalPackageReviewer(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
        queryCache.refetchQueries([
          'publishedApprovalPackages',
          approvalPackageId,
        ]);
      },
      ...config,
    }
  );
};

export const useCreateApprovalPackageContributor = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (input: CreateApprovalPackageContributorInput) =>
      mutations.createApprovalPackageContributor(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
        queryCache.refetchQueries([
          'publishedApprovalPackages',
          approvalPackageId,
        ]);
      },
      ...config,
    }
  );
};

export const useDeleteApprovalPackageContributor = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deleteApprovalPackageContributor(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
        queryCache.refetchQueries([
          'publishedApprovalPackages',
          approvalPackageId,
        ]);
      },
      ...config,
    }
  );
};

export const useRefreshApprovalPackageKeyRisks = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.refreshApprovalPackageKeyRisks(id, approvalPackageId),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
        queryCache.refetchQueries([
          'publishedApprovalPackages',
          approvalPackageId,
        ]);
      },
      ...config,
    }
  );
};

export const useRefreshApprovalPackageDealMerits = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.refreshApprovalPackageDealMerits(id, approvalPackageId),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
        queryCache.refetchQueries([
          'publishedApprovalPackages',
          approvalPackageId,
        ]);
      },
      ...config,
    }
  );
};

export const useCreateApprovalPackageSubSectionTableCell = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.createApprovalPackageSubSectionTableCell(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
        queryCache.refetchQueries([
          'publishedApprovalPackages',
          approvalPackageId,
        ]);
      },
      ...config,
    }
  );
};

export const useDeleteApprovalPackageSubSectionTableCell = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deleteApprovalPackageSubSectionTableCell(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useSetEntriesForApprovalPackageSubSectionTableCell = (
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    ({
      id,
      entries,
    }: {
      id: number;
      entries: IcSubSectionTableCellEntryInput[];
    }) =>
      mutations.setEntriesForApprovalPackageSubSectionTableCell(id, entries),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

export const useSetApprovalPackageSubSectionTableColumns = (
  sectionId: number,
  approvalPackageId: number,
  config?: any
) => {
  return useMutation(
    ({ id, columns }: { id: number; columns: number }) =>
      mutations.setApprovalPackageSubSectionTableColumns(id, columns),
    {
      onMutate: (variables) => {
        const snapshot = queryCache.getQueryData([
          'approvalPackages',
          approvalPackageId,
        ]);
        queryCache.setQueryData(
          ['approvalPackages', approvalPackageId],
          (old?: GraphQLResult<GetApprovalPackage>) => {
            if (old && old.data?.approvalPackage) {
              const updated = { ...old };
              const oldSections = old.data.approvalPackage.sections
                ? [...old.data.approvalPackage.sections]
                : [];
              const index = oldSections.findIndex((x) => x.id == sectionId);
              if (index != -1) {
                const section = oldSections[index];
                const subSectionIndex = section.content?.findIndex(
                  (x) => x.id == variables.id
                );
                if (
                  subSectionIndex &&
                  subSectionIndex != -1 &&
                  section.content &&
                  section.content[subSectionIndex].__typename ==
                  'ApprovalPackageSubSectionTable'
                ) {
                  oldSections[index].content![subSectionIndex].columns =
                    variables.columns;
                }
              }
              if (updated && updated.data?.approvalPackage) {
                updated.data.approvalPackage.sections = oldSections;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return snapshot;
      },
      onError: (err, data, rollback: any) => rollback(),
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackages', approvalPackageId]);
      },
      ...config,
    }
  );
};

// Approval Package Templates

export const useCreateApprovalPackageTemplate = (config?: any) => {
  return useMutation(
    (input: CreateApprovalPackageTemplateInput) =>
      mutations.createApprovalPackageTemplate(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackageTemplates']);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackageTemplate = (
  approvalPackageTemplateId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateApprovalPackageTemplateInput) =>
      mutations.updateApprovalPackageTemplate(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useCreateApprovalPackageTemplateFromPackage = (config?: any) => {
  return useMutation(
    (id: number) => mutations.createApprovalPackageTemplateFromPackage(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackageTemplates']);
      },
      ...config,
    }
  );
};

export const useDeleteApprovalPackageTemplate = (config?: any) => {
  return useMutation(
    (id: number) => mutations.deleteApprovalPackageTemplate(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackageTemplates']);
      },
      ...config,
    }
  );
};

export const useCreateApprovalPackageTemplateSection = (
  approvalPackageTemplateId: number,
  config?: any
) => {
  return useMutation(
    (input: CreateApprovalPackageSectionInput) =>
      mutations.createApprovalPackageTemplateSection(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackageTemplateSection = (
  approvalPackageTemplateId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateApprovalPackageSectionInput) =>
      mutations.updateApprovalPackageTemplateSection(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useDeleteApprovalPackageTemplateSection = (
  approvalPackageTemplateId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deleteApprovalPackageTemplateSection(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackageTemplateSubSectionText = (
  approvalPackageTemplateId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateApprovalPackageSubSectionTextInput) =>
      mutations.updateApprovalPackageTemplateSubSectionText(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackageTemplateSubSectionChecklist = (
  approvalPackageTemplateId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateApprovalPackageSubSectionChecklistInput) =>
      mutations.updateApprovalPackageTemplateSubSectionChecklist(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useCreateApprovalPackageTemplateSubSection = (
  approvalPackageTemplateId: number,
  config?: any
) => {
  return useMutation(
    ({
      sectionId,
      type,
      checklistId,
      gcEstimateTypeId
    }: {
      sectionId: number;
      type: ApprovalPackageSubSectionTypeEnum;
      checklistId?: number;
      gcEstimateTypeId?: number;
    }) => mutations.createApprovalPackageTemplateSubSection(sectionId, type, checklistId, gcEstimateTypeId),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackageTemplateSubSection = (
  approvalPackageTemplateId: number,
  sectionId: number,
  config?: any
) => {
  return useMutation(
    (input: UpdateApprovalPackageSubSectionInput) =>
      mutations.updateApprovalPackageTemplateSubSection(input),
    {
      onMutate: (variables) => {
        const snapshot = queryCache.getQueryData([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
        queryCache.setQueryData(
          ['approvalPackageTemplates', approvalPackageTemplateId],
          (old?: GraphQLResult<GetApprovalPackageTemplate>) => {
            if (old && old.data?.approvalPackageTemplate) {
              const updated = { ...old };
              const oldSections = old.data.approvalPackageTemplate.sections
                ? [...old.data.approvalPackageTemplate.sections]
                : [];
              const index = oldSections.findIndex((x) => x.id == sectionId);
              if (index != -1) {
                const section = oldSections[index];
                const subSectionIndex = section.content?.findIndex(
                  (x) => x.id == variables.id
                );
                if (
                  subSectionIndex &&
                  subSectionIndex != -1 &&
                  section.content &&
                  section.content[subSectionIndex]
                ) {
                  oldSections[index].content![subSectionIndex].orderId =
                    variables.orderId;
                }
              }
              if (updated && updated.data?.approvalPackageTemplate) {
                updated.data.approvalPackageTemplate.sections = oldSections;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return snapshot;
      },
      onError: (err, data, rollback: any) => rollback(),
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useDeleteApprovalPackageTemplateSubSection = (
  approvalPackageTemplateId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deleteApprovalPackageTemplateSubSection(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useCreateApprovalPackageTemplateSubSectionTableCell = (
  approvalPackageTemplateId: number,
  config?: any
) => {
  return useMutation(
    (id: number) =>
      mutations.createApprovalPackageTemplateSubSectionTableCell(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useDeleteApprovalPackageTemplateSubSectionTableCell = (
  approvalPackageTemplateId: number,
  config?: any
) => {
  return useMutation(
    (id: number) =>
      mutations.deleteApprovalPackageTemplateSubSectionTableCell(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useSetEntriesForApprovalPackageTemplateSubSectionTableCell = (
  approvalPackageTemplateId: number,
  config?: any
) => {
  return useMutation(
    ({
      id,
      entries,
    }: {
      id: number;
      entries: IcSubSectionTableCellEntryInput[];
    }) =>
      mutations.setEntriesForApprovalPackageTemplateSubSectionTableCell(
        id,
        entries
      ),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useSetApprovalPackageTemplateSubSectionTableColumns = (
  sectionId: number,
  approvalPackageTemplateId: number,
  config?: any
) => {
  return useMutation(
    ({ id, columns }: { id: number; columns: number }) =>
      mutations.setApprovalPackageTemplateSubSectionTableColumns(id, columns),
    {
      onMutate: (variables) => {
        const snapshot = queryCache.getQueryData([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
        queryCache.setQueryData(
          ['approvalPackageTemplates', approvalPackageTemplateId],
          (old?: GraphQLResult<GetApprovalPackageTemplate>) => {
            if (old && old.data?.approvalPackageTemplate) {
              const updated = { ...old };
              const oldSections = old.data.approvalPackageTemplate.sections
                ? [...old.data.approvalPackageTemplate.sections]
                : [];
              const index = oldSections.findIndex((x) => x.id == sectionId);
              if (index != -1) {
                const section = oldSections[index];
                const subSectionIndex = section.content?.findIndex(
                  (x) => x.id == variables.id
                );
                if (
                  subSectionIndex &&
                  subSectionIndex != -1 &&
                  section.content &&
                  section.content[subSectionIndex].__typename ==
                  'TemplateApprovalPackageSubSectionTable'
                ) {
                  oldSections[index].content![subSectionIndex].columns =
                    variables.columns;
                }
              }
              if (updated && updated.data?.approvalPackageTemplate) {
                updated.data.approvalPackageTemplate.sections = oldSections;
              }
              return updated;
            } else {
              return old;
            }
          }
        );
        return snapshot;
      },
      onError: (err, data, rollback: any) => rollback(),
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'approvalPackageTemplates',
          approvalPackageTemplateId,
        ]);
      },
      ...config,
    }
  );
};

export const useCreateLabel = (
  config?: any
) => {
  return useMutation(
    (input: CreateLabelInput) =>
      mutations.createLabel(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'labels'
        ]);
      },
      ...config,
    }
  );
};

export const useUpdateLabel = (
  config?: any
) => {
  return useMutation(
    (input: UpdateLabelInput) =>
      mutations.updateLabel(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'labels'
        ]);
      },
      ...config,
    }
  );
};

export const useDeleteLabel = (
  config?: any
) => {
  return useMutation(
    (id: number) =>
      mutations.deleteLabel(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'labels'
        ]);
      },
      ...config,
    }
  );
};

export const useAssignChangeEventsToParent = (
  fkDeal: number,
  config?: any
) => {
  return useMutation(
    ({ changeEventIds, parentId }: { changeEventIds: number[]; parentId?: number }) =>
      mutations.assignChangeEventsToParent(fkDeal, changeEventIds, parentId),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries([
          'dealChangeItems', fkDeal
        ]);
      },
      ...config,
    }
  );
};

export const useAssignChangeOrdersToParent = (
  fkDeal: number,
  config?: any
) => {
  return useMutation(
    ({ changeOrderIds, parentId }: { changeOrderIds: number[]; parentId?: number }) =>
      mutations.assignChangeOrdersToParent(fkDeal, changeOrderIds, parentId),
    {
      onSuccess: () => {
        queryCache.refetchQueries([
          'dealChangeItems', fkDeal
        ]);
      },
      ...config,
    }
  );
};

export const useAssignPotentialChangeOrdersToParent = (
  fkDeal: number,
  config?: any
) => {
  return useMutation(
    ({ potentialChangeOrderIds, parentId }: { potentialChangeOrderIds: number[]; parentId?: number }) =>
      mutations.assignPotentialChangeOrdersToParent(fkDeal, potentialChangeOrderIds, parentId),
    {
      onSuccess: () => {
        queryCache.refetchQueries([
          'dealChangeItems', fkDeal
        ]);
      },
      ...config,
    }
  );
};

export const useRunDefaultForecastRules = (
  oppId: number,
  fkGlAccountTree: number,
  config?: any
) => {
  return useMutation(
    ({ fkDeal, accountIds }: { fkDeal: number; accountIds?: number[] }) =>
      mutations.runDefaultForecastRules(fkDeal, accountIds),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['dealTransactions', oppId]);
        queryCache.refetchQueries([
          'dealMasterBudgetOverview', oppId, fkGlAccountTree
        ]);
      },
      ...config,
    }
  );
};

export const useForecastCapitalizationAccounts = (
  oppId: number,
  fkGlAccountTree: number,
  config?: any
) => {
  return useMutation(
    (fkDeal: number) =>
      mutations.forecastCapitalizationAccounts(fkDeal),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['dealTransactions', oppId]);
        queryCache.refetchQueries([
          'dealMasterBudgetOverview', oppId, fkGlAccountTree
        ]);
      },
      ...config,
    }
  );
};

export const useForecastInterestAccount = (
    dealId: number,
    fkGlAccountTree: number,
    config?: any
  ) => {
    return useMutation(
      (fkDeal: number) =>
        mutations.forecastInterestAccount(fkDeal),
      {
        onSuccess: () => {
          queryCache.refetchQueries(['dealTransactions', dealId]);
          queryCache.refetchQueries([
            'dealMasterBudgetOverview', dealId, fkGlAccountTree
          ]);
        },
        ...config,
      }
    );
  };

export const useCreateApprovalPackageType = (config?: any) => {
  return useMutation(
    (input: CreateApprovalPackageTypeInput) => mutations.createApprovalPackageType(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['approvalPackageTypes']);
      },
      ...config,
    }
  );
};

export const useUpdateApprovalPackageType = (config?: any) => {
  return useMutation(
    (input: UpdateApprovalPackageTypeInput) => mutations.updateApprovalPackageType(input),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['approvalPackageTypes']);
        queryCache.refetchQueries(['approvalPackageTypes', variables.id]);
      },
      ...config,
    }
  );
};

export const useDeleteApprovalPackageType = (config?: any) => {
  return useMutation((id: number) => mutations.deleteApprovalPackageType(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['approvalPackageTypes']);
    },
    ...config,
  });
};

export const useUploadDealArtifact = (
  dealId: number,
  config?: any
) => {
  return useMutation(
    ({ dealId, artifactId, file }: { dealId: number; artifactId: number; file: any }) =>
      mutations.uploadDealArtifact(dealId, artifactId, file),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['deals', dealId, 'artifacts']);
      },
      throwOnError: true,
      ...config,
    }
  );
};

export const useDeleteDealArtifact = (
  dealId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deleteDealArtifact(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['deals', dealId, 'artifacts']);
      },
      ...config,
    }
  );
};

export const useBulkEditVisibleDealEvents = (
  config?: any
) => {
  return useMutation(
    ({ pipelineId, dealIds, eventIds }: { pipelineId: number; dealIds: number[]; eventIds: number[] }) => mutations.bulkEditVisibleDealEvents(pipelineId, dealIds, eventIds),
    config,
  );
};

export const useBulkUnhideDealEvents = (
  config?: any
) => {
  return useMutation(
    ({ pipelineId, dealIds, eventIds }: { pipelineId: number; dealIds: number[]; eventIds: number[] }) => mutations.bulkUnhideDealEvents(pipelineId, dealIds, eventIds),
    config,
  );
};

export const useUploadContactAttachment = (
  contactId: number,
  config?: any
) => {
  return useMutation(
    ({ id, displayName, file }: { id: number; displayName: string; file: any }) =>
      mutations.uploadContactAttachment(id, displayName, file),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['contacts', contactId]);
      },
      ...config,
    }
  );
};

export const useDeleteContactAttachment = (
  dealId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deleteContactAttachment(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['deals', dealId, 'artifacts']);
      },
      ...config,
    }
  );
};

export const useUploadFirmAttachment = (
  firmId: number,
  config?: any
) => {
  return useMutation(
    ({ id, displayName, file }: { id: number; displayName: string; file: any }) =>
      mutations.uploadFirmAttachment(id, displayName, file),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['firms', firmId]);
      },
      ...config,
    }
  );
};
export const useDeleteFirmAttachment = (
  dealId: number,
  config?: any
) => {
  return useMutation(
    (id: number) => mutations.deleteFirmAttachment(id),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['deals', dealId, 'artifacts']);
      },
      ...config,
    }
  );
};

export const useCreateInterestRate = (config?: any) => {
  return useMutation(
    (input: CreateInterestRateInput) => mutations.createInterestRate(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['interestRates']);
      },
      ...config,
    }
  );
};

export const useUpdateInterestRate = (config?: any) => {
  return useMutation(
    (input: UpdateInterestRateInput) => mutations.updateInterestRate(input),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['interestRates']);
      },
      ...config,
    }
  );
};

export const useDeleteInterestRate = (config?: any) => {
  return useMutation((id: number) => mutations.deleteInterestRate(id), {
    onSuccess: () => {
      queryCache.refetchQueries(['interestRates']);
    },
    ...config,
  });
};

export const useUploadInterestRateFile = (
  config?: any
) => {
  return useMutation(
    ({ file }: { file: any }) =>
      mutations.uploadInterestRateFile(file),
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['interestRates']);
      },
      ...config,
    }
  );
};