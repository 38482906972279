import React, {
  FunctionComponent,
  useState,
  useMemo,
  ReactElement,
  useContext,
  useEffect,
} from 'react';
import styled, { ThemeContext } from 'styled-components';
import {
  Column,
  PageDetails,
  Row,
  StatusCard,
  Spacer,
  Section,
  Button,
  PageToolbar,
  ToolbarButton,
  HorizontalSeparator,
  SelectFormikField,
  InputFormikField,
  Form,
  SelectOption,
  DropdownItem,
  DropdownButton,
  MenuSeparator,
  DatePickerFormikField,
  ToolbarMessage,
  Tooltip,
} from 'shared/components';
import { font, getMilestoneColor } from 'shared/utils/styles';
import DashboardCard from '../DashboardCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, useHistory, useRouteMatch, useParams } from 'react-router-dom';
import StatusUpdates from './StatusUpdates';
import { usePageTitle } from 'shared/hooks';
import {
  useGetFunds,
  useGetStructures,
  useGetMarkets,
  useGetMarketSegments,
  useGetDealPipelineContacts,
  useGetDeal,
  useGetFundEntities,
  useGetProductTypes,
  useGetStates,
  useGetSubmarkets,
  useUpdateDeal,
  useGetDealTypes,
  useUpdateTeamMembers,
  useUpdateDealPartners,
  useGetOrganizationUsers,
  useGetPipelineFirms,
  useGetDealKeyMilestones,
  useGetRegions,
} from 'shared/hooks/api';
import { StatusVariant } from 'shared/components/card/StatusCard';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import CreateStatusUpdateModal from './modals/CreateStatusUpdateModal';
import DealNarrativeModal from '../modals/DealNarrativeModal';
import * as Yup from 'yup';
import { PartialNullable } from 'shared/utils/utilityTypes';
import KeyRisksModal, { KeyRisk } from '../modals/KeyRisksModal';
import parseISO from 'date-fns/parseISO';
import DealMeritsModal, { DealMerit } from '../modals/DealMeritsModal';
import { DealEvent, UpdateDealInput } from 'shared/hooks/api/graphql/generated';
import { diff } from 'shared/utils/deepDiff';
import { useNotification } from 'shared/components/notifications';
import Map from 'shared/components/map/Map';
import { formatMetric } from 'shared/utils/metricFormatting';
import DealLocationModal from '../modals/DealLocationModal';
import StatusUpdateComments from './StatusUpdates/StatusUpdateComments';
import parse from 'date-fns/parse';
import {
  dateTimeOffsetFormat,
  monthDayYearFormat,
  yearMonthDayFormat,
} from 'shared/utils/dateFormats';
import { formatDate } from 'shared/utils/formatting/formatters';
import { isToday, isTomorrow } from 'date-fns';
import { RoutedStatusUpdateDetails } from '../modals/StatusUpdateDetails';

export type Milestone = {
  __typename?: "DealEvent" | undefined;
} & Pick<DealEvent, "id" | "name" | "startDate">;

const ProjectFieldGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProjectFieldButton = styled.div`
  margin: 5px 0;
`;

interface DealDataPoint {
  label: string;
  display?: any;
  field?: React.ReactElement;
  style?: React.CSSProperties;
  rowStyle?: React.CSSProperties;
}

const DealDataTableRowWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  min-height: 35px;
  padding: 2.5px 0;
`;

const DealDataLabel = styled.div`
  width: 50%;
  ${font.defaultSize};
  ${font.medium};
  color: ${({ theme }) => theme.textLight};
`;

const DealDataValue = styled.div`
  width: 50%;
  ${font.defaultSize};
  ${font.medium};
  color: ${({ theme }) => theme.textDark};
`;

const MilestonesWrapper = styled.div`
  background: ${({ theme }) => theme.backgroundPrimary};
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 20px;
`;

const MilestoneItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  &:last-child {
    border-bottom: none;
  }
`;

const MilestoneName = styled.span`
  color: ${({ theme }) => theme.textDark};
`;

const MilestoneDate = styled.span`
  color: #9b2c2c;
  font-weight: 600;
  text-align: center;
`;

interface MilestoneDateWrapperProps {
  background: string;
}

const MilestoneDateWrapper = styled.span<MilestoneDateWrapperProps>`
  color: #9b2c2c;
  font-weight: bold;
  background-color: ${props => props.background};
  padding: 4px 3px;
  text-align: center;
  border-radius: 3px;
`;

const PaginationContainer = styled.div`
  padding-top: 10px;
`;

const PageButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, disabled }) => (disabled ? theme.textLight : theme.primary)};
  &:focus {
    outline: none;
  }
  padding: 0;
`;

const PageIndicator = styled.span`
  color: ${({ theme }) => theme.textDark};
  margin: 0 10px;
`;
interface MilestoneListProps {
  milestoneCount: number;
}

const MilestonesList = styled.div<MilestoneListProps>`
  display: flex;
  flex-direction: column;
  height: ${props => props.milestoneCount > 4 ? '165px' : 'auto'};
`;

interface DealDataTableProps {
  isEditing?: boolean;
  rowStyle?: React.CSSProperties;
}

export const DealDataTable: FunctionComponent<DealDataTableProps> = ({
  children,
  isEditing,
  rowStyle,
}) => {
  const themeContext = useContext(ThemeContext);
  return (
    <React.Fragment>
      {React.Children.map(children, (child) => {
        if (child) {
          const row = child as ReactElement<DealDataPoint>;
          return (
            <Column>
              <DealDataTableRowWrapper
                style={row.props.rowStyle ? row.props.rowStyle : rowStyle}
              >
                <DealDataLabel style={row.props.style}>
                  {row.props.label}
                </DealDataLabel>
                <DealDataValue>
                  {row.props.field && isEditing
                    ? row.props.field
                    : row.props.display || '-'}
                </DealDataValue>
              </DealDataTableRowWrapper>
              <HorizontalSeparator />
            </Column>
          );
        }
      })}
    </React.Fragment>
  );
};

export const DealDataTableRow: FunctionComponent<DealDataPoint> = () => {
  return null;
};

const DashboardDetails = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 576px) {
    flex-direction: column-reverse;
  }
`;

const DetailsBody = styled.div`
  min-width: 600px;
  width: 100%;
  margin-right: 20px;
  @media (max-width: 576px) {
    padding-left: 0;
    min-width: 100%;
    width: 100%;
  }
`;

const DetailsColumn = styled.div`
  display: flex;
  min-width: 375px;
  width: 400px;
  flex-direction: column;
  padding-right: 10px;

  @media (max-width: 576px) {
    padding-left: 0;
    min-width: 100%;
    width: 100%;
  }
`;

type Option = {
  value: number;
  label: string;
};

export type DealFormValues = {
  name: string;
  otherName?: string | null;
  address1?: string | null;
  city?: string | null;
  state?: Option | null;
  zipcode?: string | null;
  parcelNumber?: string | null;
  yearBuilt?: number | null;
  market?: Option | null | null;
  marketSegment?: Option | null;
  submarket?: Option | null;
  productType?: Option | null;
  numberOfUnits?: number | null;
  nrsf?: number | null;
  irr?: number | null;
  unleverredIrr?: number | null;
  moic?: number | null;
  entryCapRate?: number | null;
  exitCapRate?: number | null;
  requiredEquity?: number | null;
  debt?: number | null;
  birthDate: Date;
  riskLevel?: Option | null;
  stage?: Option | null;
  fund?: Option | null;
  fundEntity?: Option | null;
  brokerFirm?: Option | null;
  brokerContact?: Option | null;
  buyerFirm?: Option | null;
  buyerContact?: Option | null;
  sellerFirm?: Option | null;
  sellerContact?: Option | null;
  purchasePrice?: number | null;
  legalEntity?: string | null;
  structure?: Option | null;
  dealType?: Option | null;
  region?: Option | null;
};

export const dealFormSchema: Yup.ObjectSchema<DealFormValues> = Yup.object()
  .shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
      .label('Name'),
    address1: Yup.string().nullable().label('Address1'),
    city: Yup.string()
      .min(3, 'Too Short!')
      .max(50, 'Too Long!')
      .nullable()
      .label('City'),
    state: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('State'),
    zipcode: Yup.string().nullable().label('Zip Code'),
    parcelNumber: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .nullable()
      .label('Parcel Number'),
    yearBuilt: Yup.number().nullable().label('Year Built'),
    market: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Market'),
    marketSegment: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Market Segment'),
    submarket: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Submarket'),
    productType: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Product Type'),
    structure: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Structure'),
    dealType: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Deal Type'),
    numberOfUnits: Yup.number().nullable().label('Number of units'),
    nrsf: Yup.number().nullable().label('NRSF'),
    irr: Yup.number().nullable().label('IRR'),
    unleverredIrr: Yup.number().nullable().label('Unlevered IRR'),
    moic: Yup.number().nullable().label('MOIC'),
    entryCapRate: Yup.number().nullable().label('Entry Cap Rate'),
    exitCapRate: Yup.number().nullable().label('Exit Cap Rate'),
    requiredEquity: Yup.number().nullable().label('Required Equity'),
    debt: Yup.number().nullable().label('Debt'),
    birthDate: Yup.date().required().label('Birth Date'),
    // riskLevel: Yup.object().shape({
    //   value: Yup.number().required(),
    //   label: Yup.string().required(),
    // }).nullable(),
    stage: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Stage'),
    fund: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Fund'),
    fundEntity: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Fund Entity'),
    brokerFirm: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Broker Firm'),
    brokerContact: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Broker Contact'),
    buyerFirm: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Buyer Firm'),
    buyerContact: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Buyer Contact'),
    sellerFirm: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Seller Firm'),
    sellerContact: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Seller Contact'),
    legalEntity: Yup.string().nullable().label('Special Purpose Entity'),
    purchasePrice: Yup.number().nullable().label('Purchase Price'),
    region: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .label('Region'),
  })
  .required();

interface SummaryDashboardProps {
  pipelineId: number;
}

const SummaryDashboard: FunctionComponent<SummaryDashboardProps> = ({
  pipelineId,
}) => {
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const match = useRouteMatch();
  const { oppId, orgName } = useParams<{ oppId: string; orgName: string }>();
  usePageTitle('Deal Summary');
  const { data, refetch } = useGetDeal(Number(oppId), {
    enabled: false,
  });

  const currentStatus = useMemo(() => {
    return data?.deal?.currentStatus;
  }, [data]);

  const { data: statesData, refetch: refetchStates } = useGetStates({
    manual: true,
  });
  const { data: marketsData, refetch: refetchMarkets } = useGetMarkets({
    manual: true,
  });
  const { data: regionsData, refetch: refetchRegions } = useGetRegions({
    manual: true,
  });
  const {
    data: marketSegmentsData,
    refetch: refetchMarketSegments,
  } = useGetMarketSegments({ manual: true });
  const {
    data: submarketsData,
    refetch: refetchSubmarkets,
  } = useGetSubmarkets({ manual: true });
  const {
    data: productTypesData,
    refetch: refetchProductTypes,
  } = useGetProductTypes({ manual: true });
  const {
    data: structuresData,
    refetch: refetchStructuresData,
  } = useGetStructures({ manual: true });
  const {
    data: dealTypesData,
    refetch: refetchDealTypesData,
  } = useGetDealTypes({ manual: true });
  const {
    data: milestoneData,
  } = useGetDealKeyMilestones(Number(oppId));

  type PinnedEvent = {
    event: {
      name: string;
    };
    date: string;
  };

  const [pinnedEvents, setPinnedEvents] = useState<PinnedEvent[]>([]);
  const [milestones, setMilestones] = useState<Milestone[]>([]);

  useEffect(() => {
    if (milestoneData && milestoneData.deal && data?.deal?.pinnedEvents) {
      const formattedPinnedEvents = data.deal.pinnedEvents
        .map(pe => ({
          ...pe,
          date: pe.endDate || ''
        }));
      setPinnedEvents(formattedPinnedEvents);
      setMilestones(milestoneData.deal.milestones || []);
    }
  }, [milestoneData, data]);

  const PAGE_SIZE = 4;
  const [currentPage, setCurrentPage] = useState(0);

  const combinedMilestones = useMemo(() => {
    const pinnedMilestones = pinnedEvents.map(pe => ({
      id: `pinned-${pe.event.name}-${pe.date}`,
      name: pe.event.name,
      startDate: pe.date
    }));

    const allMilestones = [...pinnedMilestones, ...milestones];

    const uniqueMilestones = Array.from(new Set(allMilestones.map(m => m.id)))
      .map(id => allMilestones.find(m => m.id === id));

    uniqueMilestones.sort((a, b) => {
      if (a?.id.toString().includes('pinned') && !b?.id.toString().includes('pinned')) {
        return -1;
      }
      if (!a?.id.toString().includes('pinned') && b?.id.toString().includes('pinned')) {
        return 1;
      }
      return (new Date(a?.startDate || 0).getTime()) - (new Date(b?.startDate || 0).getTime());
    });

    return uniqueMilestones;
  }, [pinnedEvents, milestones]);

  const milestonesToShow = useMemo(() => {
    const startIndex = currentPage * PAGE_SIZE;
    return combinedMilestones.slice(startIndex, startIndex + PAGE_SIZE).filter(m => m !== undefined) as Milestone[];
  }, [currentPage, combinedMilestones]);

  const handlePageChange = (newPageIndex: number) => {
    if (newPageIndex >= 0 && newPageIndex < Math.ceil(combinedMilestones.length / PAGE_SIZE)) {
      setCurrentPage(newPageIndex);
    }
  };

  const { data: peopleData, refetch: refetchPeople } = useGetOrganizationUsers({ manual: true });

  const peopleOptions = useMemo(() => {
    if (peopleData?.users) {
      const people = peopleData.users;
      const options = people.map((person) => ({
        value: person.id,
        label: `${person.firstName} ${person.lastName}`,
      }));
      return options;
    } else {
      return [];
    }
  }, [peopleData]);

  const [updateTeamMembers, { status: updateTeamMembersStatus }] = useUpdateTeamMembers({
    throwOnError: true,
  });

  const { data: firmsData, refetch: refetchFirms } = useGetPipelineFirms(pipelineId);

  const firmOptions = useMemo(() => {
    if (firmsData?.pipeline?.firms) {
      const firms = firmsData.pipeline.firms;
      const options = firms.map((firm) => ({
        value: firm.id,
        label: firm.name,
      }));
      return options;
    } else {
      return [];
    }
  }, [firmsData]);

  const [updateDealPartners, { status: updateDealPartnersStatus }] = useUpdateDealPartners({
    throwOnError: true,
  });


  const { data: fundsData, refetch: refetchFunds } = useGetFunds({
    manual: true,
  });
  const {
    data: fundEntitiesData,
    refetch: refetchFundEntities,
  } = useGetFundEntities({ manual: true });
  const {
    data: contactsData,
    refetch: refetchContactsData,
  } = useGetDealPipelineContacts(Number(oppId));

  const contacts = useMemo(() => {
    return (
      contactsData?.deal?.pipeline?.contacts?.map((contact) => ({
        value: contact.id,
        label: `${contact.firstName} ${contact.lastName}`,
      })) || []
    );
  }, [contactsData]);

  const [selectedMarket, setSelectedMarket] = useState<number | null>(null);

  const filteredSubmarkets = useMemo(() => {
    if (selectedMarket) {
      return submarketsData?.submarkets?.filter(x => x.fkMarket === selectedMarket);
    } else {
      return (submarketsData?.submarkets);
    }
  }, [selectedMarket, submarketsData]);

  const newStatusUpdateModalHelpers = createQueryParamModalHelpers(
    'new-status-update'
  );

  const editDealBackgroundModalHelpers = createQueryParamModalHelpers(
    'edit-deal-background'
  );

  const editEarnestMoneyDepositModalHelpers = createQueryParamModalHelpers(
    'edit-earnest-money-deposit'
  );

  const editInvestmentVisionModalHelpers = createQueryParamModalHelpers(
    'edit-investment-vision'
  );

  const keyRisksModalHelpers = createQueryParamModalHelpers('show-key-risks');

  const dealMeritsModalHelpers = createQueryParamModalHelpers(
    'show-deal-meris'
  );

  const editLocationModalHelpers = createQueryParamModalHelpers(
    'edit-location'
  );

  const [isEditing, setIsEditing] = useState(false);

  const initialValues: PartialNullable<DealFormValues> = useMemo(() => {
    if (data?.deal) {
      const deal = data.deal;
      return {
        name: deal.name,
        otherName: deal.otherName,
        address1: deal.address1,
        city: deal.city,
        state: deal.state,
        zipcode: deal.zipcode,
        parcelNumber: deal.parcelNumber,
        yearBuilt: deal.yearBuilt,
        market: deal.market,
        marketSegment: deal.marketSegment,
        submarket: deal.submarket,
        productType: deal.productType,
        numberOfUnits: deal.numberOfUnits,
        nrsf: deal.nrsf,
        irr: deal.irr,
        unleverredIrr: Number(deal.unleverredIrr),
        moic: deal.moic,
        entryCapRate: deal.entryCapRate,
        exitCapRate: deal.exitCapRate,
        requiredEquity: deal.requiredEquity,
        debt: deal.debt,
        birthDate: parseISO(deal.birthDate),
        // riskLevel: deal.riskLevel,
        stage: deal.stage,
        structure: deal.structure,
        dealType: deal.dealType,
        fund: deal.fund,
        fundEntity: deal.fundEntity,
        brokerFirm: deal.brokerFirm,
        brokerContact: deal.brokerContact && {
          label:
            deal.brokerContact?.firstName + ' ' + deal.brokerContact?.lastName,
          value: deal.brokerContact?.id,
        },
        buyerFirm: deal.buyerFirm,
        buyerContact: deal.buyerContact && {
          label:
            deal.buyerContact?.firstName + ' ' + deal.buyerContact?.lastName,
          value: deal.buyerContact?.id,
        },
        sellerFirm: deal.sellerFirm,
        sellerContact: deal.sellerContact && {
          label:
            deal.sellerContact?.firstName + ' ' + deal.sellerContact?.lastName,
          value: deal.sellerContact?.id,
        },
        legalEntity: deal.legalEntity || undefined,
        purchasePrice: deal.purchasePrice || undefined,
        region: deal.region
      };
    } else {
      return {};
    }
  }, [data]);

  const keyRisks: KeyRisk[] = useMemo(() => {
    if (data?.deal?.keyRisks) {
      return data.deal.keyRisks.map((risk) => ({
        id: risk.id,
        title: risk.title,
        riskDescription: risk.riskDescription || undefined,
        mitigantDescription: risk.mitigantDescription || undefined,
        updatedAt: parse(risk.updatedAt, dateTimeOffsetFormat, new Date()),
        updatedBy: risk.updatedBy.displayName,
        orderId: risk.orderId || 0,
      }));
    } else {
      return [];
    }
  }, [data, data?.deal?.keyRisks]);

  const dealMerits: DealMerit[] = useMemo(() => {
    if (data?.deal?.dealMerits) {
      return data.deal.dealMerits.map((merit) => ({
        id: merit.id,
        title: merit.title,
        meritDescription: merit.meritDescription || undefined,
        updatedAt: parse(merit.updatedAt, dateTimeOffsetFormat, new Date()),
        updatedBy: merit.updatedBy.displayName,
        orderId: merit.orderId || 0,
      }));
    } else {
      return [];
    }
  }, [data, data?.deal?.dealMerits]);

  const [updateDeal, { status }] = useUpdateDeal();

  const usesCoordinates = useMemo(() => {
    return data?.deal?.usesCoordinates == true;
  }, [data]);

  const addressString = useMemo(() => {
    const components = [];
    components.push(data?.deal?.address1);
    components.push(data?.deal?.city);

    if (data?.deal?.state) {
      let stateAndZip = data.deal.state.abbreviation;
      if (data?.deal?.zipcode) {
        stateAndZip = stateAndZip + ' ' + data.deal.zipcode;
      }
      components.push(stateAndZip);
    }

    const address = components
      .filter((x) => x != null || x != undefined)
      .join(', ');
    return address;
  }, [data]);

  const coordinatesString = useMemo(() => {
    const components = [];
    if (data?.deal?.latitude && data.deal.longitude) {
      components.push(`${data.deal.latitude}, ${data.deal.longitude}`);
    }
    if (data?.deal?.state) {
      components.push(data.deal.state.abbreviation);
    }
    const result = components
      .filter((x) => x != null || x != undefined)
      .join(', ');
    return result;
  }, [data]);

  const notify = useNotification();

  const coordinates = useMemo(() => {
    if (data?.deal?.latitude && data.deal.longitude) {
      return {
        lat: data.deal.latitude,
        lng: data.deal.longitude,
      };
    }
  }, [data]);

  const dealCoordinates = useMemo(() => {
    if (data?.deal?.latitude && data.deal.longitude) {
      return [{
        id: Number(oppId),
        title: usesCoordinates ? coordinatesString : addressString,
        latitude: data.deal.latitude,
        longitude: data.deal.longitude,
      }];
    }
  }, [data]);;

  const [
    isShowingPinnedMetricsModal,
    setIsShowingPinnedMetricsModal,
  ] = useState(false);

  const [isStatusUpdatesClicked, setIsStatusUpdatesClicked] = useState(false);

  const [selectedStatusUpdateId, setSelectedStatusUpdateId] = useState<number | null>(null);

  const handleViewDetails = (statusUpdateId: number) => {
    setSelectedStatusUpdateId(statusUpdateId);
    history.push(`${match.url}/statusUpdate/${statusUpdateId}`);
  };

  return (
    <Form<DealFormValues>
      initialValues={initialValues}
      handleSubmit={async (form) => {
        const changes: any = diff(initialValues, form);
        const input: UpdateDealInput = {
          id: Number(oppId),
          name: changes?.name,
          otherName: changes?.otherName,
          address1: changes?.address1,
          city: changes?.city,
          fkState: changes?.state?.value,
          zipcode: changes?.zipcode,
          parcelNumber: changes?.parcelNumber || null,
          yearBuilt: changes?.yearBuilt || null,
          fkMarket: changes?.market?.value,
          fkMarketSegment: changes?.marketSegment?.value,
          fkSubmarket: changes?.submarket?.value,
          fkProductType: changes?.productType?.value,
          numberOfUnits: changes?.numberOfUnits,
          nrsf: changes?.nrsf,
          irr: changes?.irr,
          unleverredIrr: changes?.unleverredIrr,
          moic: changes?.moic,
          entryCapRate: changes?.entryCapRate,
          exitCapRate: changes?.exitCapRate,
          requiredEquity: changes?.requiredEquity,
          debt: changes?.debt,
          birthDate:
            changes?.birthDate &&
            formatDate(changes.birthDate, yearMonthDayFormat),
          fkStage: changes?.stage?.value,
          fkStructure: changes?.structure?.value,
          fkDealType: changes?.dealType?.value,
          fkFund: changes?.fund?.value,
          fkFundEntity: changes?.fundEntity?.value,
          fkBrokerFirm: changes?.brokerFirm?.value,
          fkBrokerContact: changes?.brokerContact?.value,
          fkBuyerFirm: changes?.buyerFirm?.value,
          fkBuyerContact: changes?.buyerContact?.value,
          fkSellerFirm: changes?.sellerFirm?.value,
          fkSellerContact: changes?.sellerContact?.value,
          fkRegion: changes?.region?.value,
          legalEntity: changes?.legalEntity,
        };
        const teamRoles = Object.keys(changes).filter(key => key.includes('role')).reduce((cur, key) => { return Object.assign(cur, { [key]: changes[key] }) }, {});
        try {
          let userIds: number[] = [];
          for (const [key, value] of Object.entries(teamRoles)) {
            const roleId = Number(key.substring(key.indexOf('_') + 1));
            if (value) {
              userIds = (value as any[]).map((x: any) => {
                return Number(x.value);
              })
            }
            const result = await updateTeamMembers({ dealId: Number(oppId), roleId: roleId, userIds: userIds });
            userIds = [];
            if (result.errors) {
              notify({
                title: 'Oops!',
                variant: 'danger',
                message:
                  'An error occurred while updating the details of this deal.',
              });
            }
          }
        } catch (e) {
          notify({
            title: 'Oops!',
            variant: 'danger',
            message:
              'An error occurred while updating the details of this deal.',
          });
        }

        const partnerRoles = Object.keys(changes).filter(key => key.includes('partner'))
          .reduce((cur, key) => { return Object.assign(cur, { [key]: changes[key] }) }, {});
        try {
          let firmIds: number[] = [];
          for (const [key, value] of Object.entries(partnerRoles)) {
            const roleId = Number(key.substring(key.indexOf('_') + 1));
            if (value) {
              firmIds = (value as any[]).map((x: any) => {
                return Number(x.value);
              })
            }
            const result = await updateDealPartners({ dealId: Number(oppId), roleId: roleId, firmIds: firmIds });
            firmIds = [];
            if (result.errors) {
              notify({
                title: 'Oops!',
                variant: 'danger',
                message:
                  'An error occurred while updating the details of this deal.',
              });
            }
          }
        } catch (e) {
          notify({
            title: 'Oops!',
            variant: 'danger',
            message:
              'An error occurred while updating the details of this deal.',
          });
        }

        try {
          const result = await updateDeal(input);
          if (result.errors) {
            notify({
              title: 'Oops!',
              variant: 'danger',
              message:
                'An error occurred while updating the details of this deal.',
            });
          } else {
            refetch()
            notify({
              title: 'Deal Updated!',
              variant: 'success',
            });
            setIsEditing(false);
          }
        } catch (error) {
          notify({
            title: 'Oops!',
            variant: 'danger',
            message:
              'An error occurred while updating the details of this deal.',
          });
        }
      }}
      validationSchema={dealFormSchema}
      renderForm={false}
    >
      {(props) => (
        <React.Fragment>
          <PageToolbar
            left={
              <React.Fragment>
                {data?.deal?.currentUserCanEdit ? (
                  <Tooltip
                    placement={'right'}
                    content={
                      'You have been added as a contributor for this deal or it has been marked as publicly editable.'
                    }
                  >
                    <span>
                      <ToolbarMessage
                        icon={'info-circle'}
                        color={themeContext.info}
                        message={'You have write access to this deal.'}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement={'right'}
                    content={
                      'You have not been added as a contributor for this deal and it is not publicly editable.'
                    }
                  >
                    <span>
                      <ToolbarMessage
                        icon={'exclamation-triangle'}
                        color={themeContext.warning}
                        message={'You have read-only access to this deal.'}
                      />
                    </span>
                  </Tooltip>
                )}
              </React.Fragment>
            }
            right={
              <React.Fragment>
                {isEditing ? (
                  <React.Fragment>
                    <ToolbarButton
                      icon={'times'}
                      onClick={() => setIsEditing(false)}
                      variant={'outline'}
                    >
                      Cancel
                    </ToolbarButton>
                    <ToolbarButton
                      variant={'standard'}
                      color={'success'}
                      style={{ marginLeft: '10px' }}
                      onClick={props.submitForm}
                      disabled={!props.dirty || !props.isValid}
                      isWorking={status == 'loading' || updateTeamMembersStatus == 'loading' || updateDealPartnersStatus == 'loading'}
                    >
                      Save Changes
                    </ToolbarButton>
                  </React.Fragment>
                ) : (
                  isStatusUpdatesClicked ? (
                    <ToolbarButton
                      icon={'times'}
                      onClick={() => setIsStatusUpdatesClicked(false)}
                    >
                      Return to Summary
                    </ToolbarButton>
                  ) : (
                    < ToolbarButton
                      icon={'pencil'}
                      onClick={() => setIsEditing(true)}
                      disabled={!data?.deal?.currentUserCanEdit}
                    >
                      Edit Details
                    </ToolbarButton>
                  ))}
              </React.Fragment>
            }
          />
          <PageDetails>
            {isStatusUpdatesClicked ? (
              <StatusUpdates
                onClose={() => history.push(match.url)}
                onCommentUpdated={refetch}
                setIsStatusUpdatesClicked={setIsStatusUpdatesClicked}
              />
            ) : (
              <form
                onSubmit={props.handleSubmit}
                autoComplete={'false'}
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'inline-flex',
                  flexDirection: 'column',
                }}
                id={'dealSummary'}
              >
                <DashboardDetails>
                  <DetailsBody>
                    <Row gap={5}>
                      {/* <Column>
                          {currentStatus != undefined && (
                            <div style={{ marginBottom: '10px' }}>
                              <StatusCard
                                variant={
                                  currentStatus.status.color as StatusVariant
                                }
                                statusUpdateId={currentStatus.id}
                                status={currentStatus.status}
                                title={currentStatus.title}
                                message={currentStatus.message}
                                authorName={
                                  currentStatus.createdBy?.displayName || 'Unknown'
                                }
                                modifiedDate={currentStatus.createdAt}
                                onViewComments={() =>
                                  history.push(
                                    `${match.url}/currentStatus/${currentStatus.id}/comments`
                                  )
                                }
                                onViewAllStatusUpdates={() =>
                                  history.push(`${match.url}/statusUpdates`)
                                }
                                onViewDetails={() => history.push(`${match.url}/statusUpdate/${currentStatus.id}`)}
                                showEdit={true}
                                oppId={Number(oppId)}
                                commentCount={currentStatus.commentCount}
                              />
                            </div>
                          )}
                        </Column> */}
                      <Column xs={12} sm={6}>
                        <DashboardCard
                          title={'Property Details'}
                          tools={[
                            <Button
                              key={'edit-location'}
                              icon={'pencil'}
                              slim={true}
                              variant={'simple'}
                              onClick={editLocationModalHelpers.open}
                              disabled={!data?.deal?.currentUserCanEdit}
                            >
                              Change Location
                            </Button>,
                          ]}
                        >
                          <Row gap={0}>
                            <DealDataTable isEditing={isEditing}>
                              {!usesCoordinates ? (
                                <DealDataTableRow
                                  label={'Address'}
                                  display={addressString}
                                />
                              ) : (
                                <DealDataTableRow
                                  label={'Location'}
                                  display={coordinatesString}
                                />
                              )}
                              {/* <DealDataTableRow
                                  label={'City'}
                                  display={data?.deal?.city}
                                  field={<InputFormikField name={'city'} />}
                                />
                                <DealDataTableRow
                                  label={'State'}
                                  display={data?.deal?.state?.label}
                                  field={
                                    <SelectFormikField
                                      name={'state'}
                                      options={statesData?.states || []}
                                      loadOptions={refetchStates}
                                    />
                                  }
                                />
                                <DealDataTableRow
                                  label={'Zip Code'}
                                  display={data?.deal?.zipcode}
                                  field={<InputFormikField name={'zipcode'} />}
                                /> */}
                              <DealDataTableRow
                                label={'Parcel Number'}
                                display={data?.deal?.parcelNumber}
                                field={<InputFormikField name={'parcelNumber'} />}
                              />
                              <DealDataTableRow
                                label={'Year Built'}
                                display={data?.deal?.yearBuilt}
                                field={<InputFormikField name={'yearBuilt'} />}
                              />
                              <DealDataTableRow
                                label={'Other Name (AKA)'}
                                display={data?.deal?.otherName}
                                field={<InputFormikField name={'otherName'} />}
                              />
                            </DealDataTable>
                          </Row>
                        </DashboardCard>
                        <Spacer padding={5} />
                        <DashboardCard title={'Market Details'}>
                          <Row gap={0}>
                            <DealDataTable isEditing={isEditing}>
                              <DealDataTableRow
                                label={'Market'}
                                display={data?.deal?.market?.label}
                                field={
                                  <SelectFormikField
                                    name={'market'}
                                    options={marketsData?.markets || []}
                                    loadOptions={refetchMarkets}
                                    onChange={(option) => setSelectedMarket(option?.value)}
                                  />
                                }
                              />
                              <DealDataTableRow
                                label={'Market Segment'}
                                display={data?.deal?.marketSegment?.label}
                                field={
                                  <SelectFormikField
                                    name={'marketSegment'}
                                    options={
                                      marketSegmentsData?.marketSegments || []
                                    }
                                    loadOptions={refetchMarketSegments}
                                  />
                                }
                              />
                              <DealDataTableRow
                                label={'Submarket'}
                                display={data?.deal?.submarket?.label}
                                field={
                                  <SelectFormikField
                                    name={'submarket'}
                                    options={filteredSubmarkets || []}
                                    loadOptions={refetchSubmarkets}
                                  />
                                }
                              />
                              <DealDataTableRow
                                label={'MSA'}
                                display={data?.deal?.market?.msa?.name}
                              />

                              <DealDataTableRow
                                label={'Region'}
                                display={data?.deal?.region?.label}
                                field={
                                  <SelectFormikField
                                    name={'region'}
                                    options={regionsData?.regions || []}
                                    loadOptions={refetchRegions}
                                  />
                                }
                              />
                            </DealDataTable>
                            
                          </Row>
                        </DashboardCard>
                        <Spacer padding={5} />
                        <DashboardCard
                          title={'Team Members'}
                          tools={[
                            <Button
                              key={'view-all-team-members'}
                              onClick={() =>
                                history.push(
                                  `/${orgName}/deals/${oppId}/team/members`
                                )
                              }
                              slim={true}
                              variant={'simple'}
                              icon={'list'}
                            >
                              View All
                            </Button>,
                          ]}
                        >
                          {data?.deal?.pinnedTeamMemberRoles &&
                            data.deal.pinnedTeamMemberRoles.length == 0 ? (
                            <div
                              style={{
                                width: '100%',
                                height: '100px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                              }}
                            >
                              {
                                'Use this section to pin team member roles that you want visible here for everyone to see.'
                              }
                            </div>
                          ) : (
                            <Row gap={0}>
                              <DealDataTable isEditing={isEditing}>
                                {data?.deal?.pinnedTeamMemberRoles?.map(
                                  (role) => {
                                    let displayName = "";
                                    role.currentValue?.forEach((item, index) => {
                                      if (index > 0) {
                                        displayName += ", " + item?.user.displayName;
                                      } else { displayName += item?.user.displayName; }
                                    });
                                    return (
                                      <DealDataTableRow
                                        key={role.role.id}
                                        label={role.role.name}
                                        display={displayName}
                                        field={
                                          <SelectFormikField
                                            name={'role_' + role.role.id}
                                            options={peopleOptions}
                                            loadOptions={refetchPeople}
                                            isMulti={true}
                                          />
                                        }
                                      />
                                    );
                                  }
                                )}
                              </DealDataTable>
                            </Row>
                          )}
                        </DashboardCard>
                        <Spacer padding={5} />
                        <DashboardCard
                          title={'Partners'}
                          tools={[
                            <Button
                              key={'view-all-partners'}
                              onClick={() =>
                                history.push(
                                  `/${orgName}/deals/${oppId}/team/partners`
                                )
                              }
                              slim={true}
                              variant={'simple'}
                              icon={'list'}
                            >
                              View All
                            </Button>,
                          ]}
                        >
                          {data?.deal?.pinnedPartnerRoles &&
                            data.deal.pinnedPartnerRoles.length == 0 ? (
                            <div
                              style={{
                                width: '100%',
                                height: '100px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                              }}
                            >
                              {
                                'Use this section to pin team partner roles that you want visible here for everyone to see.'
                              }
                            </div>
                          ) : (
                            <Row gap={0}>
                              <DealDataTable isEditing={isEditing}>
                                {data?.deal?.pinnedPartnerRoles?.map(
                                  (role) => {
                                    let displayName = "";
                                    role.currentValue?.forEach((item, index) => {
                                      if (index > 0) {
                                        displayName += ", " + item?.firm?.name;
                                      } else { displayName += item?.firm?.name; }
                                    });
                                    return (
                                      <DealDataTableRow
                                        key={role.role.id}
                                        label={role.role.name}
                                        display={displayName}
                                        field={
                                          <SelectFormikField
                                            name={'partner_' + role.role.id}
                                            options={firmOptions}
                                            loadOptions={refetchFirms}
                                            isMulti={true}
                                          />
                                        }
                                      />
                                    );
                                  }
                                )}
                              </DealDataTable>
                            </Row>
                          )}
                        </DashboardCard>
                        <Spacer padding={5} />
                        {/* <Spacer padding={5} />
                          <DashboardCard title={'Transaction Details'}>
                            <Row gap={0}>
                              <DealDataTable isEditing={isEditing}>
                                <DealDataTableRow
                                  label={'Broker Firm'}
                                  display={data?.deal?.brokerFirm?.label}
                                  field={
                                    <SelectFormikField
                                      name={'brokerFirm'}
                                      options={sources}
                                      loadOptions={refetchSources}
                                    />
                                  }
                                />
                                <DealDataTableRow
                                  label={'Broker Contact'}
                                  display={
                                    data?.deal?.brokerContact &&
                                    `${data?.deal?.brokerContact?.firstName} ${data?.deal?.brokerContact?.lastName}`
                                  }
                                  field={
                                    <SelectFormikField
                                      name={'brokerContact'}
                                      options={contacts}
                                      loadOptions={refetchContactsData}
                                    />
                                  }
                                />
                                <DealDataTableRow
                                  label={'Buyer Firm'}
                                  display={data?.deal?.buyerFirm?.label}
                                  field={
                                    <SelectFormikField
                                      name={'buyerFirm'}
                                      options={sources}
                                      loadOptions={refetchSources}
                                    />
                                  }
                                />
                                <DealDataTableRow
                                  label={'Buyer Contact'}
                                  display={
                                    data?.deal?.buyerContact &&
                                    `${data?.deal?.buyerContact?.firstName} ${data?.deal?.buyerContact?.lastName}`
                                  }
                                  field={
                                    <SelectFormikField
                                      name={'buyerContact'}
                                      options={contacts}
                                      loadOptions={refetchContactsData}
                                    />
                                  }
                                />
                                <DealDataTableRow
                                  label={'Seller Firm'}
                                  display={data?.deal?.sellerFirm?.label}
                                  field={
                                    <SelectFormikField
                                      name={'sellerFirm'}
                                      options={sources}
                                      loadOptions={refetchSources}
                                    />
                                  }
                                />
                                <DealDataTableRow
                                  label={'Seller Contact'}
                                  display={
                                    data?.deal?.sellerContact &&
                                    `${data?.deal?.sellerContact?.firstName} ${data?.deal?.sellerContact?.lastName}`
                                  }
                                  field={
                                    <SelectFormikField
                                      name={'sellerContact'}
                                      options={contacts}
                                      loadOptions={refetchContactsData}
                                    />
                                  }
                                />
                                <DealDataTableRow
                                  label={'Purchase Price'}
                                  display={data?.deal?.purchasePrice}
                                  field={
                                    <InputFormikField
                                      name={'purchasePrice'}
                                      mask={'number'}
                                    />
                                  }
                                />
                              </DealDataTable>
                            </Row>
                          </DashboardCard> */}
                      </Column>
                      <Column xs={12} sm={6}>
                        <DashboardCard title={'Map'}>
                          <div style={{ height: '250px' }}>
                            <Map dealCoordinates={dealCoordinates} center={coordinates} defaultZoom={15} />
                          </div>
                        </DashboardCard>
                        <Spacer padding={5} />
                        <DashboardCard title='Key Milestones' >
                          <MilestonesList milestoneCount={combinedMilestones.length}>
                            {milestonesToShow.map((milestone, index) => {
                              if (!milestone) return null;
                              return (
                                <MilestoneItem key={milestone.id}>
                                  <MilestoneName>{milestone.name}</MilestoneName>
                                  {milestone.startDate ? (
                                    <MilestoneDateWrapper background={getMilestoneColor(milestone, themeContext.isDark)?.background ?? 'white'}>
                                      <FontAwesomeIcon
                                        icon={['fal', 'calendar']}
                                        color={getMilestoneColor(milestone, themeContext.isDark)?.text}
                                        fixedWidth={true}
                                      />
                                      <span> </span>
                                      <MilestoneDate style={{ color: getMilestoneColor(milestone, themeContext.isDark)?.text }}>
                                        {isToday(parseISO(milestone.startDate)) ? 'Today' :
                                          isTomorrow(parseISO(milestone.startDate)) ? 'Tomorrow' :
                                            formatDate(milestone.startDate, 'EEE MMM d, yyyy')}
                                      </MilestoneDate>
                                    </MilestoneDateWrapper>
                                  ) : (
                                    <span>-</span>
                                  )}
                                </MilestoneItem>
                              );
                            })}
                          </MilestonesList>
                          {combinedMilestones.length > PAGE_SIZE && (
                            <PaginationContainer>
                              <PageButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  handlePageChange(currentPage - 1);
                                }}
                                disabled={currentPage === 0}
                              >
                                <FontAwesomeIcon icon={['fas', 'chevron-left']} size='lg' />
                              </PageButton>
                              <PageIndicator>
                                {currentPage + 1} of {Math.ceil(combinedMilestones.length / PAGE_SIZE)}
                              </PageIndicator>
                              <PageButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  handlePageChange(currentPage + 1);
                                }}
                                disabled={currentPage === Math.ceil(combinedMilestones.length / PAGE_SIZE) - 1}
                              >
                                <FontAwesomeIcon icon={['fas', 'chevron-right']} size='lg' />
                              </PageButton>
                            </PaginationContainer>
                          )}
                        </DashboardCard>
                        <Spacer padding={5} />
                        <DashboardCard
                          title={'Metrics Overview'}
                          tools={[
                            <DropdownButton
                              variant={'simple'}
                              key={'edit-menu'}
                              icon={'pencil'}
                              anchor={'right'}
                              disabled={!data?.deal?.currentUserCanEdit || false}
                            >
                              {data?.deal?.currentAnalysis && (
                                <React.Fragment>
                                  <DropdownItem
                                    icon={'analytics'}
                                    onClick={() => {
                                      history.push(
                                        `/${orgName}/deals/${oppId}/analysis/${data.deal?.currentAnalysis?.id}/metrics`
                                      );
                                    }}
                                  >
                                    Update current analysis
                                  </DropdownItem>
                                  <MenuSeparator />
                                </React.Fragment>
                              )}
                              <DropdownItem
                                icon={'plus'}
                                onClick={() => {
                                  history.push(
                                    `/${orgName}/deals/${oppId}/analysis/all?modal-new-analysis=true`
                                  );
                                }}
                              >
                                Create new analysis
                              </DropdownItem>
                              {/* <DropdownItem
                              icon={'pencil'}
                              onClick={() =>
                                setIsShowingPinnedMetricsModal(true)
                              }
                            >
                              Edit pinned metrics
                            </DropdownItem> */}
                            </DropdownButton>,
                          ]}
                        >
                          {data?.deal?.pinnedMetrics &&
                            data.deal.pinnedMetrics.length == 0 ? (
                            <div
                              style={{
                                width: '100%',
                                height: '100px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                              }}
                            >
                              {
                                'Use this section to pin metrics that you want visible here for everyone to see.'
                              }
                            </div>
                          ) : (
                            <Row gap={0}>
                              <DealDataTable isEditing={isEditing}>
                                {data?.deal?.pinnedMetrics?.map(
                                  (metric, index) => {
                                    return (
                                      <DealDataTableRow
                                        key={metric.metric.id}
                                        label={metric.metric.name}
                                        display={formatMetric(
                                          metric.currentValue?.value,
                                          metric.metric.format,
                                          metric.metric.unit,
                                          metric.metric.precision,
                                          metric.metric.scale,
                                        )}
                                      />
                                    );
                                  }
                                )}
                              </DealDataTable>
                            </Row>
                          )}
                        </DashboardCard>
                        <Spacer padding={5} />
                      </Column>
                    </Row>
                  </DetailsBody>
                  <DetailsColumn>
                    <Column>
                      {currentStatus != undefined && (
                        <div style={{ marginBottom: '10px' }}>
                          <StatusCard
                            variant={currentStatus.status.color as StatusVariant}
                            statusUpdateId={currentStatus.id}
                            status={currentStatus.status}
                            title={currentStatus.title}
                            message={currentStatus.message}
                            authorName={
                              currentStatus.createdBy?.displayName || 'Unknown'
                            }
                            modifiedDate={currentStatus.createdAt}
                            onViewComments={() =>
                              history.push(
                                `${match.url}/currentStatus/${currentStatus.id}/comments`
                              )
                            }
                            onViewDetails={() => handleViewDetails(currentStatus.id)}
                            oppId={Number(oppId)}
                            commentCount={currentStatus.commentCount}
                            labels={currentStatus.labels || []}
                          />
                        </div>
                      )}
                    </Column>
                    <ProjectFieldGroupWrapper>
                      <ProjectFieldButton>
                        <Button
                          fullWidth={true}
                          variant={'outline'}
                          icon={'comment-alt-lines'}
                          onClick={() => {
                            setIsStatusUpdatesClicked(!isStatusUpdatesClicked)
                          }}
                        >
                          View All Status Updates
                        </Button>
                      </ProjectFieldButton>
                    </ProjectFieldGroupWrapper>
                    <Section title={'About'}>
                      {data?.deal && (
                        <ProjectFieldGroupWrapper>
                          <Row gap={0}>
                            <DealDataTable isEditing={isEditing}>
                              <DealDataTableRow
                                label={'Deal Birthdate'}
                                display={formatDate(
                                  data.deal.birthDate,
                                  monthDayYearFormat
                                )}
                                field={
                                  <DatePickerFormikField
                                    name={'birthDate'}
                                    popperPlacement={'bottom-end'}
                                  />
                                }
                              />
                              <DealDataTableRow
                                label={'Structure'}
                                display={data.deal.structure?.label}
                                field={
                                  <SelectFormikField
                                    name={'structure'}
                                    options={
                                      structuresData?.structures?.filter(
                                        (x) => x.isDisabled == false
                                      ) || []
                                    }
                                    loadOptions={refetchStructuresData}
                                  />
                                }
                              />
                              <DealDataTableRow
                                label={'Deal Type'}
                                display={data.deal.dealType?.label}
                                field={
                                  <SelectFormikField
                                    name={'dealType'}
                                    options={dealTypesData?.dealTypes || []}
                                    loadOptions={refetchDealTypesData}
                                  />
                                }
                              />
                              <DealDataTableRow
                                label={'Product Type'}
                                display={data?.deal?.productType?.label}
                                field={
                                  <SelectFormikField
                                    name={'productType'}
                                    options={
                                      productTypesData?.productTypes?.filter(
                                        (x) => x.isDisabled == false
                                      ) || []
                                    }
                                    loadOptions={refetchProductTypes}
                                  />
                                }
                              />
                              {/* <DealDataTableRow
                                      label={'Risk Level'}
                                      display={'Need this stil...'}
                                      field={
                                        <SelectFormikField
                                          name={'riskLevel'}
                                          options={[
                                            { value: 'NW', label: 'NW' },
                                            { value: 'SW', label: 'SW' },
                                            { value: 'NE', label: 'NE' },
                                            { value: 'SE', label: 'SE' },
                                            { value: 'TX', label: 'TX' },
                                          ]}
                                        />
                                      }
                                    /> */}
                              <DealDataTableRow
                                label={'Stage'}
                                display={data.deal.stage?.label}
                                field={
                                  <SelectFormikField
                                    name={'stage'}
                                    options={
                                      data.deal.pipeline?.dealStages?.filter(
                                        (x) => x.isDisabled == false
                                      ) || []
                                    }
                                    loadOptions={refetch}
                                  />
                                }
                              />
                              <DealDataTableRow
                                label={'Fund'}
                                display={data.deal.fund?.label}
                                field={
                                  <SelectFormikField
                                    name={'fund'}
                                    options={fundsData?.funds || []}
                                    loadOptions={refetchFunds}
                                  />
                                }
                              />
                              <DealDataTableRow
                                label={'Fund Entity'}
                                display={data.deal.fundEntity?.label}
                                field={
                                  <SelectFormikField
                                    name={'fundEntity'}
                                    options={fundEntitiesData?.fundEntities || []}
                                    loadOptions={refetchFundEntities}
                                  />
                                }
                              />
                              <DealDataTableRow
                                label={'Special Purpose Entity'}
                                display={data.deal.legalEntity}
                                field={<InputFormikField name={'legalEntity'} />}
                              />
                            </DealDataTable>
                          </Row>
                        </ProjectFieldGroupWrapper>
                      )}
                    </Section>
                    <Section title={'Deal Considerations'}>
                      <ProjectFieldGroupWrapper>
                        <ProjectFieldButton>
                          <Button
                            fullWidth={true}
                            variant={'outline'}
                            icon={'align-left'}
                            onClick={editDealBackgroundModalHelpers.open}
                          >
                            Deal Background
                          </Button>
                        </ProjectFieldButton>
                        <ProjectFieldButton>
                          <Button
                            fullWidth={true}
                            variant={'outline'}
                            icon={'align-left'}
                            onClick={editEarnestMoneyDepositModalHelpers.open}
                          >
                            Earnest Money Deposit
                          </Button>
                        </ProjectFieldButton>
                        <ProjectFieldButton>
                          <Button
                            fullWidth={true}
                            variant={'outline'}
                            icon={'align-left'}
                            onClick={editInvestmentVisionModalHelpers.open}
                          >
                            Investment Vision
                          </Button>
                        </ProjectFieldButton>
                        <ProjectFieldButton>
                          <Button
                            fullWidth={true}
                            variant={'outline'}
                            icon={
                              <FontAwesomeIcon
                                icon={['fal', 'exclamation-triangle']}
                                color={themeContext.danger}
                                fixedWidth={true}
                              />
                            }
                            onClick={keyRisksModalHelpers.open}
                          >
                            Key Risks
                          </Button>
                        </ProjectFieldButton>
                        <ProjectFieldButton>
                          <Button
                            fullWidth={true}
                            variant={'outline'}
                            icon={
                              <FontAwesomeIcon
                                icon={['fal', 'comment-alt-check']}
                                color={themeContext.success}
                                fixedWidth={true}
                              />
                            }
                            onClick={dealMeritsModalHelpers.open}
                          >
                            Deal Merits
                          </Button>
                        </ProjectFieldButton>
                      </ProjectFieldGroupWrapper>
                    </Section>
                    {/* <Section title={'Photos'}></Section> */}
                  </DetailsColumn>
                </DashboardDetails>
                <Route
                  path={`${match.path}/currentStatus/:statusUpdateId/comments`}
                  render={() => (
                    <StatusUpdateComments
                      onClose={() => history.push(match.url)}
                      onCommentUpdated={refetch}
                    />
                  )}
                />
                {newStatusUpdateModalHelpers.isOpen() && (
                  <CreateStatusUpdateModal
                    oppId={Number(oppId)}
                    onClose={newStatusUpdateModalHelpers.close}
                  />
                )}
                {/* {isShowingPinnedMetricsModal && (
                <DealPinnedMetricsModal
                  onClose={() => setIsShowingPinnedMetricsModal(false)}
                  currentUserCanEdit={data?.deal?.currentUserCanEdit || false}
                />
              )} */}
                {editDealBackgroundModalHelpers.isOpen() && (
                  <DealNarrativeModal
                    title={'Deal Background'}
                    valueKey={'dealBackground'}
                    value={data?.deal?.dealBackground}
                    oppId={Number(oppId)}
                    onClose={editDealBackgroundModalHelpers.close}
                    currentUserCanEdit={data?.deal?.currentUserCanEdit || false}
                  />
                )}
                {editEarnestMoneyDepositModalHelpers.isOpen() && (
                  <DealNarrativeModal
                    title={'Earnest Money Deposit'}
                    valueKey={'earnestMoneyDeposit'}
                    value={data?.deal?.earnestMoneyDeposit}
                    oppId={Number(oppId)}
                    onClose={editEarnestMoneyDepositModalHelpers.close}
                    currentUserCanEdit={data?.deal?.currentUserCanEdit || false}
                  />
                )}
                {editInvestmentVisionModalHelpers.isOpen() && (
                  <DealNarrativeModal
                    title={'Investment Vision'}
                    valueKey={'investmentVision'}
                    value={data?.deal?.investmentVision}
                    oppId={Number(oppId)}
                    onClose={editInvestmentVisionModalHelpers.close}
                    currentUserCanEdit={data?.deal?.currentUserCanEdit || false}
                  />
                )}
                {keyRisksModalHelpers.isOpen() && (
                  <KeyRisksModal
                    oppId={Number(oppId)}
                    onClose={keyRisksModalHelpers.close}
                    keyRisks={keyRisks}
                    onSuccess={refetch}
                    currentUserCanEdit={data?.deal?.currentUserCanEdit || false}
                  />
                )}
                {dealMeritsModalHelpers.isOpen() && (
                  <DealMeritsModal
                    oppId={Number(oppId)}
                    onClose={dealMeritsModalHelpers.close}
                    dealMerits={dealMerits}
                    onSuccess={refetch}
                    currentUserCanEdit={data?.deal?.currentUserCanEdit || false}
                  />
                )}
                {editLocationModalHelpers.isOpen() && (
                  <DealLocationModal
                    onClose={editLocationModalHelpers.close}
                    onSuccess={() => false}
                    oppId={Number(oppId)}
                  />
                )}
                {selectedStatusUpdateId && (
                  <Route
                    path={`${match.path}/statusUpdate/:statusUpdateId`}
                    render={() => (
                      <RoutedStatusUpdateDetails
                        onClose={() => {
                          history.push(match.url);
                          setSelectedStatusUpdateId(null);
                        }}
                      />
                    )}
                  />
                )}
              </form>
            )}
          </PageDetails>
        </React.Fragment>
      )
      }
    </Form >
  );
};

function sortByName<T extends SelectOption>(a: T, b: T) {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}

export default SummaryDashboard;
